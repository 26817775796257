import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faMapMarkerAlt, faListCheck } from '@fortawesome/free-solid-svg-icons';
import { faImages } from '@fortawesome/free-regular-svg-icons';
import 'styles/views/home.scss';

const Home = () => {
  const cards = [
    {
      icon: faUserGroup,
      label: 'CRM',
      path: 'crm',
    },
    {
      icon: faImages,
      label: 'Campaigns',
      path: 'campaigns',
    },
    {
      icon: faMapMarkerAlt,
      label: 'Routes',
      path: 'distribution',
    },
    {
      icon: faListCheck,
      label: 'Activities',
      path: 'activities',
    }];
  const navigate = useNavigate();
  return (
    <div className="home">
      <Row xs={1} md={2} className="g-4">
        {cards.map(({ icon, label, path }) =>
        <Col>
          <Card className={label.toLowerCase()} onClick={() => navigate(`/${path}`)}>
            <Card.Body>
              <Card.Title>{label}</Card.Title>
              <Card.Text><FontAwesomeIcon icon={icon} /></Card.Text>
            </Card.Body>
          </Card>
        </Col>)}
      </Row>
    </div>
    // <ListGroup>
    //   <ListGroup.Item>CRM</ListGroup.Item>
    //   <ListGroup.Item>Campaigns</ListGroup.Item>
    //   <ListGroup.Item>Routes</ListGroup.Item>
    //   <ListGroup.Item>Activities</ListGroup.Item>
    //   <ListGroup.Item>Admin</ListGroup.Item>
    // </ListGroup>
  );
};

export default Home;
