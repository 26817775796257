import React from 'react';
import * as Route from 'services/route';
import * as Market from 'views/market/config';

export const model = 'movement';
export const label = 'Movement';
export const apiPath = '/movements';
export const routePath = () => Route.paths.campaigns.movements;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  date: {
    label: 'Date',
    type: 'date',
    required: true,
    grid: {},
  },
  createdBy: {
    label: 'Recorded By',
    form: {
      info: true,
    },
  },
  fk_itemId: {
    label: 'Item',
    required: true,
    form: {
      hide: true,
    },
  },
  fk_marketId: {
    label: 'To/From Market',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Market.apiPath,
    },
  },
  'market.name': {
    label: 'To/From Market',
    grid: {},
    form: {
      hide: true,
    },
  },
  qtyIn: {
    label: 'Qty In',
    type: 'number',
    grid: {},
  },
  qtyOut: {
    label: 'Qty Out',
    type: 'number',
    grid: {},
  },
  deliveryInfo: {
    label: 'Delivery Info',
    grid: {},
    form: {
      type: 'textarea',
    },
  },
};
