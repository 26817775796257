import React from 'react';
import Crud from 'components/crud';
import { apiPath, schema, model, label, routePath } from './config';

const Job = () => {
  return (
    <Crud
      apiPath={apiPath}
      schema={schema}
      model={model}
      label={label}
      routePath={routePath}
      showAdd={false}
      showDelete
      // icons={[
      //   {
      //     type: iconType.ACTIVATE,
      //     detail: true,
      //     onClick: () => {},
      //     tooltip: 'Activate Job',
      //   },
      // ]}
    />
  );
};

export default Job;
