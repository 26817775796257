import React, { useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import Form from 'components/form';
import * as Route from 'views/route/config';
import 'styles/detail.scss';

const StreetDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit }) => {
  if (!dataParent) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={schema}
        data={data}
        dataDefault={!data && (modelParent === Route.model ? { fk_routeId: dataParent.id } : null)}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default StreetDetail;
