import React, { useState, useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { pick, cloneDeep, capitalize } from 'lodash';
import axios from 'axios';
import pluralize from 'pluralize';
import Form from 'components/form';
import Tabs from 'components/tabs';
import Crud from 'components/crud';
import { alterSchema } from 'utils/schema';
import * as Contact from 'views/contact/config';
import * as Activity from 'views/activity/config';
import { model } from './config';
import 'styles/detail.scss';

const VendorDetail = ({ id, apiPath, schema, data, dataParent, nested, modelParent, onSubmit }) => {
  if (!nested) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});

  const schemaVendorContact = (type) => {
    const typeKey = (key) => type === 'ar' ? `${type}${capitalize(key)}` : key;
    const fields = cloneDeep(pick(schema, [
      ...[ `id_${type}Contact`, 'id' ],
      ...[ 'email', 'phone', 'mobile', 'fax', 'address', 'city', 'state', 'zip' ].map((key) => typeKey(key)),
      ...type === 'ar' ? [ 'terms', 'accountNumber', 'discount' ] : [],
    ]));

    return Object.keys(fields).reduce((acc, key, i) => ({
      ...acc,
      [key]: {
        ...fields[key],
        form: {
          ...fields[key].form,
          hide: !!fields[key].primaryKey,
          ...i === 0 && {
            onChange: async ({ item, setFieldValue }) => {
              if (item) {
                const { data } = await axios.get(`${Contact.apiPath}/${item.value}`);
                [ 'email', 'phone', 'mobile', 'fax' ].forEach((key) => setFieldValue(typeKey(key), data[key]));
              }
            },
          },
        },
      },
    }), {});
  };

  return (
    <div className="detail vendor">
      <Form
        apiPath={apiPath}
        schema={schema}
        data={data}
        dataParent={dataParent}
        modelParent={modelParent}
        onSubmit={onSubmit}
      />
      {data && <Tabs
        tabs={[
          {
            key: Contact.model,
            title: pluralize(Contact.label),
            content:
              <div>
                <Crud
                  apiPath={Contact.apiPath}
                  apiParams={{ vendorId: id }}
                  schema={alterSchema(Contact.schema, { gridCols: [ 'id', 'nameFull', 'title', 'email', 'phone' ] })}
                  schemaParent={schema}
                  routePath={Contact.routePath}
                  label={Contact.label}
                  nested
                  dataParent={data}
                  model={Contact.model}
                  modelParent={model}
                  gridHeight={200}
                  onInit={() => {
                    forceUpdate();
                  }}
                />
                <Row
                  ref={(el) => {
                    /* if (el) {
                      setNestedFormHeight(el.getBoundingVendorRect().height);
                    } */
                  }}
                >
                  <Col>
                    <Form
                      apiPath={apiPath}
                      schema={schemaVendorContact('primary')}
                      data={data}
                    />
                  </Col>
                  <Col>
                    <Form
                      apiPath={apiPath}
                      schema={schemaVendorContact('ar')}
                      data={data}
                    />
                  </Col>
                </Row>
              </div>,
          },
          {
            key: 'activity',
            title: 'Activities',
            content:
              <Crud
                apiPath={Activity.apiPath}
                apiParams={{ vendorId: id }}
                schema={alterSchema(Activity.schema, { gridColsOrder: Activity.nestedGridCols })}
                schemaParent={schema}
                routePath={Activity.routePath}
                label={Activity.label}
                nested
                dataParent={data}
                model={Activity.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
        ]}
      />}
    </div>
  );
};

export default VendorDetail;
