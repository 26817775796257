import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
// import useWebSocket from 'react-use-websocket';
import Crud from 'components/crud';
import Form from 'components/form';
import Tabs from 'components/tabs';
import Header from 'components/header';
import Spinner from 'components/spinner';
import Modal from 'components/modal';
import { alterSchema } from 'utils/schema';
import iconType from 'utils/iconType';
import useInterval from 'utils/useInterval';
import { isBA as _isBA, getToken } from 'services/auth';
import * as Job from 'views/job/config';
import Venue from 'views/venue';
import * as _Venue from 'views/venue/config';
import * as RouteSheetNote from 'views/routeSheetNote/config';
import * as RouteSheetLog from 'views/routeSheetLog/config';
import * as Photo from 'views/photo/config';
import * as VenueAgreement from 'views/venueAgreement/config';
import {
  model,
  status,
  agreementStatus,
  agreementStatusItems,
  auditStatus,
  auditStatusItems,
  type,
  isAudit,
} from './config';
import 'styles/detail.scss';
import 'styles/views/routeSheet.scss';

const RouteSheetDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit, nested, showNestedDetailModal, hideNestedDetailModal, hideDetail, tabActive }) => {
  if (!nested) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  const isBA = _isBA();
  const [ _schema, _ ] = useState(alterSchema(schema, { formFieldsHide: data?.status !== status.PROCESSED ? ['dataProcessedBy'] : [] }));
  const [ photosVenue, setPhotosVenue ] = useState(null);
  const [ photosVenueGridNode, setPhotosVenueGridNode ] = useState(null);
  const [ photosVenuesLoading, setPhotosVenuesLoading ] = useState(false);
  const [ photosVenues, setPhotosVenues ] = useState(null);
  const [ photosVenuesFormState, setPhotosVenuesFormState ] = useState(null);
  const [ photosVenueDetailFormState, setPhotosVenueDetailFormState ] = useState(null);
  const [ isPhotosVenueContactModalShowing, setIsPhotosVenueContactModalShowing ] = useState(false);
  const [ photosContainerHeight, setPhotosContainerHeight ] = useState(null);
  const [ agreementSent, setAgreementSent ] = useState(false);
  const [ isMapShowing, setIsMapShowing ] = useState(false);
  const [ venueGridApi, setVenueGridApi ] = useState(false);
  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});
  // const { sendJsonMessage, lastJsonMessage } = useWebSocket(window.webSocketURL, { protocols: getToken() });

  const setPhotoData = ({ venueDistribution, index, photo }) => {
    venueDistribution.photos[index] = {
      ...venueDistribution.photos[index],
      ...photo,
      date: photo.date ? `${moment(photo.date).format('M/D/YYYY')} ${photo.time ? moment(photo.time, 'HH:mm:ss').format('h:mm a') : ''}` : null,
    };
  };

  useEffect(async () => {
    if (photosVenue) {
      setPhotosVenues(null);
      const { data: { items: venues } } = await axios.get(`${_Venue.apiPath}?venueId=${photosVenue.id}&routeSheetId=${data.id}`);
      const { data: { items: photos } } = await axios.get(`${Photo.apiPath}/?venueId=${photosVenue.id}&routeSheetId=${data.id}&fields=id,fk_jobId`);
      setPhotosVenues(venues.map(({ distributionVenue, agreementDate }) => ({
        ...distributionVenue,
        agreementDate,
        photos: [
          {},
          ...photos.filter((photo) => photo.fk_jobId === distributionVenue.fk_jobId),
        ],
      })));
      setPhotosVenuesLoading(true);

      // TODO: uncomment when GAE standard supports websockets
      // sendJsonMessage({ routeSheetId: data.id, venueId: photosVenue.id });
    }
  }, [photosVenue]);

  useEffect(async () => {
    if (photosVenuesLoading) {
      if (photosVenues.length) {
        await Promise.all(photosVenues.map(async (venueDistribution) =>
          Promise.all(venueDistribution.photos.map(async ({ id }, index) => {
            if (id) {
              try {
                const { data: photo } = await axios.get(`${Photo.apiPath}/${id}?fields=id,photo,date,time,flagApproved`);
                setPhotoData({ venueDistribution, index, photo });
              } catch (err) {
                delete venueDistribution.fk_photoId;
              }
              setPhotosVenues([...photosVenues]);
            }
          }))));
      }
      setPhotosVenuesLoading(false);
    }
  }, [photosVenuesLoading]);

  const photosVenueDetailFormData = photosVenueDetailFormState?.values || {};
  const photosVenueContactModalOnChange = async (key, value) => {
    photosVenueDetailFormState.setFieldValue(key, value);
  };
  const photosVenueContactModalSubmit = async () => {
    setIsPhotosVenueContactModalShowing(false);
    await axios.patch(`${apiPath}/${data.id}`, {
      distributionVenue: true,
      fk_venueId: photosVenues[0].fk_venueId,
      contactName: photosVenueDetailFormData.contactName,
    });
    if (!photosVenueDetailFormState?.errors.contactEmail) {
      await axios.patch(`${apiPath}/${data.id}`, {
        distributionVenue: true,
        fk_venueId: photosVenues[0].fk_venueId,
        contactEmail: photosVenueDetailFormData.contactEmail,
      });
    }
  };

  const isPhotosVenueDisabledClick =
    (!photosVenueDetailFormData.contactName || (!photosVenueDetailFormData.contactEmail || !!photosVenueDetailFormState?.errors.contactEmail)) &&
    !photosVenueDetailFormData.refused &&
    (data?.type === type.INSTALL || [ auditStatus.REPLACED, auditStatus.DOWN ].includes(photosVenueDetailFormData.auditStatus));

  useInterval(async () => {
    const { data: venueAgreement } = await axios.get(`${VenueAgreement.apiPath}?venueId=${photosVenues[0].fk_venueId}&campaignId=${photosVenues[0].fk_campaignId}`);
    if (venueAgreement?.contactEmail) {
      photosVenueDetailFormState.setFieldValue('contactName', venueAgreement.contactName);
      photosVenueDetailFormState.setFieldValue('contactEmail', venueAgreement.contactEmail);
      photosVenueDetailFormState.setFieldValue('agreementStatus', agreementStatus.COMPLETED);
      photosVenueDetailFormState.setFieldValue('agreementDate', moment(venueAgreement.dateCreation).format('M/D/YYYY'));
    }
  }, photosVenues?.length && data.status === status.ACTIVE && isPhotosVenueDisabledClick ? 5000 : null);

  // TODO: uncomment when GAE standard supports websockets
  // useEffect(async () => {
  //   if (
  //     lastJsonMessage?.routeSheetId === data.id &&
  //     lastJsonMessage.venueId === photosVenue?.id &&
  //     !photosVenueDetailFormData.contactEmail &&
  //     !photosVenueDetailFormData.agreementDate
  //   ) {
  //     photosVenueDetailFormState.setFieldValue('contactName', lastJsonMessage.contactName);
  //     photosVenueDetailFormState.setFieldValue('contactEmail', lastJsonMessage.contactEmail);
  //     photosVenueDetailFormState.setFieldValue('agreementDate', moment(lastJsonMessage.agreementDate).format('M/D/YYYY'));
  //   }
  // }, [lastJsonMessage]);

  return (
    <>
      {isBA &&
      <Header
        label={`${data.fk_routeId} on ${moment(data.date).format('M/D')}`}
        /* icons={[
          {
            type: iconType.BACK,
            onClick: hideDetail,
            tooltip: 'Back',
          },
        ]} */
      />}
      <div className="detail route-sheet">
        {!isBA && <Form
          apiPath={apiPath}
          schema={_schema}
          data={data}
          dataDefault={!data && {
            ...modelParent === Job.model && {
              date: dataParent.dateStart,
              fk_jobId: dataParent.id,
            },
          }}
          onSubmit={onSubmit}
        />}
        {data && <Tabs
          tabs={[
            !isBA && {
              key: 'job',
              title: 'New Jobs',
              content:
                <Crud
                  apiPath={Job.apiPath}
                  apiParams={{ distributionRoute: true, routeSheetId: id }}
                  schema={alterSchema(Job.schema, { gridCols: [ 'id', 'campaign.title', 'campaign.client.name', 'item.name', 'distributionGoal' ] })}
                  schemaParent={schema}
                  routePath={Job.routePath}
                  label={Job.label}
                  nested
                  dataParent={data}
                  model={Job.model}
                  modelParent={model}
                  showAdd={false}
                  onInit={() => {
                    forceUpdate();
                  }}
                />,
            },
            !isBA && {
              key: 'distributionVenue',
              title: 'Venue Distributions',
              content:
                <Crud
                  apiPath={_Venue.apiPath}
                  apiParams={{ routeSheetId: id }}
                  schema={alterSchema(_Venue.schema, { gridCols: [ 'name', 'address', 'type.type', 'distributionVenue.item.name', 'distributionPossibleLeave', 'distributionPhoto', 'distributionRefused', isAudit(data) && 'auditStatus' ].filter(Boolean), exportEmpty: [ 'upCurrent', 'notes' ] })}
                  schemaParent={schema}
                  routePath={_Venue.routePath}
                  label={_Venue.label}
                  nested
                  dataParent={data}
                  model={_Venue.model}
                  modelParent={model}
                  showAdd={false}
                  rowClass={{
                    'has-photo': (params) => isAudit(data) ? !!params.data?.distributionPhotoAudit : !!params.data?.distributionPhoto, // eslint-disable-line
                    'has-refused': (params) => !!params.data?.distributionRefused,
                  }}
                  onInit={() => {
                    forceUpdate();
                  }}
                />,
            },
            // {
            //   key: 'routeVenue',
            //   title: 'Venues on Route',
            //   content:
            //     <Venue
            //       dataParent={data}
            //       modelParent={model}
            //     />,
            // },
            {
              key: 'photo',
              title: isBA ? 'Venues' : 'Venues on Route',
              content:
                <>
                  {/* <Header
                  detailData={data}
                  icons={[
                    data.status === status.ACTIVE && {
                      type: iconType.ACTIVATE,
                      tooltip: ({ detailData }) => data.status === status.ACTIVE ? 'Process Photos' : detailData.status === status.ACTIVE ? 'Active' : null,
                      onClick: async ({ detailData, warnConfirm }) =>
                        axios.post(apiPath, {
                          id: detailData.id,
                          processPhotos: true,
                          warnConfirm,
                        }),
                    },
                  ].filter(Boolean)}
                /> */}
                  <Row>
                    <Col
                      className={classNames('col venue', { 'photos-venue': photosVenue, 'map-showing': isMapShowing })}
                      md={photosVenue ? 6 : 12}
                      ref={(el) => {
                        if (el && !photosContainerHeight) {
                          setTimeout(() => {
                            setPhotosContainerHeight(el.getBoundingClientRect().height);
                          }, 1000);
                        }
                      }}
                    >
                      <Venue
                        dataParent={data}
                        modelParent={model}
                        schema={alterSchema(_Venue.schema, {
                          gridCols: isMapShowing ? ['name'] : photosVenue ? [ 'name', 'address', 'distributionPossibleLeave', 'distributionPhoto', 'distributionRefused', isAudit(data) && 'auditStatus' ] : [ 'name', 'address', 'distributionPossibleLeave', 'distributionPhoto', 'distributionRefused', isAudit(data) && 'auditStatus', ...!isMobile ? ['type.type'] : [] ].filter(Boolean),
                          gridDetail: !isMapShowing && {
                            name: {
                              key: 'id',
                              ..._Venue,
                            },
                          },
                          types: {
                            distributionPossibleLeave: 'boolean',
                          },
                          labels: (isMobile || photosVenue) && {
                            distributionPossibleLeave: 'Leave',
                          },
                        })}
                        // showMap={false}
                        showAdd={data.type !== type.AUDIT_UP}
                        showExport={!isBA}
                        setIsMapShowing={(showing) => {
                          if (photosVenue && showing) {
                            setPhotosVenue(false);
                            setPhotosVenueGridNode(null);
                          }
                          setIsMapShowing(showing);
                          venueGridApi?.setPinnedTopRowData([]);
                          venueGridApi?.deselectAll();
                        }}
                        onRowClick={({ node, data, event, api }) => {
                          if (event.target.classList.contains('fa')) {
                            return;
                          }
                          setPhotosVenue(data);
                          setPhotosVenueGridNode(node);
                          // if (photosVenue) {
                          //   delete photosVenue._pinned;
                          // }
                          // data._pinned = true;
                          // api.refreshInfiniteCache();
                          // api.setPinnedTopRowData([data]);
                          // api.deselectAll();
                        }}
                        gridHeight={isMobile && photosVenue ? photosContainerHeight - 285 : null}
                        rowClass={{
                          'has-photo': (params) => isAudit(data) ? !!params.data?.distributionPhotoAudit : !!params.data?.distributionPhoto,
                          'has-refused': (params) => !!params.data?.distributionRefused,
                        }}
                        onInit={({ gridApi }) => {
                          setVenueGridApi(gridApi);
                          // if (photosVenue) {
                          //   gridApi.setPinnedTopRowData([photosVenue]);
                          // }
                        }}
                      />
                    </Col>
                    {photosVenue && !isMapShowing &&
                      <>
                        <Col className="col photo" md={6}>
                          <Header label="Displays" />
                          {!photosVenues ? <Spinner /> :
                            !photosVenues.length ? <Alert variant="secondary" className="empty">No venue distribution</Alert> :
                              <>
                                <div
                                  className="list"
                                  style={{ maxHeight: !isMobile && photosContainerHeight }}
                                >
                                  {photosVenues.map((venueDistribution) => {
                                    const venueDistributionPhotos = venueDistribution.photos.filter(({ id }) => data.status !== status.PROCESSED || id);
                                    return (
                                      <div key={venueDistribution.id}>
                                        <div className="item">{venueDistribution.item.name}</div>
                                        <div className="photos">
                                          {!venueDistributionPhotos.length && data.status === status.PROCESSED ? <Alert variant="secondary" className="empty">No photos</Alert> :
                                            venueDistributionPhotos.map((photo, index) =>
                                            <Form
                                              key={photo.id}
                                              apiPath={Photo.apiPath}
                                              schema={{
                                                id: {
                                                  type: 'number',
                                                  label: 'ID',
                                                  required: true,
                                                  primaryKey: true,
                                                  form: {
                                                    hide: true,
                                                  },
                                                },
                                                photo: {
                                                  type: 'image',
                                                  form: {
                                                    readOnly: data.status === status.PROCESSED,
                                                    ...isPhotosVenueDisabledClick &&
                                                    {
                                                      disabledClick: () => setIsPhotosVenueContactModalShowing(true),
                                                    },
                                                    lazyLoading: photo.id, // photosVenuesLoading,
                                                    handleSubmit: async ({ formData }) => {
                                                      let response;
                                                      formData.append('distributionVenuePhoto', true);
                                                      const isAdd = !photo.id;
                                                      if (isAdd) {
                                                        formData.append('id', venueDistribution.id);
                                                        formData.append('fk_routeSheetId', data.id);
                                                        formData.append('fk_venueId', venueDistribution.fk_venueId);
                                                        formData.append('fk_jobId', venueDistribution.fk_jobId);
                                                        response = await axios.post(apiPath, formData);
                                                      } else {
                                                        formData.append('fk_photoId', photo.id);
                                                        response = await axios.patch(`${apiPath}/${data.id}`, formData);
                                                      }
                                                      setPhotoData({ venueDistribution, index, photo: response.data });
                                                      if (isAdd) {
                                                        venueDistribution.photos.unshift({});
                                                        response = { data: {} };
                                                      }
                                                      photosVenueGridNode.setData({ ...photosVenue, distributionPhoto: venueDistribution.photos.length - 1 });
                                                      setPhotosVenues([...photosVenues]);
                                                      return response;
                                                    },
                                                    handleDelete: () => {
                                                      axios.delete(`${apiPath}/${data.id}`, {
                                                        data: {
                                                          distributionVenuePhoto: true,
                                                          id: venueDistribution.id,
                                                          fk_photoId: photo.id,
                                                        } });
                                                      venueDistribution.photos = venueDistribution.photos.filter((_photo) => _photo !== photo);
                                                      photosVenueGridNode.setData({ ...photosVenue, distributionPhoto: photosVenues.map(({ photos }) => photos).flat().length > photosVenues.length });
                                                      setPhotosVenues([...photosVenues]);
                                                    },
                                                    download: {
                                                      filename: ({ data, dataParent }) => {
                                                        return `${dataParent.id} - ${dataParent.fk_routeId} - ${data.venue ? `${data.venue.name} - ` : ''}${data.item?.name} - ${moment(data.date).format('M-D-YY')}.jpg`;
                                                      },
                                                    },
                                                  },
                                                },
                                                ...photo.id && {
                                                  date: {
                                                    // label: 'Date',
                                                    form: {
                                                      info: true,
                                                    },
                                                  },
                                                  // time: {
                                                  //   label: 'Time',
                                                  //   form: {
                                                  //     info: true,
                                                  //   },
                                                  // },
                                                  ...!isBA && {
                                                    flagApproved: {
                                                      label: 'Approved',
                                                      type: 'boolean',
                                                      form: {
                                                        handleSubmit: ({ formData }) => {
                                                          if (formData.flagApproved) {
                                                            venueDistribution.photos.forEach((_photo) => {
                                                              photosVenuesFormState[_photo.id]?.setFieldValue('flagApproved', _photo.id === photo.id);
                                                            });
                                                          }
                                                          axios.patch(`${apiPath}/${data.id}`, {
                                                            distributionVenuePhoto: true,
                                                            id: venueDistribution.id,
                                                            fk_photoId: photo.id,
                                                            ...formData,
                                                          });
                                                          return {};
                                                        },
                                                      },
                                                    },
                                                  },
                                                },
                                              }}
                                              schemaParent={schema}
                                              data={photo}
                                              dataParent={data}
                                              modelParent={model}
                                              getState={({ setFieldValue }) => photo.id && setPhotosVenuesFormState((prevState) => ({ ...prevState, [photo.id]: { setFieldValue } }))}
                                            />)}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                                <Form
                                  apiPath={apiPath}
                                  defaultSubmitData={{ distributionVenue: true, fk_venueId: photosVenues[0].fk_venueId }}
                                  schema={{
                                    id: {
                                      type: 'number',
                                      label: 'ID',
                                      required: true,
                                      primaryKey: true,
                                      form: {
                                        hide: true,
                                      },
                                    },
                                    contactName: {
                                      label: 'Contact Name',
                                      form: {
                                        col: 1,
                                      },
                                    },
                                    contactEmail: {
                                      type: 'email',
                                      label: 'Contact Email',
                                      form: {
                                        col: 2,
                                      },
                                      forceValidate: !!photosVenueDetailFormData.contactEmail && !!photosVenueDetailFormState?.errors.contactEmail,
                                    },
                                    agreementStatus: {
                                      label: 'Agreement Status',
                                      form: {
                                        col: 1,
                                        readOnly: photosVenueDetailFormData.agreementStatus === agreementStatus.COMPLETED,
                                        type: 'autocomplete',
                                        items: photosVenueDetailFormData.agreementStatus === agreementStatus.COMPLETED ? agreementStatusItems : agreementStatusItems.filter((status) => status !== agreementStatus.COMPLETED),
                                      },
                                    },
                                    agreementDate: {
                                      label: 'Agreement',
                                      form: {
                                        col: 2,
                                        readOnly: true,
                                        className: `agreement${!photosVenueDetailFormData.agreementDate ? ' empty' : ''}`,
                                        icons: [
                                          {
                                            type: iconType.PEN,
                                            tooltip: 'Open Agreement',
                                            onClick: () =>
                                              window.open(`https://docs.google.com/forms/d/e/1FAIpQLSdWINjn3xCKiKvhh6py5lnqVB2MTPhHsOwVRD6RiILhzbzLrw/viewform?usp=pp_url&entry.558879413=${encodeURIComponent(photosVenue.name)}&entry.1659819319=${photosVenue.id}&entry.471906898=${encodeURIComponent(photosVenues.map(({ item, fk_jobId }) => `${item.name} #${fk_jobId}`).join('\n'))}`, '_blank'), // eslint-disable-line
                                          },
                                          {
                                            type: iconType.EMAIL,
                                            disabled: !photosVenueDetailFormData.contactEmail,
                                            tooltip: agreementSent ? `Sent to ${photosVenueDetailFormData.contactEmail}` : !photosVenueDetailFormData.contactEmail ? 'Enter Contact Email' : !photosVenueDetailFormData.agreementDate ? 'Send Agreement Request' : 'Resend Completed Agreement',
                                            onClick: () => {
                                              if (photosVenueDetailFormData.contactEmail) {
                                                axios.post(apiPath, {
                                                  sendAgreement: true,
                                                  contactEmail: photosVenueDetailFormData.contactEmail,
                                                  routeSheetId: data.id,
                                                  venueId: photosVenues[0].fk_venueId,
                                                });
                                                setAgreementSent(true);
                                                setTimeout(() => {
                                                  setAgreementSent(false);
                                                }, 5000);
                                              }
                                            },
                                          },
                                        ],
                                      },
                                    },
                                    refused: {
                                      label: 'Refusal',
                                      form: {
                                        type: 'autocomplete',
                                        items: [
                                          'Closed',
                                          'No Manager',
                                          'No Space',
                                          'Not Found',
                                          'Out of Business',
                                          'Refused',
                                          // '-',
                                        ],
                                        onSubmit: ({ updatedItem }) => {
                                          if (Object.keys(updatedItem).includes('refused')) {
                                            photosVenue.distributionRefused = !!updatedItem.refused;
                                            photosVenueGridNode.setData(photosVenue);
                                          }
                                        },
                                      },
                                    },
                                    ...isAudit(data) && {
                                      auditStatus: {
                                        label: 'Audit Status',
                                        form: {
                                          type: 'autocomplete',
                                          items: auditStatusItems,
                                        },
                                      },
                                    },
                                    comments: {
                                      label: 'Notes',
                                      form: {},
                                    },
                                  }}
                                  data={{ id: data.id, agreementDate: photosVenues[0].agreementDate ? moment(photosVenues[0].agreementDate).format('M/D/YYYY') : null, contactName: photosVenues[0].contactName, contactEmail: photosVenues[0].contactEmail, refused: photosVenues[0].refused, agreementStatus: photosVenues[0].agreementStatus, auditStatus: photosVenues[0].auditStatus, comments: photosVenues[0].comments }}
                                  getState={(state) => setPhotosVenueDetailFormState(state)}
                                />
                                <Modal
                                  showing={isPhotosVenueContactModalShowing}
                                  onHide={photosVenueContactModalSubmit}
                                  containerWidth
                                  body={
                                    <>
                                      <Header
                                        label={photosVenue.name}
                                      />
                                      <div className="detail">
                                        <Form
                                          apiPath={apiPath}
                                          schema={{
                                            id: {
                                              type: 'number',
                                              label: 'ID',
                                              required: true,
                                              primaryKey: true,
                                              form: {
                                                hide: true,
                                              },
                                            },
                                            contactName: {
                                              label: 'Contact Name',
                                              required: true,
                                              form: {
                                                col: 1,
                                                onChange: ({ value }) => photosVenueContactModalOnChange('contactName', value),
                                              },
                                            },
                                            contactEmail: {
                                              type: 'email',
                                              label: 'Contact Email',
                                              required: true,
                                              form: {
                                                col: 2,
                                                onChange: ({ value }) => photosVenueContactModalOnChange('contactEmail', value),
                                              },
                                              forceValidate: true,
                                            },
                                          }}
                                          dataDefault={{ id: data.id, ...photosVenueDetailFormData }}
                                          onSubmit={() => {
                                            photosVenueContactModalSubmit();
                                            document.querySelector('.rc-upload').click();
                                          }}
                                          submitLabel="Continue"
                                        />
                                      </div>
                                    </>
                                  }
                                />
                              </>}
                        </Col>
                        {/* <Col className="col job" md={4}>
                        <Crud
                          apiPath={Job.apiPath}
                          apiParams={{ distributionRoute: true, routeSheetId: id }}
                          schema={alterSchema(Job.schema, { gridCols: ['item.name'] })}
                          routePath={Job.routePath}
                          label={Job.label}
                          nested
                          dataParent={data}
                          model={Job.model}
                          modelParent={model}
                          showAdd={false}
                          checkSelect
                          onInit={() => {
                            forceUpdate();
                          }}
                        />
                      </Col> */}
                      </>}
                  </Row>
                </>,
            },
            isBA && {
              key: 'info',
              title: 'Info',
              content:
                <Form
                  apiPath={apiPath}
                  schema={alterSchema(schema, { formFieldsHide: [ 'id', 'fk_displayRepId', 'bonusRate', 'upCurrent', 'upReturned', /* 'leaveUpMissing', */ 'dataProcessedBy', 'dateCreation', 'dateMod', 'status' ], formFieldsInfo: [ 'date', 'fk_routeId', 'rep.fullName', 'distributionGoal', 'jobsUp', 'jobsLeave', 'jobsLeave', 'newVenues', 'type' ] })}
                  data={data}
                  dataDefault={!data && {
                    ...modelParent === Job.model && {
                      date: dataParent.dateStart,
                      fk_jobId: dataParent.id,
                    },
                  }}
                />,
            },
            !isBA && {
              key: 'note',
              title: 'Notes',
              content:
                <Crud
                  apiPath={`${RouteSheetNote.apiPath}/${id}`}
                  schema={RouteSheetNote.schema}
                  schemaParent={schema}
                  // routePath={RouteSheetNote.routePath}
                  label={RouteSheetNote.label}
                  nested
                  dataParent={data}
                  model={RouteSheetNote.model}
                  modelParent={model}
                  readOnly
                  // showDetailRef={showDetailRef}
                  // onRowClick={({ data }) => {
                  //   showDetailRef.current({
                  //     data: data.campaign || data.route,
                  //   });
                  // }}
                  onInit={() => {
                    forceUpdate();
                  }}
                />,
            },
            !isBA && {
              key: 'log',
              title: 'Activity',
              content:
                <Crud
                  apiPath={`${RouteSheetLog.apiPath}/${id}`}
                  schema={RouteSheetLog.schema}
                  schemaParent={schema}
                  label={RouteSheetLog.label}
                  nested
                  dataParent={data}
                  model={RouteSheetLog.model}
                  modelParent={model}
                  readOnly
                />,
            },
          ].filter(Boolean)}
          tabActive={tabActive}
          onChange={() => {
            setPhotosVenue(null);
            setPhotosVenueGridNode(null);
            setIsMapShowing(false);
          }}
        />}
      </div>
    </>
  );
};

export default RouteSheetDetail;
