import * as Route from 'services/route';
import * as Market from 'views/market/config';
import * as Neighborhood from 'views/neighborhood/config';

export const model = 'route';
export const label = 'Route';
export const apiPath = '/routes';
export const routePath = () => Route.paths.routes.routes;
export const schema = {
  _id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  id: {
    label: 'ID',
    required: true,
    grid: {},
    form: {},
  },
  fk_marketId: {
    label: 'Market',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Market.apiPath,
      onChange: async ({ item, fields, setFieldValue }) => {
        if (item) {
          await fields.find(({ key }) => key === 'fk_neighborhoodId').form.initItems({ fk_marketId: item.value });
          setFieldValue('fk_neighborhoodId', '');
        }
      },
    },
  },
  'market.name': {
    label: 'Market',
    grid: {},
    form: {
      hide: true,
    },
  },
  fk_neighborhoodId: {
    label: 'Neighborhood',
    // required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: (data) => data && data.fk_marketId ? `${Neighborhood.apiPath}?marketId=${data.fk_marketId}` : null,
    },
  },
  'neighborhood.name': {
    label: 'Neighborhood',
    grid: {},
    form: {
      hide: true,
    },
  },
  venueTotal: {
    label: 'Venues',
    type: 'number',
    grid: {},
    form: {
      info: true,
    },
  },
  dateLast: {
    label: 'Last Visited',
    type: 'date',
    grid: {},
    form: {},
  },
  health: {
    label: 'Health',
    form: {
      type: 'autocomplete',
      items: [ 'Good', 'Fair', 'Bad' ],
    },
  },
  jobsLeave: {
    label: 'Leave Up',
    form: {
      hide: true,
    },
  },
  notes: {
    label: 'Notes',
    form: {
      type: 'textarea',
      colBreak: true,
    },
  },
  tags: {
    label: 'Tags',
    form: {
      type: 'tag',
      colBreak: true,
      colWidth: 2,
    },
  },
  dateCreation: {
    label: 'Created On',
    type: 'date',
    form: {
      info: true,
    },
  },
  dateMod: {
    label: 'Modified On',
    type: 'date',
    form: {
      info: true,
    },
  },
};
