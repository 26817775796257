export const model = 'googlePlace';
export const label = 'Google Places';
export const apiPath = '/googlePlaceExcludes';
export const schema = {
  id: {
    type: 'string',
    label: 'ID',
    required: true,
    primaryKey: true,
  },
  name: {
    label: 'Name',
    grid: {
      minWidth: '400',
    },
  },
};
