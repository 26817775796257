import axios from 'axios';
import { camelCase, startCase } from 'lodash';
import { paths } from 'services/route';
import { isBA } from 'services/auth';
import iconType from 'utils/iconType';
import * as AuditRatio from 'utils/auditRatio';
import * as Route from 'views/route/config';
import * as User from 'views/user/config';
import * as Market from 'views/market/config';
import * as Item from 'views/item/config';

export const status = {
  PENDING: '',
  ACTIVE: '',
  PROCESSED: '',
};
const statusItems = Object.keys(status).map((key) => {
  status[key] = startCase(camelCase(key));
  return status[key];
});

export const agreementStatus = {
  INTERESTED: '',
  NOT_INTERESTED: '',
  COMPLETED: '',
};
export const agreementStatusItems = Object.keys(agreementStatus).map((key) => {
  agreementStatus[key] = startCase(camelCase(key));
  return agreementStatus[key];
});

export const auditStatus = {
  UP: '',
  DOWN: '',
  REPLACED: '',
};
export const auditStatusItems = Object.keys(auditStatus).map((key) => {
  auditStatus[key] = startCase(camelCase(key));
  return auditStatus[key];
});

export const type = {
  INSTALL: '',
  AUDIT: '',
  AUDIT_UP: '',
};
const typeItems = Object.keys(type).map((key) => {
  type[key] = startCase(camelCase(key));
  return type[key];
});

export const isAudit = (data) => data.type !== type.INSTALL;

export const model = 'routeSheet';
export const label = 'Route Sheet';
export const apiPath = '/routeSheets';
export const routePath = () => paths.routes.routeSheets;
export const schema = {
  id: {
    label: 'ID',
    required: true,
    primaryKey: true,
    grid: { hide: false },
    form: {
      hideAdd: true,
      info: true,
    },
  },
  date: {
    label: 'Date',
    type: 'date',
    grid: {},
    form: {},
  },
  fk_campaignId: {
    form: {
      hide: true,
    },
  },
  fk_jobId: {
    form: {
      hide: true,
    },
  },
  fk_routeId: {
    label: 'Route',
    // required: true,
    grid: {
      ...!isBA() && {
        detail: () => ({
          key: 'route._id',
          ...Route,
        }),
      },
    },
    form: {
      type: 'autocomplete',
      primaryKey: '_id',
      valueKey: 'id',
      labelKey: 'id',
      itemsUrl: () => Route.apiPath,
      onChange: async ({ item, fields, setValues }) => {
        if (item) {
          const { data } = await axios.get(`${Route.apiPath}/${item.primaryKey}`);
          setValues({ 'route.market.name': data.market.name, 'route.neighborhood.name': data.neighborhood.name });
        }
      },
    },
  },
  'route.market.name': {
    label: 'Market',
    grid: {},
    form: {
      info: true,
    },
  },
  'route.neighborhood.name': {
    label: 'Neighborhood',
    form: {
      info: true,
    },
  },
  'distributionVenue.job.campaign.title': {
    label: 'Campaign',
    form: {
      hide: true,
    },
  },
  'distributionVenue.item.name': {
    label: 'Item',
    form: {
      hide: true,
    },
  },
  'distributionRoute.fk_jobId': {
    label: 'Job',
    form: {
      hide: true,
    },
  },
  'distributionRoute.item.name': {
    label: 'Item',
    form: {
      hide: true,
    },
  },
  fk_displayRepId: {
    label: 'BA',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'fullName',
      itemsUrl: () => `${User.apiPath}?rep=true`,
    },
  },
  'rep.fullName': {
    label: 'BA',
    grid: {},
    form: {
      hide: true,
    },
  },
  type: {
    label: 'Type',
    required: true,
    grid: {},
    form: {
      type: 'autocomplete',
      items: typeItems,
      defaultValue: 'Install',
    },
  },
  // notes: {
  //   label: 'Notes',
  //   form: {
  //     type: 'textarea',
  //     colBreak: true,
  //   },
  // },
  // tags: {
  //   label: 'Tags',
  //   form: {
  //     type: 'tag',
  //     colBreak: true,
  //     colWidth: 2,
  //   },
  // },
  jobsUp: {
    type: 'number',
    label: 'New Jobs',
    grid: {},
    form: {
      colBreak: true,
    },
  },
  jobsLeave: {
    type: 'number',
    label: 'Leave Up Jobs',
    form: {},
  },
  bonusRate: {
    type: 'price',
    label: 'Bonus Rate',
    form: {},
  },
  distributionGoal: {
    type: 'number',
    label: 'Goal',
    grid: {},
    form: {},
  },
  // possibleNew: {
  //   type: 'number',
  //   label: 'Possible New',
  //   form: {
  //     info: true,
  //   },
  // },
  // 'distributionVenue.flagPay': {
  //   label: 'Payable',
  //   type: 'boolean',
  //   form: {
  //     hide: true,
  //   },
  // },
  venuesAlltime: {
    type: 'number',
    label: 'Venues All-Time',
    form: {
      info: true,
    },
  },
  newVenues: {
    type: 'number',
    label: 'New Venues',
    form: {
      info: true,
    },
  },
  upReturned: {
    type: 'number',
    label: 'Up Returned',
    form: {
      info: true,
    },
  },
  upCurrent: {
    type: 'number',
    label: 'Up Current',
    form: {
      info: true,
    },
  },
  venuesAudit: {
    label: 'Audit',
    type: 'number',
    grid: {
      cellRenderer: ({ params, dataParent }) => AuditRatio.renderer(params.data),
    },
    form: {
      info: true,
      infoRenderer: AuditRatio.renderer,
    },
  },
  // replaced: {
  //   type: 'number',
  //   label: 'Replaced',
  //   form: {
  //     info: true,
  //   },
  // },
  // leaveUpMissing: {
  //   type: 'number',
  //   label: 'Leave Up Missing',
  //   form: {
  //     info: true,
  //   },
  // },
  status: {
    label: 'Status',
    grid: {},
    form: {
      type: 'autocomplete',
      items: statusItems,
      defaultValue: 'Pending',
      info: true,
    },
  },
  dataProcessedBy: {
    label: 'Processed By',
    form: {
      info: true,
    },
  },
  // mobileStatus: {
  //   label: 'Mobile',
  //   grid: {},
  //   form: {
  //     type: 'autocomplete',
  //     items: [ 'Ready', 'Completed', 'Uploaded' ],
  //     defaultValue: 'Ready',
  //   },
  // },
  dateCreation: {
    label: 'Created On',
    type: 'date',
    form: {
      info: true,
    },
  },
  dateMod: {
    label: 'Modified On',
    type: 'date',
    form: {
      info: true,
    },
  },
};

export const icons = [
  {
    detail: true,
    type: ({ detailData }) => detailData.status === status.PENDING ? iconType.ACTIVATE : detailData.status === status.ACTIVE ? iconType.ACTIVE : detailData.status === status.PROCESSED ? iconType.COMPLETE : null,
    tooltip: ({ detailData }) => detailData.status === status.PENDING ? 'Activate Route Sheet' : detailData.status === status.ACTIVE ? 'Process Route Sheet' : detailData.status === status.PROCESSED ? 'Processed' : null,
    readOnly: ({ detailData }) => detailData.status === status.PROCESSED,
    onClick: async ({ detailData, warnConfirm }) =>
      detailData.status === status.PENDING ?
        axios.post(apiPath, {
          id: detailData.id,
          activate: true,
          warnConfirm,
        }) :
      detailData.status === status.ACTIVE ?
        axios.post(apiPath, {
          id: detailData.id,
          process: true,
          warnConfirm,
        }) : null,
  },
];
