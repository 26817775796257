import React from 'react';
import { camelCase, startCase } from 'lodash';
import { nanoid } from 'nanoid';
import { paths } from 'services/route';
import { role } from 'services/auth';
import state from 'data/state';
import * as Market from 'views/market/config';
import * as Client from 'views/client/config';

// export const title = {
//   ACCOUNT_ASSISTANT: '',
//   ACCOUNT_EXECUTIVE: '',
//   DISPLAY_CONTRACTOR: '',
//   BA: 'BA',
//   GRAPHIC_DESIGNER: '',
//   HR_DIRECTOR: '',
//   MARKET_CONTRACTOR: '',
//   ROUTE_MANAGER: '',
//   SALESPERSON: '',
//   WEB_PRODUCER: '',
// };
// Object.keys(title).map((key) => {
//   if (!title[key]) {
//     title[key] = startCase(camelCase(key));
//   }
//   return title[key];
// });

export const model = 'user';
export const label = 'Personnel';
export const apiPath = '/users';
export const routePath = () => paths.admin.users;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  firstName: {
    label: 'First Name',
    required: true,
    grid: {},
    form: {
      col: 1,
    },
  },
  lastName: {
    label: 'Last Name',
    required: true,
    grid: {},
    form: {
      col: 2,
    },
  },
  email: {
    type: 'email',
    label: 'Email',
    required: true,
    grid: {},
    form: {
      colWidth: 3,
    },
  },
  password: {
    label: 'Password',
    form: {
      readOnly: true,
      inlineButton: {
        label: 'Generate',
        tooltip: 'Copied',
        tooltipTrigger: 'focus',
        onClick: (field) => {
          const newPassword = nanoid(12);
          field.form.setFieldValue('password', newPassword);
          field.form.submitValue(field, newPassword);
          window.navigator.clipboard.writeText(newPassword);
        },
      },
      colWidth: 3,
    },
    // type: 'password',
  },
  role: {
    label: 'Role',
    form: {
      type: 'autocomplete',
      items: Object.values(role),
      colWidth: 3,
    },
  },
  fk_clientId: {
    label: 'Company',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Client.apiPath,
      colWidth: 3,
    },
  },
  'client.name': {
    label: 'Company',
    grid: {},
    form: {
      hide: true,
    },
  },
  title: {
    label: 'Title',
    grid: {},
    form: {
      // type: 'autocomplete',
      // items: Object.values(title),
    },
  },
  fk_marketId: {
    label: 'Market',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Market.apiPath,
    },
  },
  'market.name': {
    label: 'Market',
    grid: {},
    form: {
      hide: true,
    },
  },
  address: {
    label: 'Address',
  },
  city: {
    label: 'City',
  },
  state: {
    label: 'State',
    form: {
      col: 1,
      type: 'autocomplete',
      items: state,
    },
  },
  zip: {
    label: 'Zip',
    form: {
      col: 2,
    },
  },
  phone: {
    label: 'Phone',
    form: {
      col: 1,
    },
  },
  mobile: {
    label: 'Mobile',
    form: {
      col: 2,
    },
  },
  active: {
    label: 'Active',
    type: 'boolean',
    grid: {},
  },
  // dob: {
  //   label: 'DOB',
  //   type: 'date',
  //   form: {
  //     colBreak: true,
  //   },
  // },
  // ssn: {
  //   label: 'SSN',
  // },
  paymentBasis: {
    label: 'Payment Basis',
    form: {
      type: 'autocomplete',
      items: [
        'Hourly',
        'Per Display',
      ],
      colBreak: true,
    },
  },
  hourlyRate: {
    label: 'Hourly Rate',
    type: 'price',
  },
  dailyPayBase: {
    label: 'Daily Pay Base',
    type: 'price',
  },
  dailyPayGoal: {
    label: 'Daily Pay Goal',
    type: 'price',
  },
  dateStart: {
    label: 'Start Date',
    type: 'date',
  },
  dateLastReview: {
    label: 'Last Review Date',
    type: 'date',
  },
  dateTermination: {
    label: 'Termination Date',
    type: 'date',
  },
  notes: {
    label: 'Notes',
    form: {
      type: 'textarea',
      colBreak: true,
    },
  },
  dateCreation: {
    label: 'Created On',
    type: 'date',
    form: {
      info: true,
    },
  },
  dateMod: {
    label: 'Modified On',
    type: 'date',
    time: true,
    form: {
      info: true,
    },
  },
};
