import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Form from 'components/form';
import Tabs from 'components/tabs';
import * as Contact from 'views/contact/config';
import * as Venue from 'views/venue/config';
import * as Campaign from 'views/campaign/config';
import Attachment from 'views/attachment/index';
import { model } from './config';
import 'styles/detail.scss';

const ActivityDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit }) => {
  if (!dataParent) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  const [ formState, setFormState ] = useState(null);
  useEffect(async () => {
    if (formState && !schema.fk_venueId.form.items?.length) {
      schema.fk_venueId.form.itemsUrl = () => `${Venue.apiPath}?active=true`;
      await schema.fk_venueId.form.initItems?.();
      schema.fk_venueId.form.itemsUrl = () => null;
      if (data?.fk_venueId) {
        formState.setFieldValue('fk_venueId', data.fk_venueId);
      }
    }
  }, [formState]);

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={schema}
        data={data}
        dataDefault={!data && (modelParent ? {
          [`fk_${modelParent}Id`]: dataParent.id,
          status: 'Open',
          ...modelParent === Contact.model && {
            fk_clientId: dataParent.fk_clientId,
            fk_vendorId: dataParent.fk_vendorId,
            ...dataParent.fk_vendorId && { fk_vendorContactId: dataParent.id },
            fk_venueId: dataParent.fk_venueId,
            ...dataParent.fk_venueId && { fk_venueContactId: dataParent.id },
          },
          ...modelParent === Campaign.model && {
            fk_clientId: dataParent.fk_clientId,
            fk_contactId: dataParent.fk_primaryContactId,
          },
        } : null)}
        onSubmit={onSubmit}
        getState={setFormState}
      />
      {data && <Tabs
        tabs={[
          {
            key: 'attachment',
            title: 'Attachments',
            content:
              <Attachment
                schemaParent={schema}
                modelParent={model}
                dataParent={data}
              />,
          },
        ]}
      />}
    </div>
  );
};

export default ActivityDetail;
