import React from 'react';
import { startCase, camelCase, get } from 'lodash';
import * as Route from 'services/route';
import * as AuditRatio from 'utils/auditRatio';
import * as Campaign from 'views/campaign/config';
import * as CampaignItem from 'views/campaignItem/config';
import * as CampaignMarket from 'views/campaignMarket/config';
import * as Item from 'views/item/config';
import * as Client from 'views/client/config';

export const status = {
  PENDING: '',
  IN_PROGRESS: '',
  COMPLETED: '',
};
const statusItems = Object.keys(status).map((key) => {
  status[key] = startCase(camelCase(key));
  return status[key];
});

export const model = 'job';
export const label = 'Job';
export const apiPath = '/jobs';
export const routePath = () => Route.paths.campaigns.jobs;
export const schema = {
  id: {
    label: 'ID',
    required: true,
    primaryKey: true,
    grid: {
      hide: false,
    },
    form: {
      hideAdd: true,
      info: true,
    },
  },
  fk_campaignId: {
    label: 'Campaign',
    form: {
      hide: true,
    },
  },
  'campaign.title': {
    label: 'Campaign',
    grid: {
      minWidth: 150,
      detail: () => ({
        key: 'campaign.id',
        ...Campaign,
      }),
    },
    form: {
      info: true,
    },
  },
  'campaign.client.name': {
    label: 'Company',
    grid: {
      minWidth: 150,
      detail: () => ({
        key: 'campaign.client.id',
        ...Client,
      }),
    },
    form: {
      info: true,
    },
  },
  /* 'campaign.notes': {
    label: 'Notes',
    grid: {
      minWidth: 150,
    },
  }, */
  fk_itemId: {
    label: 'Items',
    type: 'array',
    required: true,
    form: {
      hideEdit: true,
      type: 'checkbox',
      valueKey: 'item.id',
      labelKey: 'item.name',
      itemsUrl: ({ fk_campaignId: campaignId } = {}) => campaignId ? `${CampaignItem.apiPath}?campaignId=${campaignId}` : null,
      itemsWrap: 10,
    },
  },
  'item.name': {
    label: 'Item',
    grid: {
      hide: true,
      detail: () => ({
        key: 'item.id',
        ...Item,
      }),
      minWidth: 150,
    },
    form: {
      info: true,
    },
  },
  fk_campaignMarketId: {
    label: 'Markets',
    type: 'array',
    required: true,
    form: {
      hide: true,
      type: 'checkbox',
      valueKey: 'id',
      labelKey: 'market.name',
      itemsUrl: ({ fk_campaignId: campaignId } = {}) => campaignId ? `${CampaignMarket.apiPath}?campaignId=${campaignId}` : null,
      itemsWrap: 10,
    },
  },
  'campaignMarket.market.name': {
    label: 'Market',
    grid: {},
    form: {
      info: true,
    },
  },
  'campaignMarket.neighborhoods': {
    label: 'Neighborhoods',
    form: {
      info: true,
      valueGetter: (data) => {
        const neighborhoods = get(data, 'campaignMarket.neighborhoods');
        return neighborhoods?.length ? neighborhoods.map(({ name, i }) => <div key={i}>{name}</div>) : null;
      },
    },
  },
  distributionGoal: {
    label: 'Dist Goal',
    type: 'number',
    form: {
      col: 1,
    },
  },
  venuesAlltime: {
    label: 'Venues All-Time',
    type: 'number',
    grid: {
      minWidth: 125,
    },
    form: {
      info: true,
    },
  },
  upCurrent: {
    label: 'Up Current',
    type: 'number',
    grid: {},
    form: {
      info: true,
    },
  },
  venuesAudit: {
    label: 'Audit %',
    type: 'number',
    grid: {
      cellRenderer: ({ params, dataParent }) => AuditRatio.renderer(params.data),
    },
    form: {
      info: true,
      infoRenderer: AuditRatio.renderer,
    },
  },
  dateStart: {
    label: 'Start Date',
    type: 'date',
    grid: {
      minWidth: 50,
    },
    form: {},
  },
  status: {
    label: 'Status',
    grid: {},
    form: {
      type: 'autocomplete',
      items: statusItems,
    },
  },
  /* notes: {
    label: 'Notes',
    form: {
      type: 'textarea',
      colBreak: true,
      colWidth: 2,
    },
  },
  tags: {
    label: 'Tags',
    form: {
      type: 'tag',
      colBreak: true,
      colWidth: 2,
    },
  }, */
  /* createdBy: {
    label: 'Created By',
    form: {
      info: true,
    },
  },
  dateCreation: {
    label: 'Created On',
    type: 'date',
    form: {
      info: true,
    },
  },
  modifiedBy: {
    label: 'Modified By',
    form: {
      info: true,
    },
  },
  dateMod: {
    label: 'Modified On',
    type: 'date',
    form: {
      info: true,
    },
  }, */
};
