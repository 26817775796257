import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Crud from 'components/crud';
import Form from 'components/form';
import Tabs from 'components/tabs';
import { alterSchema } from 'utils/schema';
import Venue from 'views/venue';
import * as RouteSheet from 'views/routeSheet/config';
import * as Street from 'views/street/config';
import { model } from './config';
import 'styles/detail.scss';
import 'styles/views/venue.scss';

const RouteDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit, nested, hideNestedDetailModal }) => {
  if (!nested) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }
  const [ routeFormState, setRouteFormState ] = useState(data);

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={schema}
        data={data}
        onSubmit={onSubmit}
        getState={(state) => setRouteFormState(state.values)}
      />
      {data && <Tabs
        tabs={[
          {
            key: 'venue',
            title: 'Venues',
            content:
              <Venue
                dataParent={routeFormState}
                modelParent={model}
              />,
          },
          {
            key: 'routeSheet',
            title: 'Sheets',
            content:
              <Crud
                apiPath={RouteSheet.apiPath}
                apiParams={{ routeId: data.id }}
                schema={alterSchema(RouteSheet.schema, { gridCols: [ 'id', 'date', 'route.market.name', 'rep.fullName', 'distributionGoal', 'status' ] })}
                schemaParent={schema}
                label={RouteSheet.label}
                nested
                dataParent={data}
                model={RouteSheet.model}
                modelParent={model}
                routePath={RouteSheet.routePath}
                showAdd={false}
                showDelete
                icons={RouteSheet.icons}
              />,
          },
          {
            key: 'street',
            title: 'Streets',
            content:
              <Crud
                apiPath={Street.apiPath}
                apiParams={{ routeId: data.id }}
                schema={Street.schema}
                schemaParent={schema}
                label={Street.label}
                nested
                dataParent={data}
                model={Street.model}
                modelParent={model}
                routePath={Street.routePath}
              />,
          },
          /* {
            key: 'photo',
            title: 'Photos',
            content: null,
          }, */
        ]}
      />}
    </div>
  );
};

export default RouteDetail;
