import React, { useState } from 'react';
import classNames from 'classnames';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Actions } from 'redux/auth';
import { ReactComponent as Logo } from 'images/logo.svg';
import { isAuthenticated, getUserRole } from 'services/auth';
import { routes, paths, matchRoute } from 'services/route';
import 'styles/nav.scss';

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(Actions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
  const { pathname } = useLocation();
  const [ dropdownOpen, toggleDropdownOpen ] = useState(undefined);

  return (
      <Navbar sticky="top" expand="lg" className={classNames({ 'dropdown-open': dropdownOpen != null, anon: !isAuthenticated() })}>
        <Container>
          <LinkContainer to={paths.home}>
            <Navbar.Brand href="#"><Logo className="logo" /></Navbar.Brand>
          </LinkContainer>
          {isAuthenticated() &&
              <>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav>
                    {routes.filter(({ path, roles }) => ![ paths.home, paths.login ].includes(path) && (!roles || roles.includes(getUserRole()))).map((route, r) => {
                      const { label, children, path, navPath, index, key } = route;
                      const childRoute = matchRoute({ pathname, children });
                      return (
                        <NavDropdown
                          title={path ? <LinkContainer to={navPath ?? path}><div onClick={() => toggleDropdownOpen()}>{label}</div></LinkContainer> : label}
                          className={classNames(key, { active: childRoute || (index && matchRoute({ pathname }) === route) })}
                          onMouseEnter={() => {
                            toggleDropdownOpen(r);
                          }}
                          onMouseLeave={() => {
                            toggleDropdownOpen();
                          }}
                          show={dropdownOpen === r}
                          key={r}
                        >
                          {children
                            .filter(({ nested, roles }) => !nested && (!roles || roles.includes(getUserRole())))
                            .map(({ label, path, onClick, divider }, c) => path ?
                            <LinkContainer to={path} key={c}>
                              <NavDropdown.Item onClick={() => toggleDropdownOpen()}>{label}</NavDropdown.Item>
                            </LinkContainer> : divider ?
                            <NavDropdown.Divider key={c} /> :
                            <NavDropdown.Item
                              onClick={() => {
                                toggleDropdownOpen();
                                onClick(props);
                              }}
                              key={c}
                            >
                              {label}
                            </NavDropdown.Item>)}
                        </NavDropdown>
                      );
                    })}
                  </Nav>
                </Navbar.Collapse>
              </>}
        </Container>
      </Navbar>
  );
});
