import React, { useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import Form from 'components/form';
import 'styles/detail.scss';
import * as CampaignMarket from './config';

const CampaignMarketDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit }) => {
  if (!dataParent) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={schema}
        data={data}
        dataDefault={!data ? { fk_campaignId: dataParent.id } : null}
        model={CampaignMarket.model}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default CampaignMarketDetail;
