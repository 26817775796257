import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  matchPath,
} from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import 'styles/app.scss';
import 'styles/grid.scss';
import 'styles/form.scss';
import 'styles/button.scss';
import 'styles/tooltip.scss';
import Nav from 'components/nav';
import Breadcrumb from 'components/breadcrumb';
import Spinner from 'components/spinner';
import { Actions } from 'redux/auth';
import { routes, paths } from 'services/route';
import { isAuthenticated, isBA, isClient } from 'services/auth';

axios.defaults.baseURL = `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_HOST : ''}${process.env.REACT_APP_API_PATH}`;
// window.webSocketURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_HOST.replace('http:', 'ws:') : window.location.origin.replace('https:', 'wss:');
/* axios.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401 && error.response?.data?.msg?.toLowerCase() !== 'invalid login') {
    window.location.href = `/#/${paths.login}`;
  }
  return Promise.reject(error);
}); */

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

const Auth = connect(mapStateToProps)(() => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const checkPathBA = () => {
    if (isBA() && !matchPath(paths.user.profile, pathname) && !pathname.startsWith(`/${paths.routes.routeSheets}`)) {
      navigate(paths.routes.routeSheets);
      return false;
    }
    return true;
  };

  const checkPathClient = () => {
    if (isClient() && !matchPath(paths.user.profile, pathname) && !pathname.startsWith(`/${paths.campaigns.campaigns}`)) {
      navigate(paths.campaigns.campaigns);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isAuthenticated()) {
      if (matchPath(paths.login, pathname)) {
        const anonPath = window.localStorage.getItem('anonPath');
        window.localStorage.removeItem('anonPath');
        if (checkPathBA() || checkPathClient()) {
          navigate(anonPath ?? paths.home);
        }
      } else {
        checkPathBA();
        checkPathClient();
      }
    } else if (!matchPath(paths.login, pathname)) {
      window.localStorage.setItem('anonPath', pathname);
      navigate(paths.login);
    }
  }, [ isAuthenticated(), pathname ]);

  return null;
});

const buildRoutes = (_routes = routes) => _routes
  .filter(({ children, Component }) => children || Component)
  .map(({ children, path, Component, DetailComponent }, r) => (
    <React.Fragment key={r}>
      {Component &&
      <Route path={path} element={<Component />} key={r}>
        {DetailComponent &&
          <>
            <Route path=":id" element={<DetailComponent />} />
            <Route path="add" element={<DetailComponent />} />
          </>}
      </Route>}
      {children && buildRoutes(children)}
    </React.Fragment>
  ));

const App = ({ refresh }) => {
  const [ isLoading, setLoading ] = useState(true);
  useEffect(async () => {
    if (isLoading) {
      await refresh();
      setLoading(false);
    }
  }, []);

  return (
    <Router>
      {!isLoading && <Auth />}
      <Nav />
      <Container>
        {isLoading ? <Spinner /> :
        <>
        {!isBA() && <Breadcrumb />}
          <Routes>
            <Route path="/">
              {buildRoutes()}
            </Route>
          </Routes>
        </>}
      </Container>
    </Router>
  );
};

const mapDispatchToProps = (dispatch) => ({
  refresh: () => dispatch(Actions.refresh()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
