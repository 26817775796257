import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Crud from 'components/crud';
import Form from 'components/form';
import Spinner from 'components/spinner';
import isEmpty from 'utils/isEmpty';
import iconType from 'utils/iconType';
import * as Campaign from 'views/campaign/config';
import * as Client from 'views/client/config';
import * as SalesGoal from 'views/salesGoal/config';
import { apiPath, model, label, routePath } from './config';
import 'styles/views/bookedBusiness.scss';

const cellRendererQuarter = ({ params: { value, data } }) =>
  !isEmpty(value) ? `${value.toLocaleString()}${data._percent ? '%' : ''}` : '-';

const calcSortComparatorValue = ({ value, node, isDescending, key }) => {
  value = value || 0;
  if (node.data._groupParent) {
    const groupParentValue = node.data._groupParent[key];
    if (value) {
      const digits = Math.max(
        ...node.data._groupParent._group
          .map((item) => item[key] && item[key].toString().length)
          .filter(Boolean),
      );
      if (digits) {
        value = groupParentValue + (value / Math.pow(10, digits + (isDescending ? 1 : 0))); // eslint-disable-line
      }
    } else {
      value = groupParentValue;
    }
  } else if (node.data._group) {
    value += isDescending ? 0.1 : 0;
  }
  return value;
};

const sortComparator = ({ valueA, valueB, nodeA, nodeB, isDescending, key }) =>
  calcSortComparatorValue({
    value: valueA,
    node: nodeA,
    isDescending,
    key,
  }) -
  calcSortComparatorValue({
    value: valueB,
    node: nodeB,
    isDescending,
    key,
  });

const reportYearMin = moment().subtract(10, 'years').year();
const reportYearMax = moment().add(1, 'years').year();

const BookedBusiness = () => {
  const [ schema, setSchema ] = useState(null);
  const [ data, setData ] = useState(null);
  const [ gridApi, setGridApi ] = useState(null);
  const [ reportDate, setReportDate ] = useState(moment().endOf('year'));

  useEffect(async () => {
    const [
      { data: { items: campaigns } },
      { data: { items: salesGoals } },
    ] = await Promise.all([
      axios.get(`${Campaign.apiPath}/?all=true&fields=id,title,client.name,status,type,date,dateStart,dateEnd,invoiceTotal,minBudget,maxBudget,hot&order=client.name`),
      axios.get(SalesGoal.apiPath),
    ]);

    const calcGroupLineItem = ({ group, revenue, dateStart, dateEnd, client, campaign }) => {
      let lineItem = group[0];
      if (campaign?.id) {
        lineItem = { client, campaign, _groupChild: true, _groupParent: group[0] };
        group.push(lineItem);
        group[0]._group.push(lineItem);
      } else if (client) {
        lineItem = group.find(({ client: { id } = {} }) => id === client.id);
        if (!lineItem) {
          lineItem = { client, campaign, _groupChild: true, _groupParent: group[0] };
          group.push(lineItem);
          group[0]._group.push(lineItem);
        }
      }
      const campaignDays = dateEnd.diff(dateStart, 'days') + 1;
      for (const m = moment(dateStart).startOf('month'); m.isSameOrBefore(moment(dateEnd).startOf('month')); m.add(1, 'months')) {
        if (m.year() === reportDate.year()) {
          const monthNum = m.format('M');
          const monthKey = `month${monthNum}`;
          const monthDays = (
            dateStart.isSame(m, 'month') ? moment(dateStart).endOf('month').date() - dateStart.date() + 1 :
            dateEnd.isSame(m, 'month') ? dateEnd.date() :
            m.daysInMonth()
          );
          const monthPercent = monthDays / campaignDays;
          const monthTotal = Math.round(revenue * monthPercent);
          lineItem[monthKey] = (lineItem[monthKey] || 0) + monthTotal;
          const quarterKey = `quarter${m.quarter()}`;
          lineItem[quarterKey] = (lineItem[quarterKey] || 0) + monthTotal;
          lineItem.year = (lineItem.year || 0) + monthTotal;
        }
      }
    };

    const appendGroupLineItem = ({ id, title, group, campaigns, revenue, dateStart, dateEnd, client }) => {
      dateStart = moment(dateStart);
      dateEnd = moment(dateEnd);
      [
        null,
        ...campaigns.filter((campaign) => campaign.client?.id === client.id).length > 1 ? [{ client }] : [],
        { client, campaign: { id, title } },
      ].map((lineItem) => calcGroupLineItem({
        group, revenue, dateStart, dateEnd, client: lineItem?.client, campaign: lineItem?.campaign,
      }));
    };

    const bookedBusinessTotal = { label: 'Booked Business', _group: [] };
    const bookedBusiness = [bookedBusinessTotal];
    const bookedCampaigns = campaigns.filter(({ title, status, date: agreementDate, dateStart, dateEnd, invoiceTotal }) =>
      [ Campaign.status.IN_PROGRESS, Campaign.status.COMPLETED ].includes(status) &&
      moment(agreementDate).isSameOrBefore(reportDate) &&
      (moment(dateStart).year() === reportDate.year() || moment(dateEnd).year() === reportDate.year()) && invoiceTotal);
    bookedCampaigns.forEach(({ id, title, dateStart, dateEnd, invoiceTotal: revenue, client }) => {
      appendGroupLineItem({
        id, title, group: bookedBusiness, campaigns: bookedCampaigns, revenue, dateStart, dateEnd, client,
      });
    });

    const weightedPipelineTotal = { label: 'Weighted Pipeline', _group: [] };
    const weightedPipeline = [weightedPipelineTotal];
    const pipelineCampaigns = campaigns.filter(({ status, dateStart, dateEnd, minBudget, maxBudget }) =>
      status === Campaign.status.PROPOSED &&
      (moment(dateStart).year() === reportDate.year() || moment(dateEnd).year() === reportDate.year()) &&
      (minBudget || maxBudget));
    pipelineCampaigns.forEach(({ id, title, dateStart, dateEnd, minBudget, maxBudget, hot, client }) => {
      const revenue = (!maxBudget ? minBudget : !minBudget ? maxBudget : (minBudget + maxBudget) / 2) * (hot ? ((hot * 10) / 100) : 1);
      appendGroupLineItem({
        id, title, group: weightedPipeline, campaigns: pipelineCampaigns, revenue, dateStart, dateEnd, client,
      });
    });

    const projectedBusiness = { label: 'Projected Business' };
    [ ...new Set(Object.keys(bookedBusinessTotal)), ...new Set(Object.keys(weightedPipelineTotal)) ]
      .filter((key) => [ 'month', 'quarter', 'year' ].some((prefix) => key.startsWith(prefix)))
      .forEach((key) => {
      const bookedBusinessValue = bookedBusinessTotal[key];
      const weightedPipelineValue = weightedPipelineTotal[key];
      if (!isEmpty(bookedBusinessValue) || !isEmpty(weightedPipelineValue)) {
        projectedBusiness[key] = (bookedBusinessValue || 0) + (weightedPipelineValue || 0);
      }
    });

    const salesGoal = { label: 'Sales Goal', _editable: true };
    salesGoals.forEach(({ quarter, year, goal }) => {
      if (year === reportDate.year()) {
        salesGoal[`quarter${quarter}`] = goal;
      }
    });
    const bookedBusinessVsGoal = { label: 'Booked Business vs Goal', _percent: true };
    const projectedBusinessVsGoal = { label: 'Projected Business vs Goal', _percent: true };

    const calcSalesGoals = () => {
      let quarterCount = 0;
      salesGoal.year = null;
      bookedBusinessVsGoal.year = null;
      projectedBusinessVsGoal.year = null;
      Object.keys(salesGoal).forEach((key) => {
        if (key.startsWith('quarter')) {
          const quarterGoal = parseInt(salesGoal[key], 10);
          if (quarterGoal) {
            salesGoal.year = (salesGoal.year || 0) + quarterGoal;
            if (bookedBusinessTotal[key]) {
              bookedBusinessVsGoal[key] = Math.round(((bookedBusinessTotal[key] - quarterGoal) / quarterGoal) * 100);
              bookedBusinessVsGoal.year = (bookedBusinessVsGoal.year || 0) + parseInt(bookedBusinessVsGoal[key], 10);
            }
            if (projectedBusiness[key]) {
              projectedBusinessVsGoal[key] = Math.round(((projectedBusiness[key] - quarterGoal) / quarterGoal) * 100);
              projectedBusinessVsGoal.year = (projectedBusinessVsGoal.year || 0) + parseInt(projectedBusinessVsGoal[key], 10);
            }
            quarterCount += 1;
          } else {
            bookedBusinessVsGoal[key] = null;
            projectedBusinessVsGoal[key] = null;
          }
        }
      });
      if (quarterCount) {
        bookedBusinessVsGoal.year = Math.round(((bookedBusinessTotal.year - salesGoal.year) / salesGoal.year) * 100);
        projectedBusinessVsGoal.year = Math.round(((projectedBusiness.year - salesGoal.year) / salesGoal.year) * 100);
      }
    };
    calcSalesGoals();

    setData([
      ...bookedBusiness,
      ...weightedPipeline,
      projectedBusiness,
      salesGoal,
      bookedBusinessVsGoal,
      projectedBusinessVsGoal,
    ]);

    const _schema = {
      label: {
        primaryKey: true,
        label: '',
        grid: {
          pinned: 'left',
          minWidth: 200,
          detail: () => ({
            key: 'client.id',
            ...Client,
          }),
          group: true,
          sortable: false,
          suppressMenu: true,
          cellClass: ({ data }) => data._group ? [ 'group', 'bold' ] : data._groupChild ? [ 'group-child', 'linked', data.client && !data.campaign && 'bold' ].filter(Boolean) : null,
          cellRenderer: ({ params: { value, data }, dataParent }) => {
            return data._groupChild ?
              <>
                <i className={`fa fa-${iconType.LINK.className}`} />
                <span>{data.client?.name}</span>
              </> : data._group ?
                <>
                  <i className={`fa fa-${(!data._groupCollapsed ? iconType.COLLAPSE : iconType.EXPAND).className} group-toggle`} />
                  <span>{value}</span>
                </> :
                value;
          },
        },
      },
      'client.name': {
        label: 'Company',
        exportData: true,
      },
      'campaign.title': {
        label: 'Campaign',
        grid: {
          pinned: 'left',
          minWidth: 175,
          sortable: false,
          // cellClassRules: { 'italic light': ({ data }) => data.campaign && !data.campaign?.id },
          detail: () => ({
            key: 'campaign.id',
            ...Campaign,
          }),
        },
      },
      ...[...Array(12)].map((_, m) => m + 1).reduce((acc, m) => {
        const month = moment(m.toString(), 'M');
        const key = `month${m}`;
        acc[key] = {
          label: month.format('MMM'),
          type: 'number',
          grid: {
            cellClassRules: { bold: ({ data }) => data._group },
            minWidth: 75,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => sortComparator({
              valueA,
              valueB,
              nodeA,
              nodeB,
              isDescending,
              key,
            }),
          },
        };
        if (parseInt(month.endOf('quarter').format('M'), 10) === m) {
          const quarter = month.quarter();
          const key = `quarter${quarter}`;
          acc[key] = {
            label: `Q${quarter}`,
            type: 'number',
            grid: {
              cellClass: 'bold',
              headerClass: 'bold',
              minWidth: 90,
              editable: ({ data }) => data._editable,
              valueSetter: ({ newValue, data }) => {
                if (isEmpty(newValue)) {
                  newValue = 0;
                }
                if (!isNaN(newValue)) { // eslint-disable-line
                  axios.post(SalesGoal.apiPath, {
                    quarter,
                    year: reportDate.year(),
                    goal: newValue,
                  });
                  data[key] = newValue;
                  calcSalesGoals();
                  return true;
                }
                return false;
              },
              cellRenderer: cellRendererQuarter,
              headerComponentParams: {
                template: `
                  <div class="ag-cell-label-container" role="presentation">
                    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                      <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                      <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                      <span class="group-toggle group-hidden" data-quarter="${quarter}"></span>                      
                      <span ref="eSortAsc" class="ag-header-icon ag-sort-icon ag-sort-ascending-icon"><span class="ag-icon ag-icon-asc"></span></span>
                      <span ref="eSortDesc" class="ag-header-icon ag-sort-icon ag-sort-descending-icon"><span class="ag-icon ag-icon-desc"></span></span>
                      <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                    </div>                    
                  </div>`,
              },
              comparator: (valueA, valueB, nodeA, nodeB, isDescending) => sortComparator({
                valueA,
                valueB,
                nodeA,
                nodeB,
                isDescending,
                key,
              }),
            },
          };
        }
        return acc;
      }, {}),
      year: {
        label: reportDate.year(),
        type: 'number',
        grid: {
          cellClass: 'bold',
          headerClass: 'bold',
          minWidth: 100,
          cellRenderer: cellRendererQuarter,
          comparator: (valueA, valueB, nodeA, nodeB, isDescending) => sortComparator({
            valueA,
            valueB,
            nodeA,
            nodeB,
            isDescending,
            key: 'year',
          }),
        },
      },
    };
    setSchema(_schema);

  }, [reportDate]);

  useEffect(() => {
    if (gridApi) {
      const months = [...Array(12)].map((_, m) => {
        m += 1;
        return {
          key: `month${m}`,
          quarter: moment(m.toString(), 'M').quarter(),
        };
      });
      const headerGroupToggleClick = (e) => {
        if (e.target.classList.contains('group-toggle')) {
          e.stopPropagation();
          const isVisible = e.target.classList.contains('group-hidden');
          gridApi.columnModel.columnApi.setColumnsVisible(
            months
              .filter(({ quarter }) => quarter === parseInt(e.target.dataset.quarter, 10))
              .map(({ key }) => key),
            isVisible,
          );
          gridApi.sizeColumnsToFit();
          e.target.classList[isVisible ? 'remove' : 'add']('group-hidden');
        }
      };
      gridApi.columnModel.columnApi.setColumnsVisible(months.map(({ key }) => key), false);
      const headerGroupTogglesInit = () => {
        const headerGroupToggles = document.querySelectorAll('.ag-header-cell .group-toggle');
        headerGroupToggles.forEach((toggle) => {
          toggle.addEventListener('click', headerGroupToggleClick, false);
        });
      };
      setTimeout(headerGroupTogglesInit);
      gridApi.addEventListener('virtualColumnsChanged', headerGroupTogglesInit);
    }
  }, [gridApi]);

  return schema && data ?
    <div className="index booked-business">
      <div className="filter">
        <div className="startDate">{moment(reportDate).startOf('year').format('M/D/YYYY')}<span>&ndash;</span></div>
        <Form
          schema={{
            date: {
              label: 'Date',
              required: true,
              type: 'date',
              form: {
                onChange: async ({ value, values, fields, setFieldValue }) => {
                  setData(null);
                  setReportDate(moment(value));
                },
              },
            },
          }}
          dataDefault={{ date: reportDate }}
          hideSubmit
        />
      </div>
      <Crud
        schema={schema}
        model={model}
        label={label}
        labelPluralize={false}
        routePath={routePath}
        readOnly
        staticData={data}
        clientSide
        getGridApi={setGridApi}
      />
    </div> :
    <Spinner />;
};

export default BookedBusiness;
