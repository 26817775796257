import React, { useState, useEffect, useRef } from 'react';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import classNames from 'classnames';
import Upload from 'rc-upload';
import isEmpty from 'utils/isEmpty';
import { matchRoute } from 'services/route';
import iconType from 'utils/iconType';
import Modal from './modal';
import Spinner from './spinner';
import 'styles/header.scss';

export default ({ label, icons, schema, detailData, refreshDetail, rowCount, exportCols }) => {
  const exportRef = useRef(null);
  const [ iconError, setIconError ] = useState(null);
  const [ exportData, setExportData ] = useState([]);
  const [ isExporting, setIsExporting ] = useState(false);
  const [ isUploading, setIsUploading ] = useState(false);
  const { pathname } = useLocation();
  if (label !== false) {
    label = label || matchRoute({ pathname })?.label;
  }

  return (
      <div className="header">
        {label && <div className="label">{label}</div>}
        <div className="right">
          {!isEmpty(rowCount) && <div className="count">{rowCount.toLocaleString()}</div>}
          {iconError && !iconError?.warn && <Alert variant="danger">{iconError.msg}</Alert>}
          {icons?.map(({ type, className, onClick, tooltip, exportData: _exportData, exportingMsg, upload, active, detail, readOnly, disabled }, i) => {
            type = typeof type === 'function' ? type({ detailData }) : type;
            tooltip = typeof tooltip === 'function' ? tooltip({ detailData }) : tooltip;
            readOnly = typeof readOnly === 'function' ? readOnly({ detailData }) : readOnly;
            const booleanKeys = Object.keys(schema || {}).filter((key) => schema[key]?.type === 'boolean');
            const exportEmptyKeys = Object.keys(schema || {}).filter((key) => schema[key]?.exportEmpty);
            const exportArrayKeys = Object.keys(schema || {}).filter((key) => schema[key]?.exportArrayKey);
            const icon =
              type === iconType.EXPORT && !onClick ?
                <>
                  <i
                    onClick={async () => {
                      if (typeof _exportData === 'function') {
                        setIsExporting(true);
                        setExportData(await _exportData());
                        setIsExporting(false);
                      } else {
                        setExportData(_exportData);
                      }
                      setTimeout(() => {
                        exportRef.current.link.click();
                      });
                    }}
                    className={`fa fa-${iconType.EXPORT.className} icon`}
                  />
                  <CSVLink
                    data={exportData?.map(((item) => {
                      const exportItem = booleanKeys ? {
                        ...item,
                        ...booleanKeys.reduce((acc, key) => ({
                          ...acc,
                          [key]: get(item, key) >= 1 || get(item, key) === true ? 'Yes' : 'No',
                        }), {}),
                      } : item;
                      return {
                        ...exportItem,
                        ...exportEmptyKeys.reduce((acc, key) => ({
                          ...acc,
                          [key]: undefined,
                        }), {}),
                        ...exportArrayKeys.reduce((acc, key) => ({
                          ...acc,
                          [key]: item[key].map((value) => value[schema[key].exportArrayKey]).join(', '),
                        }), {}),
                      };
                    }))}
                    filename={`${pluralize(label)}-${moment().format('YYYYMMDD')}.csv`}
                    headers={(exportCols || Object.keys(schema).filter((key) => (schema[key].grid && !schema[key].grid.hide) || schema[key].exportData || schema[key].exportEmpty)).map((key) => ({ key, label: schema[key].label }))}
                    className="hidden"
                    ref={exportRef}
                    target="_blank"
                  />
                </> :
              upload ?
                <Upload
                  // accept="image/*"
                  customRequest={({ file }) => {
                    setIsUploading(true);
                    const formData = new FormData();
                    formData.append('file', file);
                    Object.keys(upload.data).forEach((key) => {
                      formData.append(key, upload.data[key]);
                    });
                    upload.onSubmit(formData).then(() => {
                      setIsUploading(false);
                    })
                    .catch((err) => {
                      console.log(err);
                      setIsUploading(false);
                    });
                  }}
                >
                  {isUploading ? <Spinner /> : <i className={`fa fa-${iconType.ADD.className} icon`} />}
                </Upload> :
              <i
                className={classNames(`fa fa-${className || type?.className} fa-${type?.regular ? 'regular' : 'solid'} icon`, { active, readonly: readOnly, disabled })}
                onClick={async () => {
                  try {
                    if (type === iconType.EXPORT) {
                      setIsExporting(true);
                    }
                    await onClick({ detailData });
                    setIsExporting(false);
                  } catch (err) {
                    if (err?.response?.data?.msg) {
                      setIconError({
                        ...err.response.data,
                        type,
                      });
                    }
                  }
                }}
                key={i}
              />;
            return icon && (
              <React.Fragment key={i}>
                {type === iconType.EXPORT && isExporting &&
                  <>
                    {exportingMsg && <Alert variant="secondary" className="empty">{exportingMsg}</Alert>}
                    <Spinner />
                  </>}
                {tooltip ?
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{tooltip}</Tooltip>}
                    key={i}
                  >
                    {icon}
                  </OverlayTrigger> :
                  icon}
                {iconError && iconError.type === type && iconError.warn &&
                  <Modal
                    showing
                    onHide={() => setIconError(null)}
                    confirm
                    onSubmit={async () => {
                      try {
                        await onClick({ detailData, warnConfirm: iconError.msg });
                        refreshDetail();
                        setIconError(null);
                      } catch (err) {
                        if (err?.response?.data?.msg) {
                          setIconError({
                            ...err.response.data,
                            type,
                          });
                        }
                      }
                    }}
                    body={
                      <>
                        <div>{iconError.msg}</div>
                        <div>Are you sure you want to continue?</div>
                      </>
                    }
                  />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
  );
};
