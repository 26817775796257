export const model = 'googleDoc';
export const label = 'Google Docs';
export const type = {
  RATE: { label: 'Rate', exportLink: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  DECK: { label: 'Deck', exportLink: 'application/pdf' },
  ATTACHMENT: { label: 'Attachment' },
};
export const apiPath = (type) => `/googleDocs?type=${type.label.toLowerCase()}`;
export const schema = {
  id: {
    type: 'string',
    label: 'ID',
    required: true,
    primaryKey: true,
  },
  name: {
    label: 'Google Doc',
    grid: {
      minWidth: '400',
    },
  },
  'lastModifyingUser.displayName': {
    label: 'Modified By',
    grid: {},
  },
  modifiedTime: {
    label: 'Modified On',
    type: 'date',
    grid: {},
  },
};
