import React, { useState, useEffect } from 'react';
import axios from 'axios';
import pluralize from 'pluralize';
import { get, isEmpty as _isEmpty } from 'lodash';
import { useParams, Outlet, useNavigate, useLocation } from 'react-router-dom';
import Grid from 'components/grid';
import Header from 'components/header';
import Spinner from 'components/spinner';
import Modal from 'components/modal';
import 'styles/detail.scss';
import iconType from 'utils/iconType';
import { getPrimaryKey } from 'utils/schema';
import * as Route from 'services/route';

const Crud = (props) => {
  const {
    apiPath,
    apiParams,
    schema,
    label,
    labelPluralize = true,
    nested,
    dataParent,
    model,
    modelParent,
    schemaParent,
    gridHeight,
    routePath,
    onInit,
    readOnly,
    icons = [],
    gridIcons = [],
    exportData,
    showAdd = true,
    showExport = true,
    showDelete = false,
    gridShowing = true,
    checkSelect = false,
    checkSelectedField,
    onCheckSelected,
    onCellMouseOver,
    onCellMouseOut,
    showDetailRef,
    nestedDetail = null,
    onHideNestedDetailModal,
    onFilterChanged,
    getGridApi,
    getApiUrl,
    filterModel,
    emptyMsg,
    refreshData,
    rowClass,
    clientSide = false,
    staticData,
    exportCols,
  } = props;
  let {
    onRowClick,
  } = props;
  const primaryKey = getPrimaryKey(schema);

  const navigate = useNavigate();
  const [ rowData, setRowData ] = useState(null);
  const [ rowCount, setRowCount ] = useState(null);
  const [ rowStart, setRowStart ] = useState(null);
  const [ rowEnd, setRowEnd ] = useState(null);
  const [ gridSort, setGridSort ] = useState(null);
  const [ gridFilter, setGridFilter ] = useState(null);
  const [ apiUrl, setApiUrl ] = useState(null);
  const [ gridApi, setGridApi ] = useState(null);
  const [ detailShowing, setDetailShowing ] = useState(!!nestedDetail);
  const [ detailData, setDetailData ] = useState(nestedDetail?.data);
  const [ deleteData, setDeleteData ] = useState(null);
  const [ deleteModalShowing, setDeleteModalShowing ] = useState(false);
  const [ nestedDetailModal, setNestedDetailModal ] = useState(nestedDetail);
  const [ groupCollapsed, setGroupCollapsed ] = useState(null);

  const initGridCols = () => {
    const cols = [
      ...Object.keys(schema).map((key) => {
        const { grid, form, label: headerName, type, primaryKey, time, linkedIn } = schema[key];
        if (!grid || (grid.hide && !primaryKey)) {
          return false;
        }
        const {
          minWidth,
          hide,
          visible,
          valueGetter,
          valueSetter,
          sortable,
          comparator,
          suppressMenu,
          pinned,
          image,
          group,
          editable,
          headerClass,
          headerComponent,
          headerComponentParams,
          order,
        } = grid;
        const detail = grid.detail?.();
        const email = type === 'email';
        return {
          field: key,
          headerName,
          minWidth,
          ...primaryKey && key === 'ID' && {
            width: 60,
            minWidth: 60,
          },
          type,
          time,
          onCellClicked: detail || email || linkedIn || group ? async ({ data, node, event, api, column }) => {
            if (event.target.classList.contains('linked') && (group || event.target.classList.contains('fa'))) {
              if (detail) {
                await showNestedDetailModal({ data, detail }); // eslint-disable-line
              } else if (email || linkedIn) {
                event.preventDefault();
                window.open(email ? `mailto:${data[key]}` : data[key], '_blank');
              }
            } else if (group && event.target.classList.contains('group-toggle')) {
              data._groupCollapsed = !data._groupCollapsed;
              api.redrawRows({ rowNodes: [node] });
              api.forEachNode((node) => {
                if (data._group.some((groupChild) => node.data === groupChild)) {
                  node.data._groupChildCollapsed = data._groupCollapsed;
                  node.setRowHeight(data._groupCollapsed ? 0 : api.getSizesForCurrentTheme().rowHeight);
                }
              });
              api.onRowHeightChanged();
              setGroupCollapsed({ data, collapsed: data._groupCollapsed });
            }
          } : null,
          headerClass,
          headerComponent,
          headerComponentParams,
          cellClassRules: grid.cellClassRules || (editable ? { editable } : null),
          cellClass: grid.cellClass || (detail || email || linkedIn ? 'linked' : null),
          cellRenderer: grid.cellRenderer ? (params) => grid.cellRenderer({ params, dataParent }) : null,
          valueGetter: valueGetter || (form?.type === 'tag' ? ({ data }) => data?.[key]?.join(', ') : null),
          valueSetter,
          hide,
          visible,
          detail,
          email,
          image,
          sortable,
          comparator,
          suppressMenu,
          pinned,
          group,
          editable,
          order,
        };
      }),
      gridIcons?.length && {
        field: 'icons',
        headerName: '',
        suppressMenu: true,
        width: gridIcons.length * 40,
        minWidth: gridIcons.length * 40,
        cellClass: 'icons',
        cellRenderer: (params) => gridIcons.map(({ type, className, onClick, onDelete }, i) =>
          <i
            className={`fa fa-${className || type.className} icon`}
            onClick={() => {
              if (type === iconType.DELETE) {
                setDeleteData(params.data);
                setDeleteModalShowing(true);
              } else {
                onClick(params.data);
              }
            }}
            key={i}
          />),
      },
    ].filter(Boolean);

    return cols.some(({ order }) => !!order) ? _.orderBy(cols, ['order'], ['asc']) : cols;
  };

  const [ gridCols, setGridCols ] = useState(initGridCols());

  useEffect(() => {
    if (schema) {
      setGridCols(initGridCols());
    }
  }, [schema]);

  const [queryCols] = useState(Object.keys(schema).map((key) => {
    const { query, exportData: _exportData } = schema[key];
    return query || _exportData ? {
      field: key,
    } : false;
  }).filter(Boolean));

  const { pathname } = useLocation();
  const params = useParams();
  const isDetailPath = () =>
    (Route.isDetailPath({ params }) || Route.isAddPath({ pathname })) && !nested;

  const showDetail = ({ data = null, dataDefault = null, navigateForce = false, nested = props.nested, nestedModalForce = false, detail = props } = {}) => {
    if (nested || nestedModalForce) {
      setDetailData(data);
      setNestedDetailModal({
        ...detail,
        dataDefault,
      });
      return;
    }
    setDetailData(data);
    setDetailShowing(true);
    //navigate(`./${data ? data.id : 'add'}`);
    if (!isDetailPath() || navigateForce) {
      navigate(`${routePath ? `/${routePath()}` : '.'}/${data ? data[primaryKey] : 'add'}`);
    }
  };
  if (showDetailRef) {
    useEffect(() => {
      showDetailRef.current = showDetail;
    }, []);
  }

  const refreshDetail = async () => {
    const { data } = await axios.get(`${apiPath}/${detailData.id}`);
    setDetailData(null);
    setDetailData(data);
  };

  // onRowClick = onRowClick || (!readOnly ? useCallback(async ({ node, event }) => {
  //   if (event.target.classList.contains('fa')) {
  //     return;
  //   }
  //   const { data } = await axios.get(`${apiPath}/${node.data[primaryKey]}${apiParams ? `?${new URLSearchParams(apiParams).toString()}` : ''}`);
  //   showDetail({ data, nested, navigateForce: true });
  // }, [nested]) : null);

  onRowClick = onRowClick || (!readOnly ? async ({ node, event }) => {
    if (event.target.classList.contains('fa')) {
      return;
    }
    const { data } = await axios.get(`${apiPath}/${node.data[primaryKey]}${apiParams ? `?${new URLSearchParams(apiParams).toString()}` : ''}`);
    showDetail({ data, nested, navigateForce: true });
  } : null);

  const hideDetail = () => {
    setRowData(null);
    setDetailData(null);
    setDetailShowing(false);
    if (isDetailPath()) {
      navigate('.');
    }
  };

  const showNestedDetailModal = async ({ data, detail }) => {
    const primaryKey = get(data, detail.key);
    if (primaryKey) {
      setDetailData((await axios.get(`${detail.apiPath}/${primaryKey}`)).data);
      setNestedDetailModal(detail);
    }
  };

  const hideNestedDetailModal = (params) => {
    // setRowData(null);
    if (gridApi) {
      if (params?.isAdd) {
        setRowData(null);
        // gridApi.refreshInfiniteCache();
      } else if (params?.isDelete) {
        setRowData(null);
        // gridApi.refreshInfiniteCache();
      } else if (detailData && (!nestedDetailModal || nestedDetailModal.model === model)) {
        setTimeout(() => {
          gridApi.forEachNode(async (node) => {
            if (detailData[primaryKey] === node.data[primaryKey]) {
              const { data } = await axios.get(`${apiPath}/${node.data[primaryKey]}${apiParams ? `?${new URLSearchParams(apiParams).toString()}` : ''}`);
              node.setData(data);
            }
          });
        }, 1000);
      }
    }

    setNestedDetailModal(null);
    if (schemaParent && dataParent) {
      Object.keys(schemaParent).map(async (key) => {
        const { form } = schemaParent[key];
        if (form?.model?.() === model && form?.initItems) {
          await form.initItems(dataParent);
          const schemaParentPrimaryKey = getPrimaryKey(schemaParent);
          const changedItem = form.items.find((item) => item.value === detailData?.[schemaParentPrimaryKey]);
          form?.setFieldValue(key, changedItem?.value);
        }
      });
    }
    onHideNestedDetailModal?.(params);
  };

  const showDeleteModal = () => {
    setDeleteModalShowing(true);
  };
  const hideDeleteModal = () => {
    setDeleteModalShowing(false);
    if (deleteData) {
      setDeleteData(null);
    }
  };
  const onDelete = async () => {
    const deleteGridIcon = gridIcons?.find(({ type }) => type === iconType.DELETE);
    if (deleteData && deleteGridIcon) {
      await deleteGridIcon.onDelete(deleteData);
      setDeleteData(null);
    } else {
      await axios.delete(`${apiPath}/${detailData[primaryKey]}`);
    }
    hideDeleteModal();
    if (dataParent || nestedDetailModal) {
      hideNestedDetailModal({ isDelete: true });
    } else {
      hideDetail();
    }
  };

  const onSubmit = async (values) => {
    const { data } = await axios.post(`${apiPath}${apiParams ? `?${new URLSearchParams(apiParams).toString()}` : ''}`, values);
    if (dataParent || nestedDetailModal) {
      hideNestedDetailModal({ isAdd: true, data });
    } else {
      showDetail({ data, navigateForce: true });
    }
  };

  useEffect(async () => {
    if (detailShowing && !isDetailPath()) {
      hideDetail();
    } else if (!detailShowing && isDetailPath()) {
      if (params.id) {
        if (!nested) {
          const { data } = await axios.get(`${apiPath}/${params.id}`);
          showDetail({ data });
        }
      } else {
        showDetail();
      }
    }
  }, [pathname]);

  const apiLimit = 100;

  const getRowData = async ({ start = null, end = null, sort = null, filter = null, exportData: _exportData = false } = {}) => {
    if (staticData) {
      const data = { items: staticData.items || staticData };
      if (!_exportData) { // eslint-disable-line
        setRowData(data);
      }
      return data;
    }
    if (_exportData) {
      return (await axios.get(apiUrl)).data;
    }

    const _apiUrl = `${apiPath}${apiPath.includes('?') ? '&' : '?'}\
fields=${primaryKey ? `${primaryKey},` : ''}${gridCols.filter(({ field }) => field !== 'icons').map(({ field }) => field).join(',')}${queryCols.length ? `,${queryCols.map(({ field }) => field).join(',')}` : ''}\
${apiParams ? `&${new URLSearchParams(apiParams).toString()}` : ''}\
${sort?.length ? `&order=${sort[0].colId},${sort[0].sort}` : ''}\
${!_isEmpty(filter) ? `&filter=${encodeURIComponent(JSON.stringify(filter))}` : ''}`;
    setApiUrl(_apiUrl);
    getApiUrl?.(_apiUrl);

    let data = null;
    if (start === null && !clientSide) {
      setRowData(null);
    } else {
      // TODO: check pending axios patch requests on modal close and wait before refreshing data (https://stackoverflow.com/questions/44670782/know-if-there-are-pending-request-in-axios)
      ({ data } = (await axios.get(`${_apiUrl}&limit=${apiLimit}&start=${start}&end=${end}`)));

      if (!start) {
        setRowData(data);
      } else {
        setRowData((rowData) => ({
          ...rowData,
          items: [
            ...rowData.items,
            ...data.items,
          ],
        }));
      }
    }
    setRowStart(start);
    setRowEnd(end);
    setGridSort(sort);
    setGridFilter(filter);
    return data;
  };

  useEffect(async () => {
    if (!rowData) {
      await getRowData();
    } else {
      onInit?.({ rowData });
    }
  }, [rowData]);

  useEffect(async () => {
    if (apiParams) {
      setRowData(null);
      await getRowData();
    }
  }, [JSON.stringify(apiParams)]);

  if (refreshData) {
    useEffect(() => {
      refreshData(() =>
        () => {
          setRowData(null);
        });
    }, []);
  }

  const stepDetail = async (direction) => {
    // first check cache data to avoid unnecessary endpoint call
    const cacheData = rowData.items;
    const maxIndex = cacheData.length - 1;
    let stepData = null;
    let stepIndex = cacheData.findIndex((item) => item[primaryKey] === detailData[primaryKey]);
    if (stepIndex !== -1) {
      if (direction === 'back') {
        stepIndex -= 1;
      } else {
        stepIndex += 1;
      }
      if (stepIndex >= 0 && stepIndex <= maxIndex) {
        stepData = cacheData[stepIndex];
      } else if (cacheData.length < apiLimit) {
        if (stepIndex < 0) {
          stepData = cacheData[maxIndex];
        } else if (stepIndex > maxIndex) {
          stepData = cacheData[0];
        }
      }
    }

    // record not found in cache data, get next block from server
    if (!stepData) {
      const data = await getRowData({ start: rowStart + apiLimit, end: rowEnd + apiLimit, sort: gridSort, filter: gridFilter });
      stepData = data.items[0];
    }
    if (stepData) {
      const { data } = await axios.get(`${apiPath}/${stepData[primaryKey]}${apiParams ? `?${new URLSearchParams(apiParams).toString()}` : ''}`);
      setDetailData(null);
      showDetail({ data, nested, navigateForce: true });
      //setDetailStepIndex(stepIndex);
    }
  };

  const renderDetail = () => {
    const label = nestedDetailModal?.label || props.label;
    const Component = nestedDetailModal && Route.matchRoute({ pathname: (nestedDetailModal?.routePath || props.routePath)() }).DetailComponent;

    return (
      <>
        <Header
          label={detailData ? label : `New ${label}`}
          detailData={detailData}
          refreshDetail={refreshDetail}
          icons={[
            ...(detailData && rowData?.items.length > 1 && rowStart != null && !props.nestedDetail && (!nestedDetailModal || nestedDetailModal.model === model) ? [{
              type: iconType.BACK,
              onClick: () => stepDetail('back'),
              tooltip: `Previous ${label}`,
              disabled: detailData && rowCount > apiLimit && rowData?.items.findIndex((item) => item[primaryKey] === detailData[primaryKey]) === 0,
            },
            {
              type: iconType.FORWARD,
              onClick: () => stepDetail('forward'),
              tooltip: `Next ${label}`,
            }] : []),
            ...(nestedDetailModal ? nestedDetailModal.icons || [] : icons).filter((icon) => detailData && icon.detail),
            (typeof showDelete === 'function' ? showDelete(detailData) : showDelete) && !icons.some((icon) => icon.type === iconType.DELETE) && detailData && {
              type: iconType.DELETE,
              onClick: showDeleteModal,
              tooltip: `Delete ${label}`,
            },
          ].filter(Boolean)}
        />
        {nestedDetailModal ?
          <Component
            id={detailData?.[getPrimaryKey(nestedDetailModal.schema) || primaryKey]}
            apiPath={nestedDetailModal.apiPath}
            schema={nestedDetailModal.schema}
            data={detailData}
            dataParent={dataParent}
            dataDefault={nestedDetailModal.dataDefault}
            modelParent={modelParent}
            onSubmit={onSubmit}
            nested={!!nestedDetailModal}
            hideNestedDetailModal={hideNestedDetailModal}
            tabActive={nestedDetailModal.tabActive}
          /> :
          <Outlet context={[ params.id, apiPath, schema, detailData, onSubmit, showNestedDetailModal, hideNestedDetailModal, hideDetail ]} />}
      </>
    );
  };

  return (
    <>
      {
        !detailShowing ?
          (
            isDetailPath() ?
              <Spinner /> :
              <>
                <Header
                  schema={schema}
                  icons={[
                    !icons.some((icon) => icon.type === iconType.EXPORT) && showExport && {
                      type: iconType.EXPORT,
                      exportData: exportData ? exportData(rowData?.items) : async () => (await getRowData({ exportData: true })).items,
                      tooltip: `Export ${pluralize(label)}`,
                    },
                    !icons.some((icon) => icon.type === iconType.ADD) && rowData && !readOnly && showAdd && {
                      type: iconType.ADD,
                      onClick: () => showDetail(),
                      tooltip: `New ${label}`,
                    },
                    ...icons.filter((icon) => !icon.detail),
                  ].filter(Boolean)}
                  label={labelPluralize ? pluralize(label) : label}
                  rowCount={rowCount}
                  exportCols={exportCols}
                />
                {gridShowing &&
                  <Grid
                    cols={gridCols}
                    rowData={rowData}
                    getRowData={getRowData}
                    setRowCount={setRowCount}
                    apiLimit={apiLimit}
                    apiParams={apiParams}
                    onRowClick={onRowClick}
                    nested={nested}
                    dataParent={dataParent}
                    model={model}
                    modelParent={modelParent}
                    schemaParent={schemaParent}
                    height={gridHeight}
                    label={label}
                    labelPluralize={labelPluralize}
                    readOnly={readOnly}
                    checkSelect={checkSelect}
                    checkSelectedField={checkSelectedField}
                    onCheckSelected={onCheckSelected}
                    onCellMouseOver={onCellMouseOver}
                    onCellMouseOut={onCellMouseOut}
                    onFilterChanged={onFilterChanged}
                    getApi={(api) => { setGridApi(api); getGridApi?.(api); }}
                    filterModel={filterModel}
                    clientSide={clientSide}
                    emptyMsg={emptyMsg}
                    rowClass={rowClass}
                    groupCollapsed={groupCollapsed}
                  />}
                <Modal
                  showing={!!nestedDetailModal}
                  onHide={hideNestedDetailModal}
                  containerWidth
                  body={renderDetail()}
                />
              </>
          ) :
          renderDetail()
      }
      <Modal
        showing={deleteModalShowing}
        onHide={hideDeleteModal}
        confirm
        onSubmit={onDelete}
        body="Are you sure you want to delete this?"
      />
    </>
  );
};

export default Crud;
