export const model = 'googlePlace';
export const label = 'Google Places';
export const apiPath = '/googlePlaces';
// export const apiPath = ({ latRange, lngRange, radius }) => `/googlePlaces?latRange=${latRange}&latRange=${latRange}&radius=${radius}`;
export const schema = {
  id: {
    type: 'string',
    label: 'ID',
    required: true,
    primaryKey: true,
  },
  name: {
    label: 'Google Place',
    grid: {
      minWidth: '400',
    },
  },
};
