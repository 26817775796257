export const model = 'salesGoal';
export const label = 'Sales Goal';
export const apiPath = '/salesGoals';
export const schema = {
  id: {
    type: 'string',
    label: 'ID',
    required: true,
    primaryKey: true,
  },
  goal: {
    label: 'Goal',
    grid: {},
  },
  date: {
    label: 'Date',
    type: 'date',
    grid: {},
  },
};
