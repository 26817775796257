import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Crud from 'components/crud';
import Pipeline from 'components/pipeline';
import iconType from 'utils/iconType';
import { apiPath, schema, model, label, routePath, stage } from './config';

const Contact = () => {
  const [ isPipelineShowing, setIsPipelineShowing ] = useState(false);
  const [ apiUrl, setApiUrl ] = useState(null);

  return (
    <div className={classNames('index contact', { 'pipeline-showing': isPipelineShowing })}>
      <Crud
        apiPath={apiPath}
        schema={schema}
        model={model}
        label={label}
        // showDelete={(data) => data?.fk_createdById === getUserId()}
        showDelete
        routePath={routePath}
        icons={[
          {
            type: iconType.PIPELINE,
            onClick: () => setIsPipelineShowing(!isPipelineShowing),
            active: isPipelineShowing,
            tooltip: 'Toggle Pipeline',
          },
        ]}
        getApiUrl={setApiUrl}
      />
      {isPipelineShowing && <Pipeline
        stage={stage}
        stageField="stage"
        apiPath={apiPath}
        apiUrl={apiUrl}
        filterMatch={({ item, query }) => item.nameFull?.toLowerCase().includes(query) || item.client?.name?.toLowerCase().includes(query)}
        renderCard={({ nameFull, status, client, dateLastActivity }) => ({
          title: <div className="title">{nameFull}</div>,
          description:
            <div className="description">
              {client?.name && <div>{client.name}</div>}
              <div>{moment(dateLastActivity).format('M/D/YYYY')}</div>
              <div>{status}</div>
            </div>,
        })}
      />}
    </div>
  );
};

export default Contact;
