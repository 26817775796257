import axios from 'axios';
import moment from 'moment/moment';
import { saveAs } from 'file-saver';
import { base64ToArrayBuffer } from 'utils/encoding';

export const apiPath = '/venueAgreements';
export const exportAgreement = async ({ venueId, campaignId }) => {
  const { data: { respondentEmail, answers, agreement } } = await axios.get(`${apiPath}/?venueId=${venueId}&campaignId=${campaignId}&export=true`);
  saveAs(
    new Blob([base64ToArrayBuffer(agreement)], { type: 'application/pdf;charset=utf-8' }),
    `${answers.venueName} - Agreement - ${moment(answers.wndwSignatureDate).format('M-D-YYYY')}.pdf`,
  );
};

