export const model = 'campaignVenueSearch';
export const apiPath = '/campaignVenueSearches';
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  fk_campaignId: {
    label: 'Campaign',
    required: true,
  },
};
