import React, { useState } from 'react';
import axios from 'axios';
import Crud from 'components/crud';
import iconType from 'utils/iconType';
import * as Attachment from 'views/attachment/config';

export default ({ dataParent, modelParent, schemaParent } = {}) => {
  const [ refreshAttachments, setRefreshAttachments ] = useState(null);

  const idField = `${modelParent}Id`;
  const selectedField = `${modelParent}Attachment`;

  return (
    <Crud
      apiPath={`${Attachment.apiPath}?${idField}=${dataParent.id}`}
      schema={Attachment.schema}
      schemaParent={schemaParent}
      model={Attachment.model}
      modelParent={modelParent}
      label={Attachment.label}
      nested
      dataParent={dataParent}
      clientSide
      onRowClick={({ data, event }) => {
        if (!event.target.classList.contains('icon')) {
          window.open(data.webViewLink);
        }
      }}
      showAdd={false}
      exportData={(rowData) => rowData?.filter((item) => item[selectedField])}
      icons={[
        {
          upload: {
            data: { [idField]: dataParent.id },
            onSubmit: async (submitData) => {
              await axios.post(Attachment.apiPath, submitData);
              setTimeout(() => refreshAttachments(), 500);
            },
          },
          tooltip: 'Add Attachment',
        },
      ]}
      gridIcons={[
        {
          type: iconType.DELETE,
          onDelete: (data) => axios.delete(`${Attachment.apiPath}/${data.id}`),
        },
      ]}
      refreshData={setRefreshAttachments}
    />
  );
};
