import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { groupBy } from 'lodash';
import { matchRoute } from 'services/route';
import { getUserRole } from 'services/auth';
import splitAt from 'utils/splitAt';
import 'styles/list.scss';

export default ({ route, items } = {}) => {
  if (route) {
    const { pathname } = useLocation();
    const _route = matchRoute({ pathname });
    if (_route) {
      items = _route.children.filter(({ nested, roles }) => !nested && (!roles || roles.includes(getUserRole())));
    }
  }
  const navigate = useNavigate();
  return items && splitAt(items, items.findIndex(({ divider }) => divider), true).map((items) => (
    <ListGroup>
      {items.map(({ label, path }) =>
        <ListGroup.Item action onClick={() => navigate(`/${path}`)}>{label}</ListGroup.Item>)}
    </ListGroup>
  ));
};
