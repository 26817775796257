import React, { useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import Form from 'components/form';
import 'styles/detail.scss';
import * as CampaignItem from './config';

const CampaignItemDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit }) => {
  if (!dataParent) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={schema}
        data={data}
        dataDefault={!data ? { fk_campaignId: dataParent.id, dateStart: dataParent.dateStart } : null}
        model={CampaignItem.model}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default CampaignItemDetail;
