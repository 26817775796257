import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation, Pagination } from 'swiper';
import { alterSchema } from 'utils/schema';
import Spinner from 'components/spinner';
import Form from 'components/form';
import Header from 'components/header';
import iconType from 'utils/iconType';
import * as Campaign from 'views/campaign/config';
import * as Venue from 'views/venue/config';
import { apiPath, schema } from './config';
import 'styles/photo.scss';

const Photo = ({ dataParent, modelParent }) => {
  const [ photos, setPhotos ] = useState([]);
  const [ photoSelected, setPhotoSelected ] = useState({});
  const [ photoSchema, _ ] = useState(alterSchema(schema, {
    formFields:
      modelParent === Campaign.model ? [ 'venue.name', 'venueType', 'venueMarket', 'venueNeighborhood', 'routeSheet.rep.fullName', 'date', 'time', 'itemType', 'flagApproved', 'photo' ] :
      modelParent === Venue.model ? [ 'routeSheet.rep.fullName', 'date', 'time', 'itemType', 'flagApproved', 'photo' ] :
      [],
  }));

  const selectPhoto = async (id) => {
    const { data: photo } = await axios.get(`${apiPath}/${id}`);
    setPhotoSelected({
      ...photo,
      venueType: photo.venue?.type?.type,
      venueMarket: photo.venue?.market?.name,
      venueNeighborhood: photo.venue?.neighborhood?.name,
    });
  };

  useEffect(async () => {
    if (!photos.length) {
      const { data: { items } } = await axios.get(
        `${apiPath}?${
          modelParent === Campaign.model ? 'campaignId' :
          modelParent === Venue.model ? 'venueId' : ''
        }=${dataParent.id}`,
      );
      if (items.length) {
        setPhotos(items);
        await selectPhoto(items[0].id);
      }
    }
  }, []);

  // const LazyImg = ({ id, removePlaceholder }) => {
  //   const [ src, setSrc ] = useState(null);
  //   useEffect(async () => {
  //     if (!src) {
  //       // setSrc(`data:image/jpg;base64,${(await axios.get(`${Photo.apiPath}/${id}/?photoData=true`)).data}`);
  //     }
  //   }, []);
  //   return (
  //     <img
  //       src={src}
  //       onLoad={removePlaceholder}
  //       onError={removePlaceholder}
  //     />
  //   );
  // };

  // const LazyPhoto = ({ photo }) => {
  //   const refPlaceholder = React.useRef();
  //   const removePlaceholder = () => {
  //     refPlaceholder.current.remove();
  //   };
  //
  //   return (
  //     <>
  //      <div
  //        ref={refPlaceholder}
  //        className="loading"
  //      >
  //        <FontAwesomeIcon icon={faImage} />
  //        <FontAwesomeIcon icon={faSquare} />
  //      </div>
  //      <LazyLoad>
  //        <LazyImg
  //          id={photo.id}
  //          removePlaceholder={removePlaceholder}
  //        />
  //      </LazyLoad>
  //     </>
  //   );
  // };

  return (
    !photos.length ? <div className="alert empty">No photos</div> :
      // <Row xs={1} md={2}>
      //   {photos.map((photo, i) => (
      //     <Col>
      //       <div className="tile">
      //         <LazyPhoto photo={photo} />
      //         <div className="caption">
      //           {photo.venue?.name}
      //         </div>
      //       </div>
      //     </Col>
      //   ))}
      // </Row>
      <>
        {modelParent === Campaign.model && <Header
          label={false}
          icons={[
            {
              type: iconType.EXPORT,
              onClick: async () => {
                // const { data: url } = await axios.get(`${apiPath}/?campaignId=${dataParent.id}&download=true`, { responseType: 'blob' });
                const { data: url } = await axios.get(`${apiPath}/?campaignId=${dataParent.id}&download=true`);
                window.location.href = url;
              },
              tooltip: 'Export Photos',
            },
          ]}
        />}
        <Swiper
          onSlideChange={({ realIndex: index }) => {
            selectPhoto(photos[index].id);
          }}
          navigation
          pagination={{ type: 'fraction' }}
          modules={[ Navigation, Pagination, Mousewheel ]}
          mousewheel={false}
          noSwiping={!isMobile}
          loop
          onTouchEnd={(swiper, e) => {
            swiper.allowClick = false;
          }}
        >
          {photos.map((photo, i) => (
            <SwiperSlide key={i}>
              {photoSelected?.id !== photo.id ? (
                <Spinner />
                /* <div className="loading">
                  <FontAwesomeIcon icon={faImage} />
                  <FontAwesomeIcon icon={faSquare} />
                </div> */
              ) : (
                <Form
                  apiPath={apiPath}
                  schema={photoSchema}
                  schemaParent={schema}
                  data={photoSelected}
                  dataParent={dataParent}
                  modelParent={modelParent}
                />)}
            </SwiperSlide>))}
        </Swiper>
      </>
  );
};

export default Photo;
