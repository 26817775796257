import React, { useState, useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import pluralize from 'pluralize';
import Form from 'components/form';
import Tabs from 'components/tabs';
import Crud from 'components/crud';
import { alterSchema } from 'utils/schema';
import { isBA as _isBA } from 'services/auth';
import * as Campaign from 'views/campaign/config';
import * as CampaignPlanVenue from 'views/campaignPlanVenue/config';
import * as Contact from 'views/contact/config';
import * as Activity from 'views/activity/config';
import * as Route from 'views/route/config';
import * as RouteSheet from 'views/routeSheet/config';
import Photo from 'views/photo';
import Attachment from 'views/attachment/index';
import { model } from './config';
import 'styles/detail.scss';

const VenueDetail = ({ id, apiPath, schema, data, dataParent, dataDefault, modelParent, onSubmit, nested, hideNestedDetailModal }) => {

  if (!nested) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});
  const isBA = _isBA();

  /* const schemaVenueContact = (type) => {
    const fields = cloneDeep(pick(schema, [
      ...[ `id_${type}Contact`, 'id' ],
      ...[ 'email', 'phone', 'streetNumber', 'streetName', 'city', 'state', 'zip' ],
    ]));

    return Object.keys(fields).reduce((acc, key, i) => ({
      ...acc,
      [key]: {
        ...fields[key],
        form: {
          ...fields[key].form,
          hide: !!fields[key].primaryKey,
          ...i === 0 && {
            onChange: async ({ item, setFieldValue }) => {
              if (item) {
                const { data } = await axios.get(`${Contact.apiPath}/${item.value}`);
                ['phone'].forEach((key) => setFieldValue(key, data[key]));
              }
            },
          },
        },
      },
    }), {});
  }; */

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={
          modelParent === Campaign.model ? alterSchema(schema, { formFieldsHide: ['id_primaryContact'], formFieldsColBreak: ['streetNumber'] }) :
          isBA ? alterSchema(schema, {
            gridDetail: {
              fk_routeId: null,
            },
          }) :
          schema
        }
        schemaParent={schema}
        data={data}
        dataParent={dataParent}
        dataDefault={!data && {
          ...dataDefault,
          ...modelParent === Route.model ? {
            fk_routeId: dataParent.id,
            fk_marketId: dataParent.fk_marketId,
            fk_neighborhoodId: dataParent.fk_neighborhoodId,
          } : modelParent === RouteSheet.model ? {
            fk_routeSheetId: dataParent.id,
            fk_routeId: dataParent.fk_routeId,
            fk_marketId: dataParent?.route?.market?.id,
            fk_neighborhoodId: dataParent?.route?.neighborhood?.id,
          } : null,
        }}
        modelParent={modelParent}
        onSubmit={onSubmit}
        submitLabel={modelParent === Campaign.model && typeof data?.campaignPlanVenue !== 'undefined' && 'Add & Select'}
        readOnly={isBA && data}
      />
      {modelParent === Campaign.model && typeof data?.campaignPlanVenue !== 'undefined' ?
      data &&
      <div className="submit">
        <Button
          variant="secondary"
          type="submit"
          onClick={async () => {
            await (
              data.campaignPlanVenue ?
                axios.delete(CampaignPlanVenue.apiPath, { data: { campaignId: dataParent.id, venueId: data.id } }) :
                axios.post(CampaignPlanVenue.apiPath, { campaignId: dataParent.id, venueId: data.id })
            );
            hideNestedDetailModal({ campaignPlanVenue: !data.campaignPlanVenue });
          }}
        >
          {`${data.campaignPlanVenue ? 'Deselect' : 'Select'}`}
        </Button>
      </div> :
      data && <Tabs
        tabs={[
          {
            key: Contact.model,
            title: pluralize(Contact.label),
            content:
              <div>
                <Crud
                  apiPath={Contact.apiPath}
                  apiParams={{ venueId: id }}
                  schema={alterSchema(Contact.schema, { gridCols: [ 'id', 'nameFull', 'title', 'email', 'phone' ] })}
                  schemaParent={schema}
                  routePath={Contact.routePath}
                  label={Contact.label}
                  nested
                  dataParent={data}
                  model={Contact.model}
                  modelParent={model}
                  gridHeight={200}
                  readOnly={isBA}
                  onInit={() => {
                    forceUpdate();
                  }}
                />
                {/* <Row>
                <Col>
                  <Form
                    apiPath={apiPath}
                    schema={schemaVenueContact('primary')}
                    data={data}
                  />
                </Col>
              </Row> */}
              </div>,
          },
          !isBA && {
            key: 'routeSheet',
            title: 'Route Sheets',
            content:
              <Crud
                apiPath={RouteSheet.apiPath}
                apiParams={{ venueId: id }}
                schema={alterSchema(RouteSheet.schema, { gridCols: [ 'id', 'date', 'distributionVenue.job.campaign.title', 'distributionVenue.item.name', 'rep.fullName', 'distributionVenue.flagPay' ] })}
                schemaParent={schema}
                label={RouteSheet.label}
                nested
                dataParent={data}
                model={RouteSheet.model}
                modelParent={model}
                routePath={RouteSheet.routePath}
                showAdd={false}
                showDelete
                icons={RouteSheet.icons}
              />,
          },
          // {
          //   key: 'post',
          //   title: 'Posts',
          //   content: null,
          // },
          // {
          //   key: 'promoPoint',
          //   title: 'Promo Points',
          //   content: null,
          // },
          !isBA && {
            key: 'photo',
            title: 'Photos',
            content:
              <Photo
                dataParent={data}
                modelParent={model}
              />,
          },
          // {
          //   key: 'document',
          //   title: 'Documents',
          //   content: null,
          // },
          !isBA && {
            key: 'activity',
            title: 'Activities',
            content:
              <Crud
                apiPath={Activity.apiPath}
                apiParams={{ venueId: id }}
                schema={alterSchema(Activity.schema, { gridColsOrder: Activity.nestedGridCols })}
                schemaParent={schema}
                routePath={Activity.routePath}
                label={Activity.label}
                nested
                dataParent={data}
                model={Activity.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          !isBA && {
            key: 'attachment',
            title: 'Attachments',
            content:
              <Attachment
                schemaParent={schema}
                modelParent={model}
                dataParent={data}
              />,
          },
        ].filter(Boolean)}
      />}
      {/* <Row>
        <Col>

        </Col>
        <Col>
          <Form
            apiPath={apiPath}
            schema={schemaVenueContact('primary')}
            data={data}
          />
        </Col>
      </Row> */}

      {}
    </div>
  );
};

export default VenueDetail;
