import React from 'react';
import { connect } from 'react-redux';
import { Actions } from 'redux/auth';
import Form from 'components/form';
import 'styles/views/login.scss';
import { schema } from './config';

const Login = ({ login }) =>
  <div className="view login">
    <Form
      schema={schema}
      submitLabel="Login"
      onSubmit={login}
      size="md"
    />
  </div>;

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  login: ({ email, password }) => dispatch(Actions.login({ email, password })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
