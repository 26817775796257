import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { Alert, Button, Col, Dropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { isMobile } from 'react-device-detect';
import { isEmpty, truncate } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import Crud from 'components/crud';
import Modal from 'components/modal';
import Form from 'components/form';
import Spinner from 'components/spinner';
import iconType from 'utils/iconType';
import { alterSchema, getPrimaryKey } from 'utils/schema';
import * as Route from 'views/route/config';
import * as RouteSheet from 'views/routeSheet/config';
import * as Campaign from 'views/campaign/config';
import * as CampaignMarket from 'views/campaignMarket/config';
import * as CampaignPlanVenue from 'views/campaignPlanVenue/config';
import * as CampaignVenueSearch from 'views/campaignVenueSearch/config';
import * as Job from 'views/job/config';
import * as Market from 'views/market/config';
import * as Neighborhood from 'views/neighborhood/config';
import * as VenueType from 'views/venueType/config';
import * as GooglePlace from 'views/googlePlace/config';
import * as GooglePlaceExclude from 'views/googlePlaceExclude/config';
import * as Photo from 'views/photo/config';
import * as Client from 'views/client/config';
import { apiPath, schema, model, label, routePath, source as venueSource, status as venueStatus } from './config';
import 'styles/views/venue.scss';

const Venue = (props) => {
  const { dataParent, modelParent, onRowClick, onInit, gridHeight, rowClass, apiParams, showAdd = true, showMap = true, showExport = true } = props;

  const [ isMapShowing, setIsMapShowing ] = useState(false);
  const [ isMapStreetShowing, setIsMapStreetShowing ] = useState(false);
  const [ isMapSearchShowing, setIsMapSearchShowing ] = useState(false);
  const isMapSearchShowingRef = useRef(null); isMapSearchShowingRef.current = isMapSearchShowing;
  const [ map, setMap ] = useState(null);
  const mapRef = useRef(null); mapRef.current = map;
  const [ mapStreet, setMapStreet ] = useState(null);
  const [ mapHeight, setMapHeight ] = useState(null);
  const [ mapVenues, setMapVenues ] = useState(null);
  const [ mapMarkers, setMapMarkers ] = useState(null);
  const [ mapCircle, setMapCircle ] = useState(null);
  const mapCircleRef = useRef(null); mapCircleRef.current = mapCircle;
  const [ mapRadius, setMapRadius ] = useState(null);
  const mapRadiusRef = useRef(null); mapRadiusRef.current = mapRadius;
  const [ mapSource, setMapSource ] = useState(venueSource.WNDW);
  const [ mapFormState, setMapFormState ] = useState(null);
  const mapFormStateRef = useRef(null); mapFormStateRef.current = mapFormState;
  const [ mapPlaces, setMapPlaces ] = useState(null);
  const mapPlacesRef = useRef(null); mapPlacesRef.current = mapPlaces;
  const [ mapPlacePreview, setMapPlacePreview ] = useState(null);
  const [ mapPlaceExcludes, setMapPlaceExcludes ] = useState([]);
  const [ mapPlacesAddSelected, setMapPlacesAddSelected ] = useState([]);
  const [ mapPlacesAddSelectedDetailShowing, setMapPlacesAddSelectedDetailShowing ] = useState(false);
  const [ isMapSearching, setIsMapSearching ] = useState(false);
  const isMapSearchingRef = useRef(false); isMapSearchingRef.current = isMapSearching;
  const [ mapSearchGrid, setMapSearchGrid ] = useState(null);
  const [ mapSearchGridStep, setMapSearchGridStep ] = useState(null);
  const [ panorama, setPanorama ] = useState(null);
  const [ isPanoramaSelectShowing, setIsPanoramaSelectShowing ] = useState(false);
  const [ infoWindow, setInfoWindow ] = useState(null);
  const [ infoWindowPlace, setInfoWindowPlace ] = useState(null);
  const [ markets, setMarkets ] = useState([]);
  const [ allMarkets, setAllMarkets ] = useState([]);
  const [ neighborhoods, setNeighborhoods ] = useState([]);
  const [ allNeighborhoods, setAllNeighborhoods ] = useState([]);
  const [ mapMarket, setMapMarket ] = useState(null);
  const [ mapNeighborhood, setMapNeighborhood ] = useState(null);
  const [ mapCenter, setMapCenter ] = useState(null);
  const [ mapPlace, _setMapPlace ] = useState(null);
  const [ mapAutocomplete, setMapAutocomplete ] = useState(null);
  const [ venueTypes, setVenueTypes ] = useState([]);
  const [ filterModel, setFilterModel ] = useState(null);
  const [ gridApi, setGridApi ] = useState(null);
  const showMapDetailRef = useRef(null);
  const [ refreshVenues, setRefreshVenues ] = useState(null);
  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});

  const _apiParams =
    modelParent === Campaign.model ? { campaignId: dataParent.id, ...mapMarket?.id && isMapShowing && { marketId: mapMarket.id }, ...mapNeighborhood?.id && { neighborhoodId: mapNeighborhood.id }, ...apiParams } :
    modelParent === Route.model ? { routeId: dataParent.id } :
    modelParent === RouteSheet.model ? { routeSheetId: dataParent.id, route: true } : null;

  const onVenueCheckSelected = async (node) => {
    if (!!node.data.campaignPlanVenue !== node.selected) {
      node.data.campaignPlanVenue = node.selected;
      await (
        !node.selected ?
          axios.delete(CampaignPlanVenue.apiPath, { data: { campaignId: dataParent.id, venueId: node.data.id } }) :
          axios.post(CampaignPlanVenue.apiPath, { campaignId: dataParent.id, venueId: node.data.id })
      );
      if (!node.selected) {
        setMapVenues(mapVenues.filter((venue) => venue.id !== node.data.id));
      } else {
        setMapVenues([ ...mapVenues || [], node.data ]);
      }
    }
  };

  /* Map */

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
  });

  const onMapLoad = useCallback(async (map) => {
    setMap(map);
    setVenueTypes((await axios.get(VenueType.apiPath)).data.items);
    setNeighborhoods((await axios.get(Neighborhood.apiPath)).data.items);
  }, []);

  const onMapUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // const infoWindow = isMapLoaded && new google.maps.InfoWindow();
  const setMapMarkerHover = ({ venue, marker, hover = true }) => {
    if (modelParent === Campaign.model && !venue.campaignPlanVenue) {
      return;
    }
    marker = marker || mapMarkers?.find((marker) => marker.venue.id === venue.id);
    if (hover) {
      infoWindow.setContent(`
      <div class="infowindow">
        <h1>${venue.name}</h1>
        <div>${venue.streetNumber} ${venue.streetName}</div>
      </div>`);
      infoWindow.open({
        map,
        anchor: marker,
        shouldFocus: false,
      });
      if (map.getZoom() < 17) {
        map.setZoom(17);
      }
    } else {
      infoWindow.close();
    }
  };

  const onMapRowMouseOver = ({ node: { data: venue } }) =>
    setMapMarkerHover({ venue });

  const onMapRowMouseOut = ({ node: { data: venue } }) =>
    setMapMarkerHover({ venue, hover: false });

  const setPanoramaPosition = ({ lat, lng }) => {
    new google.maps.StreetViewService().getPanorama({
      location: { lat, lng },
      source: google.maps.StreetViewSource.OUTDOOR,
      //preference: 'best',
      //radius: 50,
    }, (streetViewData, status) => {
      if (status === google.maps.StreetViewStatus.OK) {
        setMapCenter({ lat, lng });
        panorama.setPosition(streetViewData.location.latLng);
        panorama.setZoom(1);
        panorama.setPov({
          heading: google.maps.geometry.spherical.computeHeading(streetViewData.location.latLng, new google.maps.LatLng(lat, lng)),
          pitch: 0,
        });
      }
    });
  };

  const selectMapMarket = async ({ market, neighborhood = null }) => {
    setMapMarket(market);
    setMapNeighborhood(neighborhood);
    if (market) {
      const geocoder = new google.maps.Geocoder();
      const { results } = await geocoder.geocode({ address: `${neighborhood ? `${neighborhood.name}, ` : ''}${market.name}${market.state ? `, ${market.state}` : ''}` });
      if (results.length) {
        const { geometry } = results[0];
        const bounds = new google.maps.LatLngBounds(geometry.bounds);
        if (isMapStreetShowing) {
          if (mapStreet) {
            mapStreet.fitBounds(bounds);
            mapStreet.setZoom(Math.max(mapStreet.getZoom(), 12));
          }
          setPanoramaPosition({
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
          });
        } else if ((isMapShowing || isMapSearchShowing) && map) {
          map.fitBounds(bounds);
          map.setZoom(Math.max(map.getZoom(), 12));
        }
      }
    }
  };

  const renderMapMarketDropdown = () =>
    markets.length && mapMarket ?
    <Dropdown className="market">
      <Dropdown.Toggle variant="outline-secondary">{mapNeighborhood ? mapNeighborhood.name : mapMarket.name}</Dropdown.Toggle>
      <Dropdown.Menu>
        {markets.map(({ market, neighborhoods }, i) => (
          <React.Fragment key={i}>
            <Dropdown.Item
              className={classNames({ active: !mapNeighborhood && market === mapMarket })}
              onClick={() => {
                selectMapMarket({ market });
              }}
            >
              {market.name}
            </Dropdown.Item>
            {neighborhoods?.map((neighborhood, i) => (
              <Dropdown.Item
                className={classNames('neighborhood', { active: neighborhood === mapNeighborhood })}
                onClick={() => {
                  selectMapMarket({ market, neighborhood });
                }}
                key={i}
              >
                {neighborhood.name}
              </Dropdown.Item>
            ))}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown> : null;

  const setMapPlacePhotos = async (mapPlaces) => {
    mapPlaces.map(async (place) => {
      if (place.fk_photoId && !place.photos) {
        try {
          place.photos = [{
            photo: (await axios.get(`${Photo.apiPath}/${place.fk_photoId}/?photoData=true`)).data,
          }];
        } catch (e) {
          console.log(e);
        }
        delete place.fk_photoId;
        setMapPlaces([...mapPlaces]);
      }
    });
  };

  const renderMap = () => {
    if (!showMap) {
      return null;
    }
    useEffect(async () => {
      if (!isMapSearchShowing) {
        const { data: { items: venues } } = await axios.get(`${apiPath}${!isEmpty(filterModel) ? `?filter=${encodeURIComponent(JSON.stringify(filterModel))}` : '?fields=id,name,streetNumber,streetName,campaignPlanVenue,latitude,longitude'}${_apiParams ? `&${new URLSearchParams(_apiParams).toString()}` : ''}&raw=true`);
        setMapVenues(modelParent === Campaign.model ? venues.filter((venue) => venue.campaignPlanVenue) : venues);
      }
    }, [filterModel]);
    useEffect(async () => {
      if (map && !isMapSearchShowing) {
        const bounds = new google.maps.LatLngBounds();
        if (mapMarkers) {
          mapMarkers.forEach((marker) => {
            marker.setMap(null);
          });
        }
        if (markets.length) {
          selectMapMarket({ market: markets[0].market });
        }
        if (mapVenues.length) {
          setMapMarkers(mapVenues.map((venue) => {
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(venue.latitude, venue.longitude),
              // animation: google.maps.Animation.DROP,
              venue,
              map,
            });
            marker.addListener('click', async () => {
              showMapDetailRef.current({
                nestedModalForce: true,
                data: (await axios.get(`${apiPath}/${venue[getPrimaryKey(schema)]}${modelParent === RouteSheet.model ? `?routeSheetId=${dataParent.id}&route=true` : ''}`)).data,
              });
            });
            marker.addListener('mouseover', () => {
              setMapMarkerHover({ venue, marker });
            });
            marker.addListener('mouseout', () => {
              setMapMarkerHover({ venue, marker, hover: false });
            });

            bounds.extend(marker.position);

            return marker;
          }));
          map.fitBounds(bounds);
        }
      }
    }, [ map, mapVenues ]);

    // useEffect(() => {
    //   if (gridApi) {
    //     gridApi.columnModel.columnApi.setColumnsVisible(Object.keys(schema).filter((key) => key !== 'name'), !isMapShowing);
    //     gridApi.sizeColumnsToFit();
    //   }
    // }, [ isMapShowing, isMapStreetShowing ]);

    useEffect(async () => {
      if (mapSearchGrid?.source === venueSource.WNDW && mapPlaces?.length && !isMapSearching) {
        setMapPlacePhotos(mapPlaces);
      }
    }, [ mapSearchGrid, isMapSearching ]);


    return (
      isMapLoaded && isMapShowing && !isMapStreetShowing && mapVenues &&
      <>
        {renderMapMarketDropdown()}
        <Col>
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: (isMobile ? mapHeight * 0.6 : mapHeight),
            }}
            options={{
              controlSize: 22,
              gestureHandling: 'greedy',
              styles: [
                {
                  featureType: 'poi',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
              ],
            }}
            onLoad={onMapLoad}
            onUnmount={onMapUnmount}
          />
        </Col>
      </>
    );
  };

  /* Map Street View */

  const setMapPlace = async (place) => {
    if (place) {
      const { data: { items } } = await axios.get(`${apiPath}?googlePlaceId=${place.place_id}${modelParent === Campaign.model ? `&campaignId=${dataParent.id}` : modelParent === Route.model ? `&routeId=${dataParent.id}` : modelParent === RouteSheet.model ? `&routeId=${dataParent.fk_routeId}` : ''}`);
      const existingVenue = items.length ? items[0] : null;
      _setMapPlace({ place, existingVenue });
      return existingVenue;
    }
    _setMapPlace(null);
    return null;
  };

  const initMapPlaceDetailData = (place) => {
    const placeMarketId = allMarkets.find(({ name }) => place.address_components.filter(({ types }) => types.includes('locality') || types.includes('sublocality')).some((c) => name === c.short_name || name === c.long_name))?.id;
    const placeNeighborhoodId = allNeighborhoods.find(({ name }) => place.address_components.filter(({ types }) => types.includes('neighborhood')).some((c) => name === c.short_name || name === c.long_name))?.id;
    const venueType = Object.entries({
      apparel: [ 'clothing_store', 'department_store', 'shoe_store', 'shopping_mall' ],
      'restaurant/cafe': [ 'restaurant', 'cafe' ],
      bakery: ['bakery'],
      'bar/nightclub': [ 'bar', 'night_club' ],
      electronics: ['electronics_store'],
      entertainment: [ 'stadium', 'tourist_attraction', 'zoo' ],
      gallery: ['art_gallery'],
      'garment care': ['laundry'],
      grocery: [ 'supermarket', 'convenience_store' ],
      hardware: ['hardware_store'],
      hotel: ['lodging'],
      museum: ['museum'],
      pharmacy: [ 'pharmacy', 'drugstore' ],
      'prof. services': [ 'accounting', 'bank', 'dentist', 'doctor', 'electrician', 'insurance_agency', 'lawyer', 'locksmith', 'painter', 'physiotherapist', 'plumber', 'real_estate_agency', 'roofing_contractor', 'travel_agency', 'veterinary_care' ],
      salon: [ 'hair_care', 'spa' ],
      theater: ['movie_theater'],
      'wine & liquor': ['liquor_store'],
    }).find(([ , mappedTypes ]) => mappedTypes.some((type) => place.types.includes(type)))?.[0];
    const venueTypeId = venueTypes
      .map(({ id, type }) => ({ id, type: type.toLowerCase() }))
      .find(({ type }) => type === venueType)?.id;

    return {
      name: place.name,
      fk_marketId: placeMarketId,
      fk_neighborhoodId: placeNeighborhoodId,
      streetNumber: place.address_components.find(({ types }) => types.includes('street_number'))?.long_name,
      streetName: place.address_components.find(({ types }) => types.includes('route'))?.short_name,
      city: place.address_components.find(({ types }) => types.includes('locality'))?.long_name || place.address_components.find(({ types }) => types.includes('sublocality_level_1'))?.long_name,
      state: place.address_components.find(({ types }) => types.includes('administrative_area_level_1'))?.short_name,
      zip: place.address_components.find(({ types }) => types.includes('postal_code'))?.short_name,
      phone: place.formatted_phone_number,
      fk_typeId: venueTypeId,
      latitude: place.latitude || place.geometry.location.lat(),
      longitude: place.longitude || place.geometry.location.lng(),
      googlePlaceId: place.place_id,
      fk_routeId: dataParent?.fk_routeId,
    };
  };

  const showMapDetail = async (place) => {
    let existingVenue;
    if (place) {
      existingVenue = await setMapPlace(place);
    } else {
      place = mapPlace.place;
      existingVenue = mapPlace.existingVenue;
    }
    showMapDetailRef.current({
      ...existingVenue && { data: existingVenue },
      dataDefault: initMapPlaceDetailData(place),
      nestedModalForce: true,
    });
  };

  useEffect(async () => {
    let _markets = [];
    if (isMapLoaded) {
      if (modelParent === Campaign.model) {
        const { data: { items } } = await axios.get(`${CampaignMarket.apiPath}?campaignId=${dataParent.id}`);
        _markets = items;
      } else if (modelParent === Route.model || modelParent === RouteSheet.model) {
        const marketId = dataParent.route?.market?.id || dataParent.fk_marketId;
        if (marketId) {
          const neighborhoodId = dataParent.route?.neighborhood?.id || dataParent.fk_neighborhoodId;
          const { data: market } = await axios.get(`${Market.apiPath}/${marketId}`);
          const { data: neighborhood } = await axios.get(`${Neighborhood.apiPath}/${neighborhoodId}`);
          _markets = [{
            market,
            ...neighborhood && { neighborhoods: [neighborhood] },
          }];
        }
      } else {
        // TODO: include neighborhoods for each market
        const { data: { items } } = await axios.get(`${Market.apiPath}`);
        const { data: { items: neighborhoods } } = await axios.get(`${Neighborhood.apiPath}`);
        _markets = items.map((market) => ({
          market,
          neighborhoods: neighborhoods.filter((neighborhood) => neighborhood.fk_marketId === market.id),
        }));
      }
      setMarkets(_markets);
      if (_markets.length && panorama) {
        selectMapMarket({ market: _markets[0].market });
      }
      const { data: { items: _allMarkets } } = await axios.get(`${Market.apiPath}`);
      const { data: { items: _allNeighborhoods } } = await axios.get(`${Neighborhood.apiPath}`);
      setAllMarkets(_allMarkets);
      setAllNeighborhoods(_allNeighborhoods);
    }
  }, [ isMapLoaded, isMapStreetShowing, panorama ]);

  useEffect(() => {
    if (isMapLoaded) {
      setInfoWindow(new google.maps.InfoWindow());
    }
  }, [isMapLoaded]);

  const checkIsPanoramaSelectShowing = () => {
    setIsPanoramaSelectShowing(
      mapPlace && panorama && google.maps.geometry.spherical.computeDistanceBetween(mapPlace.place.geometry.location, panorama.getPosition()) < 75, // TODO: make proximity dynamic based on market/neighborhood density??
    ); // clear place if greater than 75 meters away, select if within 75 meters
  };

  useEffect(async () => {
    if (panorama || mapPlace) {
      google.maps.event.clearListeners(panorama, 'position_changed');
      panorama.addListener('position_changed', () => {
        mapStreet.setCenter(panorama.getPosition());
        checkIsPanoramaSelectShowing();
      });
      if (mapPlace) {
        checkIsPanoramaSelectShowing();
      }
    }
  }, [ panorama, mapPlace ]);

  const onMapStreetLoad = useCallback(async (map) => {
    setMapStreet(map);

    const panorama = new google.maps.StreetViewPanorama(document.getElementById('pano'), {
      position: mapCenter,
      addressControl: false,
      controlSize: 28,
    });
    setPanorama(panorama);
    map.setStreetView(panorama);
    setVenueTypes((await axios.get(VenueType.apiPath)).data.items);
    setNeighborhoods((await axios.get(Neighborhood.apiPath)).data.items);
  }, []);

  const onMapStreetUnmount = useCallback(() => {
    setMapStreet(null);
  }, []);

  const onMapAutocompleteLoad = useCallback(async (autocomplete) => {
    setMapAutocomplete(autocomplete);
  }, []);

  const clearMapCircle = () => {
    if (mapCircleRef.current) {
      mapCircleRef.current.setMap(null);
    }
  };

  const onMapAutocompletePlaceChanged = () => {
    if (mapAutocomplete !== null) {
      const place = mapAutocomplete.getPlace();
      const latLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      if (isMapStreetShowing) {
        mapStreet.fitBounds(place.geometry.viewport);
        setPanoramaPosition(latLng);
        showMapDetail(place);
      } else if (isMapSearchShowing) {
        map.fitBounds(place.geometry.viewport);
        clearMapCircle();
        setMapRadius(null);
        mapFormState.setFieldValue('radius', null);
      }
    }
  };

  const renderMapStreet = () => {
    return isMapLoaded && isMapStreetShowing ? (
      !markets.length ? <div className="alert empty">Add market(s) above before mapping venues</div> :
        <>
          {renderMapMarketDropdown()}
          <Row>
            <Col>
              <GoogleMap
                id="mapStreet"
                mapContainerStyle={{
                  width: '100%',
                  height: mapHeight,
                }}
                mapContainerClassName={classNames('map')}
                center={mapCenter}
                options={{
                  controlSize: 28,
                  gestureHandling: 'greedy',
                  mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                  } }}
                onLoad={onMapStreetLoad}
                onUnmount={onMapStreetUnmount}
                onClick={(e) => {
                  if (e.placeId) {
                    const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
                    setPanoramaPosition(latLng);
                    new google.maps.places.PlacesService(document.createElement('div')).getDetails({
                      placeId: e.placeId,
                    }, (place, status) => {
                      showMapDetail(place);
                    });
                  }
                }}
              >
                <Autocomplete
                  onLoad={onMapAutocompleteLoad}
                  onPlaceChanged={onMapAutocompletePlaceChanged}
                  bounds={mapStreet ? (new google.maps.Circle({ radius: 30, center: mapStreet.getCenter() })).getBounds() : null}
                >
                  <input
                    type="text"
                    placeholder="Find Venue"
                    className="autocomplete"
                  />
                </Autocomplete>
                {panorama ?
                  <div
                    ref={(el) => {
                      panorama.controls[google.maps.ControlPosition.TOP_LEFT].push(el);
                    }}
                    className={classNames('select', { show: isPanoramaSelectShowing })} // clear place / add venue if greater than 30 meters away
                    onClick={() => {
                      showMapDetail();
                    }}
                  >
                    {
                      modelParent === Campaign.model ?
                      `${mapPlace?.existingVenue?.campaignPlanVenue ? 'Deselect' : 'Select'}` :
                      `${mapPlace?.existingVenue ? 'Edit' : 'Add'}`
                    }
                  </div> : null}
              </GoogleMap>
            </Col>
            <Col>
              <div id="pano" className="pano" />
            </Col>
          </Row>
        </>
    ) : null;
  };

  /* Map Search */

  const drawMapCircle = ({ radius, fitBounds = false } = {}) => { // eslint-disable-line
    clearMapCircle();
    if (radius) {
      const circle = new google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.75,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.15,
        map,
        center: map.getCenter(),
        radius,
      });
      /* if (fitBounds) {
        map.fitBounds(circle.getBounds());
        map.setZoom(map.getZoom() - 1);
      } */
      setMapCircle(circle);
      return circle;
    }
  };

  const infoWindowContentRef = useRef(null);
  const showMapPlaceMarkerInfoWindow = ({ place, marker, show = true }) => {
    marker = marker || mapMarkers?.find((marker) => marker._place.placeId === place.placeId);
    infoWindow?.close();
    if (show) {
      setInfoWindowPlace(place);
      infoWindow.setContent(infoWindowContentRef.current);
      infoWindow.open({
        map,
        anchor: marker,
        shouldFocus: false,
      });
      // if (map.getZoom() < 17) {
      //   map.setZoom(17);
      // }
    }/*  else {
      infoWindow.close();
    } */
  };

  const buildMapPlaceMarkers = (places) =>
    places.map((place) => { // eslint-disable-line
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(place.latitude, place.longitude),
        animation: google.maps.Animation.DROP,
        zIndex: 1,
        _place: place,
        map,
      });
      marker.addListener('click', () => {
        showMapPlaceMarkerInfoWindow({
          place,
          marker,
        });
      });
      // place.marker.addListener('mouseover', () => {
      //   setMapPlaceMarkerHover({ place });
      // });
      // place.marker.addListener('mouseout', () => {
      //   setMapPlaceMarkerHover({ place, hover: false });
      // });
      // bounds.extend(marker.position);
      return marker;
    });


  const previewMapPlace = async (place) => {
    const geocoder = new google.maps.Geocoder();
    const directionsService = new google.maps.DirectionsService();
    const { results: geocodeResults } = await geocoder.geocode({ placeId: place.placeId });
    const geocodeLatLng = geocodeResults[0].geometry.location;

    // find a streetview location on the road
    const directionsResult = await directionsService.route({
      origin: { placeId: place.placeId },
      destination: { placeId: place.placeId },
      travelMode: google.maps.TravelMode.DRIVING,
    });
    const directionsLatLng = directionsResult.routes[0].legs[0].start_location;

    const { data: streetViewData } = await new google.maps.StreetViewService().getPanorama({
      location: directionsLatLng,
      source: google.maps.StreetViewSource.OUTDOOR,
      radius: 50,
      preference: 'nearest',
    });
    setMapPlacePreview({
      ...place,
      pano: streetViewData.location.pano,
      heading: google.maps.geometry.spherical.computeHeading(streetViewData.location.latLng, geocodeLatLng),
    });
  };

  const excludeMapPlace = async (place) => {
    let { data } = await axios.post(GooglePlaceExclude.apiPath, { // eslint-disable-line
      name: place.name,
    });
    setMapPlaceExcludes([
      ...mapPlaceExcludes,
      place,
    ]);
  };

  const getMapSearchBounds = (circle) => {
    const bounds = mapCircle.getBounds();
    return {
      latitudeMin: bounds.getSouthWest().lat(),
      latitudeMax: bounds.getNorthEast().lat(),
      longitudeMin: bounds.getSouthWest().lng(),
      longitudeMax: bounds.getNorthEast().lng(),
    };
  };

  const MILE_METERS = 1609.344;
  const getMileRadius = (radius) => radius ? parseFloat((radius / MILE_METERS).toFixed(1)) : null;

  const renderMapSearchGrid = async ({ grid, circle, initial }) => {
    circle = circle || mapCircle;
    let bounds = getMapSearchBounds(circle);
    let places = [];
    let markers = [];

    setMapSearchGrid(grid);
    for (let s = 0; s < grid.steps.length; s += 1) {
      if (!mapRadiusRef.current || !mapCircleRef.current || !isMapSearchingRef.current || !isMapSearchShowingRef.current) {
        setMapSearchGrid(null);
        // setMapPlaces(null);
        setIsMapSearching(false);
        break;
      }
      setMapSearchGridStep(s);
      const step = grid.steps[s];
      const stepLatitude = step[0];
      const stepLongitude = step[1];
      const stepCenter = new google.maps.LatLng(stepLatitude, stepLongitude);
      const maxDist = circle.getRadius() + grid.radius;
      const actualDist = google.maps.geometry.spherical.computeDistanceBetween(circle.getCenter(), stepCenter);
      if (maxDist >= actualDist) {
        const stepCircle = new google.maps.Circle({
          strokeColor: '#000000',
          strokeOpacity: 0.4,
          strokeWeight: 1,
          fillColor: '#000000',
          fillOpacity: 0.25,
          zIndex: 2,
          map,
          center: stepCenter,
          radius: grid.radius,
        });
        let { data } = await axios.post(GooglePlace.apiPath, { // eslint-disable-line
          campaignId: dataParent.id,
          marketId: mapMarket?.id,
          neighborhoodId: mapNeighborhood?.id,
          latitude: stepLatitude,
          longitude: stepLongitude,
          radius: grid.radius,
          source: grid.source,
          bounds,
          zoom: map.getZoom(),
        });
        stepCircle.setMap(null);

        if (initial && data.reduceRadius) {
          circle = drawMapCircle({ radius: data.reduceRadius, fitBounds: true });
          bounds = getMapSearchBounds(circle);
          setMapRadius(data.reduceRadius); // force to .1mi
          mapFormState.setFieldValue('radius', getMileRadius(data.reduceRadius));

          const { data: { grid } } = await axios.post(`${GooglePlace.apiPath}?grid=true`, { radius: data.reduceRadius, bounds }); // eslint-disable-line
          await renderMapSearchGrid({ grid, circle }); // eslint-disable-line
          return true;
        }

        const stepPlaces = data.items.filter((stepPlace) =>
          !places.find((place) => place.placeId === stepPlace.placeId) &&
          !mapPlaces?.find((place) => place.placeId === stepPlace.placeId));

        places = [ ...places, ...stepPlaces ];

        if (places.length === 1) {
          map.fitBounds(circle.getBounds());
          // map.setZoom(map.getZoom()-1);
        }

        const stepMarkers = buildMapPlaceMarkers(stepPlaces);
        markers = [ ...markers, ...stepMarkers ];
        setMapMarkers([
          ...mapMarkers ?? [],
          ...markers,
        ]);
        setMapPlaces([
          ...mapPlaces ?? [],
          ...places,
        ]);
      }
    }
    return true;
  };

  const renderMapSearch = () => {
    useEffect(async () => {
      if (map) {
        if (markets.length) {
          const { market } = markets[0];
          selectMapMarket({ market });
        }
        google.maps.event.clearListeners(map, 'dragend');
        map.addListener('dragend', () => {
          if (mapRadiusRef.current) {
            drawMapCircle({ radius: mapRadiusRef.current });
          }
        });

        const { data: campaignVenueSearch } = await axios.get(`${CampaignVenueSearch.apiPath}?campaignId=${dataParent?.id}`);
        if (campaignVenueSearch) {
          selectMapMarket({ market: allMarkets.find(({ id }) => id === campaignVenueSearch.fk_marketId), neighborhood: allNeighborhoods.find(({ id }) => id === campaignVenueSearch.fk_neighborhoodId) });
          setMapRadius(campaignVenueSearch.radius);
          mapFormStateRef.current?.setFieldValue('radius', getMileRadius(campaignVenueSearch.radius));
          setMapSource(campaignVenueSearch.source);
          mapFormStateRef.current?.setFieldValue('source', campaignVenueSearch.source);
          setTimeout(() => {
            map.setCenter(new google.maps.LatLng(campaignVenueSearch.latitude, campaignVenueSearch.longitude));
            drawMapCircle({ radius: campaignVenueSearch.radius });
            map.fitBounds(new google.maps.LatLngBounds(
              new google.maps.LatLng({ lat: campaignVenueSearch.bounds.latitudeMin, lng: campaignVenueSearch.bounds.longitudeMin }),
              new google.maps.LatLng({ lat: campaignVenueSearch.bounds.latitudeMax, lng: campaignVenueSearch.bounds.longitudeMax }),
            ));
            map.setZoom(campaignVenueSearch.zoom);
            setMapMarkers(buildMapPlaceMarkers(campaignVenueSearch.googlePlaces));
            setMapPlaces(campaignVenueSearch.googlePlaces);
            setMapPlacePhotos(campaignVenueSearch.googlePlaces);
          }, 1000);
        }

        // TODO: remove after dev
        // setTimeout(() => {
        //   map.setCenter(new google.maps.LatLng(40.789850, -73.387120));
        // }, 2000);
      }
    }, [map]);

    return (
      isMapLoaded && isMapSearchShowing &&
      <>
        <Modal
          showing={isMapSearchShowing}
          onHide={() => {
            setIsMapSearchShowing(false);
            setMapCircle(null);
            setMapRadius(null);
            setMapPlaces(null);
            setMapMarkers(null);
            setMapPlacesAddSelected([]);
          }}
          fullScreen
          className={classNames('venue map-search', { 'place-select-showing': mapPlacePreview })}
          body={
            <>
              <div className="nav">
                {renderMapMarketDropdown()}
                <Autocomplete
                  className="search"
                  onLoad={onMapAutocompleteLoad}
                  onPlaceChanged={onMapAutocompletePlaceChanged}
                  bounds={map ? (new google.maps.Circle({ radius: 30, center: map.getCenter() })).getBounds() : null}
                >
                  <div className="form-outline">
                    <input
                      type="text"
                      placeholder="Enter POI, Neighborhood, Zip, City or County"
                      className="autocomplete form-control active form-control-sm active"
                    />
                  </div>
                </Autocomplete>
                <div className="options">
                  <Form
                    schema={{
                      radius: {
                        label: 'Radius',
                        required: true,
                        form: {
                          type: 'autocomplete',
                          items: [
                            ...Array.from({ length: 9 }, (_, m) => parseFloat(((m + 1) * 0.1).toFixed(1))),
                            ...Array.from({ length: mapSource === venueSource.WNDW ? 10 : 3 }, (_, m) => m + 1),
                          ].map((m) => ({ value: m, label: `${m.toString().replace(/^0+/, '')} mi` })),
                          defaultValue: getMileRadius(mapRadius),
                          col: 1,
                          onChange: async ({ item, fields, setFieldValue }) => {
                            const radius = item?.value ? item.value * MILE_METERS : null;
                            drawMapCircle({ radius, fitBounds: true });
                            setMapRadius(radius);
                            setIsMapSearching(false);
                          },
                        },
                      },
                      source: {
                        label: 'Source',
                        required: true,
                        form: {
                          type: 'autocomplete',
                          items: Object.values(venueSource),
                          defaultValue: mapSource,
                          col: 2,
                          onChange: async ({ item, fields, setFieldValue }) => {
                            setMapSource(item.value);
                          },
                        },
                      },
                    }}
                    submitLabel="Search"
                    onSubmit={async () => {
                      setMapSearchGridStep(null);
                      setIsMapSearching(true);
                      // setMapPlaces(null);
                      // mapMarkers?.forEach((marker) => marker.setMap(null));
                      // setMapMarkers(null);

                      const center = mapCircle.getCenter();
                      const grid = {
                        radius: mapRadius,
                        source: mapSource,
                        steps: [
                          [ center.lat(), center.lng() ],
                        ],
                      };

                      await renderMapSearchGrid({ grid, initial: true });
                      setIsMapSearching(false);
                    }}
                    getState={(state) => setMapFormState(state)}
                  />
                  {isMapSearching &&
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Stop Search</Tooltip>}
                    >
                      <div className="stop" onClick={() => setIsMapSearching(false)}>
                        <i className="fa fa-circle-stop fa-regular" />
                      </div>
                    </OverlayTrigger>}
                  {!isMapSearching && mapPlacesRef.current?.length &&
                      <div className="clear">
                        <Button
                          variant="outline-secondary"
                          onClick={() => {
                            setMapPlaces(null);
                            mapMarkers?.forEach((marker) => marker.setMap(null));
                            setMapMarkers(null);
                            axios.delete(CampaignVenueSearch.apiPath, { data: { campaignId: dataParent.id } });
                          }}
                          className="inline-button"
                        >
                          Clear
                        </Button>
                      </div>}
                </div>
                {isMapSearching && mapSearchGrid &&
                  <div className="alerts">
                    <Alert variant="secondary" className="empty">
                      {mapSearchGrid?.steps.length > 1 ?
                        <>
                          Section {mapSearchGridStep} of {mapSearchGrid?.steps.length}
                          <span>/</span>
                        </> : null}
                      {mapPlaces?.length || 0} Found
                    </Alert>
                  </div>}
                {!isMapSearching && mapPlaces &&
                  <div className="alerts">
                    <Alert variant="secondary" className="empty">
                      {mapPlaces.length} Found
                    </Alert>
                    <Alert variant="secondary" className="empty">
                      {mapPlaces.filter((p) => p._campaignPlanVenue).length} Added
                    </Alert>
                  </div>}
              </div>
              <Row>
                <Col>
                  <>
                    <GoogleMap
                      mapContainerClassName="map"
                      mapContainerStyle={{
                        width: '100%',
                        height: mapPlacesRef.current?.length ? 345 : window.innerHeight - 150,
                      }}
                      options={{
                        controlSize: 22,
                        gestureHandling: isMapSearching ? 'none' : 'greedy',
                        styles: [
                          {
                            featureType: 'poi',
                            stylers: [
                              {
                                visibility: 'off',
                              },
                            ],
                          },
                        ],
                      }}
                      onLoad={onMapLoad}
                      onUnmount={onMapUnmount}
                    />
                    <div
                      className="infowindow"
                      onClick={() => previewMapPlace(infoWindowPlace)}
                      ref={infoWindowContentRef}
                    >
                      {infoWindowPlace &&
                      <>
                        <div className="photo">
                          {infoWindowPlace.fk_photoId && !infoWindowPlace.photos ? <Spinner /> :
                           infoWindowPlace.photos?.length ? <img src={`data:image/jpeg;base64,${infoWindowPlace.photos[0].photo}`} /> : // eslint-disable-line
                           <FontAwesomeIcon icon={faImage} />}
                        </div>
                        <div className="caption">
                          <div className="name">{infoWindowPlace?.name}</div>
                          <div className="address">{infoWindowPlace?.address}</div>
                        </div>
                      </>}
                    </div>
                  </>
                </Col>
              </Row>
              <Row>
                <Col className={classNames('results-container', { empty: !mapPlacesRef.current?.length })}>
                  {mapPlaces?.length &&
                    <div className="buttons">
                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          setMapPlacesAddSelected(mapPlaces.filter((p) => !p._campaignPlanVenue));
                        }}
                        className="select-all"
                      >
                        Select All
                      </Button>
                      {mapPlacesAddSelected.length ?
                        <Button
                          variant="secondary"
                          onClick={() => setMapPlacesAddSelectedDetailShowing(true)}
                          className="add-selected"
                        >
                          Add Selected ({mapPlacesAddSelected.length})
                          <i className="fa fa-chevron-right" />
                        </Button> : null}
                    </div>}
                  <div
                    className="places-container"
                    style={{
                      height: window.innerHeight - 475,
                    }}
                  >
                    <div className="places">
                      {mapPlaces?.map((place) =>
                        <div
                          className={classNames('place', { excluded: mapPlaceExcludes.includes(place) })}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>{mapPlacesAddSelected.includes(place) ? 'Deselect' : place._campaignPlanVenue ? 'Added' : 'Select to Add'}</Tooltip>
                            }
                          >
                            <i
                              className={classNames('add', { check: mapPlacesAddSelected.includes(place), added: place._campaignPlanVenue })}
                              onClick={() => {
                                if (!place._campaignPlanVenue) {
                                  if (!mapPlacesAddSelected.includes(place)) {
                                    setMapPlacesAddSelected([ ...mapPlacesAddSelected, place ]);
                                  } else {
                                    setMapPlacesAddSelected(mapPlacesAddSelected.filter((p) => p !== place));
                                  }
                                }
                              }}
                            />
                          </OverlayTrigger>
                          {!place._campaignPlanVenue &&
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Block</Tooltip>}
                            >
                              <i
                                className="exclude"
                                onClick={() => excludeMapPlace(place)}
                              />
                            </OverlayTrigger>}
                          <div
                            className={classNames('photo', { default: !place.fk_photoId && !place.photos?.length })}
                            onMouseEnter={() => showMapPlaceMarkerInfoWindow({ place })}
                            onMouseLeave={() => showMapPlaceMarkerInfoWindow({ place, show: false })}
                            onClick={() => previewMapPlace(place)}
                          >
                            {place.fk_photoId && !place.photos ? <Spinner /> :
                             place.photos?.length ? <img src={`data:image/jpeg;base64,${place.photos[0].photo}`} /> : // eslint-disable-line
                             <FontAwesomeIcon icon={faImage} />}
                          </div>
                          <div className="caption">
                            <div className="name">{place.name}</div>
                            <div className="address">{place.address}</div>
                          </div>
                        </div>)}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          }
        />
        <Modal
          showing={!!mapPlacesAddSelectedDetailShowing}
          onHide={() => {
            setMapPlacesAddSelectedDetailShowing(false);
          }}
          fullScreen
          className="venue map-search add-selected"
          body={
            <>
              <div className="header">
                <div className="label">
                  Add Selected Venues
                </div>
              </div>
              <div className="detail">
                <Form
                  schema={alterSchema(schema, { formFields: [ 'fk_marketId', 'fk_neighborhoodId' ] })}
                  submitLabel="Add"
                  dataDefault={{
                    fk_marketId: mapMarket?.id,
                    fk_neighborhoodId: mapNeighborhood?.id,
                  }}
                  onSubmit={async (submitData) => {
                    const added = await Promise.all(mapPlacesAddSelected.map(async (place) => {
                      if (place.id) {
                        await axios.post(CampaignPlanVenue.apiPath, { campaignId: dataParent.id, venueId: place.id });
                        place._campaignPlanVenue = true;
                        return place;
                      }
                      const { data: venue } = await axios.post(`${apiPath}?campaignId=${dataParent.id}`, {
                        ...place.id ? { ...place, photos: undefined } : initMapPlaceDetailData(place),
                        ...submitData,
                      });
                      place._campaignPlanVenue = !!venue.campaignPlanVenue; // eslint-disable-line
                      return place;
                    }));
                    setMapPlaces(mapPlaces.map((place) => ({
                      ...place,
                      _campaignPlanVenue: added.find(({ placeId }) => placeId === place.placeId)?._campaignPlanVenue || place._campaignPlanVenue,
                    })));
                    setMapPlacesAddSelected([]);
                    setMapPlacesAddSelectedDetailShowing(false);
                  }}
                />
              </div>
            </>
          }
        />
        <Modal
          showing={!!mapPlacePreview}
          onHide={() => {
            setMapPlacePreview(null);
          }}
          fullScreen
          body={
            mapPlacePreview ? <iframe
              width="100%"
              height={window.innerHeight - 150}
              style={{ border: 0 }}
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/streetview?key=${process.env.REACT_APP_GOOGLE_API_KEY}&pano=${mapPlacePreview.pano}&heading=${mapPlacePreview.heading}`} // &location=${mapPlacePreview.latitude},${mapPlacePreview.longitude}
            /> : null
          }
        />
      </>
    );
  };

  return (
    <Row
      className={classNames({ 'index venue': !dataParent, 'map-showing': isMapShowing, 'map-street-showing': isMapStreetShowing, 'map-search-showing': isMapSearchShowing })}
      style={{ height: '100%' }}
      ref={(el) => {
        if (el) {
          // setMapHeight(isMobile ? el.getBoundingClientRect().height * 0.7 : window.innerHeight - el.getBoundingClientRect().top - (modelParent ? 30 : 55));
          setMapHeight(window.innerHeight - el.getBoundingClientRect().top - (isMobile ? 10 : (modelParent ? 30 : 55)));
          // setMapHeight(window.innerHeight - el.getBoundingClientRect().top - 75); // street view
        }
      }}
    >
      <Col className="col" md={isMapShowing ? 3 : 12}>
        <Crud
          apiPath={apiPath}
          apiParams={_apiParams} //modelParent === Campaign.model
          schema={
            props.schema ? props.schema :
            modelParent === Campaign.model ? alterSchema(schema, apiParams?.distributionVenue ? { gridCols: [ 'name', 'type.type', 'market.name', 'neighborhood.name', 'fk_routeId', 'address', 'contactEmail', 'agreementStatus', 'upCurrent', dataParent.routeSheetAudit && 'auditStatus' ].filter(Boolean) } : { gridColsHide: [ 'id', 'campaignPlanVenue' ], ...isMapShowing && { gridColsVisible: ['name'] } }) :
            isMapShowing ? alterSchema(schema, { gridColsVisible: ['name'] }) :
            modelParent === Route.model ? alterSchema(schema, { gridColsHide: ['fk_routeId'] }) :
            modelParent === RouteSheet.model ? alterSchema(schema, { gridCols: [ 'name', 'address', 'type.type' ].filter(Boolean) }) :
            schema
          }
          schemaParent={schema}
          routePath={routePath}
          label={label}
          {...((modelParent || isMapShowing) && {
            nested: true,
          })}
          dataParent={dataParent}
          model={model}
          modelParent={modelParent}
          {...showMap && {
            icons: [
              !isMapShowing && {
                type: iconType.SEARCH,
                onClick: () => setIsMapSearchShowing(!isMapSearchShowing),
                active: isMapSearchShowing,
                tooltip: !isMobile && 'Find Venues',
              },
              isMapShowing && {
                type: iconType.STREET_VIEW,
                onClick: () => setIsMapStreetShowing(!isMapStreetShowing),
                active: isMapStreetShowing,
                tooltip: !isMobile && 'Toggle Street View',
              },
              {
                type: iconType.MAP,
                onClick: () => {
                  const mapShowing = !isMapShowing;
                  // const mapStreetShowing = (mapShowing && !mapVenues.length) || false;
                  setIsMapShowing(mapShowing);
                  props?.setIsMapShowing(mapShowing);
                  setIsMapStreetShowing((mapShowing && !mapVenues.length) || false);
                },
                active: isMapShowing,
                tooltip: !isMobile && 'Toggle Map',
              }].filter(Boolean),
          }}
          {...(modelParent === Campaign.model && apiParams?.distributionVenue && {
            icons: [{
              type: iconType.EXPORT,
              tooltip: 'Export Campaign Venues',
              onClick: async () => {
                const workbook = XLSX.utils.book_new();
                const escapeRegex = /[\\\/\?\*\[\]\:]/g; // eslint-disable-line
                const { data: { items: jobs } } = await axios.get(`${Job.apiPath}/?campaignId=${dataParent.id}`);
                (await Promise.all(jobs.map(async (job) => {
                  const { data: { items: venues } } = await axios.get(`${apiPath}/?campaignId=${dataParent.id}&jobId=${job.id}&distributionVenue=true`);
                  return {
                    job,
                    venues,
                  };
                }))).forEach(({ job, venues }) => {
                  try {
                    const worksheet = XLSX.utils.json_to_sheet(venues.map((venue) => ({
                      ID: venue.id,
                      Name: venue.name,
                      Address: venue.address,
                      Zip: venue.zip,
                      County: venue.county,
                      Email: venue.contactEmail,
                      Type: venue.type?.type,
                      Neighborhood: venue.neighborhood?.name,
                      Market: venue.market?.name,
                      Latitude: venue.latitude,
                      Longitude: venue.longitude,
                      Facing: venue.facingDirection,
                      'Geopath ID': venue.geopathId,
                      'Install Date': venue.installDate,
                      'Up Current': venue.upCurrent ? 'Yes' : 'No',
                      ...dataParent.routeSheetAudit && {
                        'Audit Status': venue.auditStatus,
                      },
                    })));
                    XLSX.utils.book_append_sheet(workbook, worksheet, truncate(`${job.campaignMarket.market.name} | ${job.item.name.replace(escapeRegex, ' ')}`, { length: 30 }));
                  } catch (e) {
                    console.log(e);
                  }
                });
                XLSX.writeFile(workbook, `Venues-${dataParent.title.replace(escapeRegex, ' ')}-${moment().format('YYYYMMDD')}.xlsx`, { compression: true });
              },
            }],
          })}
          showAdd={showAdd}
          showExport={showExport}
          /* gridShowing={!isMapStreetShowing} */
          {...(modelParent === Campaign.model && !apiParams?.distributionVenue && {
            checkSelect: true,
            checkSelectedField: 'campaignPlanVenue',
            onCheckSelected: onVenueCheckSelected,
            exportData: (rowData) => rowData?.filter((item) => (!item.status || item.status === venueStatus.ACTIVE) && item.campaignPlanVenue),
          })}
          onRowClick={onRowClick && !isMapShowing ? (params) => onRowClick({ ...params, refreshData: refreshVenues }) : null}
          onCellMouseOver={isMapShowing && !isMapStreetShowing && onMapRowMouseOver}
          onCellMouseOut={isMapShowing && !isMapStreetShowing && onMapRowMouseOut}
          onFilterChanged={setFilterModel}
          getGridApi={setGridApi}
          showDetailRef={showMapDetailRef}
          onHideNestedDetailModal={() => {
            if (isMapStreetShowing && mapPlace) {
              setMapPlace(mapPlace.place);
            }
          }}
          gridHeight={gridHeight || (mapHeight * (isMobile && isMapShowing ? 0.4 : 1))}
          filterModel={filterModel}
          refreshData={setRefreshVenues}
          rowClass={rowClass}
          {...(modelParent === Campaign.model && !apiParams?.distributionVenue && {
            exportCols: [ 'id', 'name', 'address', 'zip', 'county', 'type.type', 'neighborhood.name', 'market.name', 'latitude', 'longitude', 'facingDirection', 'geopathId' ],
          })}
          onInit={() => {
            onInit?.({ gridApi });
            forceUpdate();
          }}
        />
      </Col>
      {renderMap()}
      {renderMapStreet()}
      {renderMapSearch()}
    </Row>
  );
};

export default Venue;
