import { cloneDeep, pick } from 'lodash';

export const getPrimaryKey = (schema) =>
  Object.keys(schema).find((key) => schema[key].primaryKey);

export const alterSchema = (schema, { gridCols, gridColsHide, gridColsShow, gridColsVisible, gridColsOrder, gridDetail, formFields, formFieldsHide, formFieldsShow, formFieldsColBreak, formFieldsInfo, formFieldsInfoOnly, exportEmpty, labels, types } = {}) => {
  schema = cloneDeep(schema);
  const primaryKey = getPrimaryKey(schema);
  if (gridColsOrder) {
    gridCols = gridColsOrder;
  }
  if (gridCols) {
    Object.keys(schema).forEach((key) => {
      const order = gridColsOrder?.indexOf(key);
      schema[key].grid = {
        ...schema[key].grid,
        hide: !gridCols.includes(key) || (key === primaryKey && schema[key].grid?.hide !== false), // || (key === primaryKey && !gridCols.includes(primaryKey))
        ...order >= 0 && {
          order: order + 1,
        },
      };
    });
  }
  if (gridColsHide) {
    gridColsHide.forEach((key) => {
      schema[key].grid = {
        ...schema[key].grid,
        hide: true,
      };
    });
  }
  if (gridColsShow) {
    gridColsShow.forEach((key) => {
      delete schema[key].grid.hide;
    });
  }
  if (gridColsVisible) {
    Object.keys(schema).forEach((key) => {
      if (schema[key].grid) {
        schema[key].grid.visible = gridColsVisible.includes(key);
      }
    });
  }
  if (gridDetail) {
    Object.keys(gridDetail).forEach((key) => {
      if (schema[key].grid) {
        if (gridDetail[key]) {
          schema[key].grid.detail = () => ({
            ...gridDetail[key],
          });
        } else {
          delete schema[key].grid.detail;
        }
      }
    });
  }
  if (formFields) {
    schema = pick(schema, [ primaryKey, ...formFields ]);
    formFields.forEach((key) => {
      if (key !== primaryKey) {
        delete schema[key].form.hide;
      }
    });
  }
  if (formFieldsHide) {
    formFieldsHide.forEach((key) => {
      schema[key].form = {
        ...schema[key].form,
        hide: true,
        info: undefined,
      };
    });
  }
  if (formFieldsShow) {
    formFieldsShow.forEach((key) => {
      delete schema[key].form.hide;
    });
  }
  if (formFieldsColBreak) {
    formFieldsColBreak.forEach((key) => {
      schema[key].form = {
        ...schema[key].form,
        colBreak: true,
      };
    });
  }
  if (formFieldsInfo) {
    formFieldsInfo.forEach((key) => {
      schema[key].form = {
        ...schema[key].form,
        info: true,
      };
    });
  }
  if (formFieldsInfoOnly) {
    Object.keys(schema).forEach((key) => {
      if (schema[key].form && !schema[key].form.info) {
        schema[key].form = {
          ...schema[key].form,
          hide: true,
        };
      }
    });
  }
  if (types) {
    Object.keys(types).forEach((key) => {
      schema[key].type = types[key];
    });
  }
  if (labels) {
    Object.keys(labels).forEach((key) => {
      schema[key].label = labels[key];
    });
  }
  if (exportEmpty) {
    exportEmpty.forEach((key) => {
      schema[key] = {
        ...schema[key],
        exportEmpty: true,
      };
    });
  }
  return schema;
};
