import axios from 'axios';

export const model = 'campaignStageFlow';
export const apiPath = '/campaignStageFlow';

export const onSelected = async ({ node, dataParent }) => {
  if (!!node.data.flow?.completedDate !== node.selected) {
    const { data: { completedDate } } = await axios.patch(apiPath, { campaignId: dataParent.id, campaignStageId: node.data.id, completed: node.selected });
    node.setDataValue('flow.completedDate', completedDate);
  }
};

export const onChange = ({ value, data, dataParent, key }) =>
  axios.patch(apiPath, { campaignId: dataParent.id, campaignStageId: data.id, [key]: value });
