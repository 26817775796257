import { camelCase, startCase } from 'lodash';
import * as Route from 'services/route';
import * as Client from 'views/client/config';
import * as Vendor from 'views/vendor/config';
import * as Venue from 'views/venue/config';

export const status = {
  LEAD: '',
  ACTIVE: '',
  INACTIVE: '',
};
const statusItems = Object.keys(status).map((key) => {
  status[key] = startCase(camelCase(key));
  return status[key];
});

export const stage = {
  NEW: '',
  TOUCH: '',
  REPLY: '',
  MEETING_BOOKED: '',
  MEETING_CONDUCTED: '',
  RFP: 'RFP',
};
const stageItems = Object.keys(stage).map((key) => {
  stage[key] = stage[key] || startCase(camelCase(key));
  return stage[key];
});

export const model = 'contact';
export const label = 'Contact';
export const apiPath = '/contacts';
export const routePath = () => Route.paths.crm.contacts;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  nameFirst: {
    label: 'First Name',
    required: true,
    form: {
      col: 1,
    },
  },
  nameLast: {
    label: 'Last Name',
    required: true,
    form: {
      col: 2,
    },
  },
  nameFull: {
    label: 'Name',
    grid: {
      minWidth: 150,
    },
    form: {
      hide: true,
    },
  },
  type: {
    label: 'Type',
    grid: {},
    form: {
      type: 'autocomplete',
      //items: [{ value: 'Client', label: 'Company' }, 'Vendor', 'Venue', 'Pending Employee' ],
      items: [ 'Client', 'Vendor', 'Venue', 'Pending Employee' ],
    },
  },
  fk_clientId: {
    label: 'Company',
    grid: {
      minWidth: 125,
      hide: true,
    },
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Client.apiPath,
      detail: () => ({
        key: 'client.id',
        ...Client,
      }),
    },
  },
  'client.name': {
    label: 'Company',
    grid: {
      minWidth: 150,
      detail: () => ({
        key: 'client.id',
        ...Client,
      }),
    },
    form: {
      hide: true,
    },
  },
  fk_vendorId: {
    label: 'Vendor',
    grid: {
      minWidth: 125,
      hide: true,
    },
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Vendor.apiPath,
      detail: () => ({
        key: 'vendor.id',
        ...Vendor,
      }),
    },
  },
  fk_venueId: {
    label: 'Venue',
    grid: {
      minWidth: 125,
      hide: true,
    },
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => null,
      detail: () => ({
        key: 'venue.id',
        ...Venue,
      }),
    },
  },
  status: {
    label: 'Status',
    grid: {},
    form: {
      type: 'autocomplete',
      items: Object.values(status), // , 'Proposed', 'Dead'
      col: 1,
    },
  },
  stage: {
    label: 'Stage',
    grid: {},
    form: {
      type: 'autocomplete',
      items: Object.values(stage),
      col: 2,
    },
  },
  title: {
    label: 'Title',
    grid: {},
    form: {
      colBreak: true,
    },
  },
  email: {
    type: 'email',
    label: 'Email',
    grid: {},
  },
  phone: {
    label: 'Phone',
  },
  mobile: {
    label: 'Mobile',
  },
  fax: {
    label: 'Fax',
  },
  linkedIn: {
    label: 'LinkedIn',
    linkedIn: true,
  },
  owner: {
    label: 'Owner',
    grid: {
      minWidth: 125,
      hide: true,
    },
    form: {
      hide: true,
    },
  },
  dateLastActivity: {
    type: 'date',
    label: 'Latest Activity',
    grid: {
      minWidth: 140,
    },
    form: {
      hide: true,
    },
  },
  dateTransfer: {
    type: 'date',
    label: 'Latest Transfer',
    form: {
      hide: true,
    },
  },
  notes: {
    label: 'Notes',
    form: {
      type: 'textarea',
      colBreak: true,
      colWidth: 2,
      height: '60%',
    },
  },
  tags: {
    label: 'Tags',
    grid: {},
    form: {
      type: 'tag',
      height: '40%',
    },
  },
  createdBy: {
    label: 'Created By',
    form: {
      info: true,
    },
  },
  dateCreation: {
    label: 'Created On',
    type: 'date',
    form: {
      info: true,
    },
  },
  modifiedBy: {
    label: 'Modified By',
    form: {
      info: true,
    },
  },
  dateMod: {
    label: 'Modified On',
    type: 'date',
    time: true,
    form: {
      info: true,
    },
  },
};
