import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Col, Row, Dropdown } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from 'axios';
import moment from 'moment';
import { capitalize, cloneDeep, pick, last } from 'lodash';
import { PDFDocument, AcroFieldFlags } from 'pdf-lib';
import { saveAs } from 'file-saver';
import classNames from 'classnames';
import Crud from 'components/crud';
import Form from 'components/form';
import Tabs from 'components/tabs';
import Header from 'components/header';
import { alterSchema } from 'utils/schema';
import iconType from 'utils/iconType';
import { formatNumber } from 'utils/number';
import { isClient as _isClient } from 'services/auth';
import * as Contact from 'views/contact/config';
import * as Client from 'views/client/config';
import * as CampaignItem from 'views/campaignItem/config';
import * as CampaignMarket from 'views/campaignMarket/config';
import * as CampaignPlanGoogleDoc from 'views/campaignPlanGoogleDoc/config';
import * as CampaignStage from 'views/campaignStage/config';
import * as CampaignStageFlow from 'views/campaignStageFlow/config';
import Venue from 'views/venue/index';
import * as Job from 'views/job/config';
import * as RouteSheet from 'views/routeSheet/config';
import * as Activity from 'views/activity/config';
import * as GoogleDoc from 'views/googleDoc/config';
import Photo from 'views/photo/index';
import Attachment from 'views/attachment/index';
import { model, schema } from './config';
import 'styles/detail.scss';
import 'styles/map.scss';
import 'styles/google.scss';
import 'styles/views/campaign.scss';
import 'styles/views/venue.scss';
import agreementPdf from './agreement.pdf';
import invoicePdf from './invoice.pdf';
import * as Campaign from './config';

const PlanGoogleDocs = ({ data, docType }) => {
  const [ googleDocs, setGoogleDocs ] = useState(null);
  const [ planGoogleDoc, setPlanGoogleDoc ] = useState(null);
  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});

  const googleDocsApiPath = `${GoogleDoc.apiPath(docType)}&campaignId=${data.id}`;
  const planGoogleDocKey = `googleDoc${docType.label}Id`;
  useEffect(async () => {
    if (docType === GoogleDoc.type.ATTACHMENT) {
      return;
    }
    const { data: planGoogleDoc } = (await axios.get(`${CampaignPlanGoogleDoc.apiPath}?campaignId=${data.id}`)) || {};
    if (planGoogleDoc) {
      const planGoogleDocId = planGoogleDoc[planGoogleDocKey];
      // const { googleDocRateId, googleDocDeckId } = planGoogleDoc;
      if (planGoogleDocId) {
        const { data: { items: docs } } = await axios.get(googleDocsApiPath);
        setGoogleDocs(docs);
        const doc = docs.find((doc) => doc.id === planGoogleDocId);
        if (doc) {
          setPlanGoogleDoc(doc);
        }
      }
    }
  }, []);

  const onSelectDoc = (doc) => {
    axios.post(CampaignPlanGoogleDoc.apiPath, { campaignId: data.id, [planGoogleDocKey]: doc.id });
    setPlanGoogleDoc(doc);
  };

  return (
    <div className="google-doc">
      {!planGoogleDoc ? (
          <Crud
            apiPath={googleDocsApiPath}
            schema={{ ...GoogleDoc.schema, ...{ name: { ...GoogleDoc.schema.name, label: 'Doc' } } }}
            schemaParent={schema}
            model={GoogleDoc.model}
            modelParent={model}
            label={GoogleDoc.label}
            nested
            dataParent={data}
            clientSide
            readOnly
            onRowClick={(node) => onSelectDoc(node.data)}
            onInit={({ rowData: { items } }) => {
              setGoogleDocs(items);
              forceUpdate();
            }}
          />
        ) :
        <>
          <Header
            label={false}
            icons={[
              {
                type: iconType.EXPORT,
                onClick: () => window.open(planGoogleDoc.webContentLink || planGoogleDoc.exportLinks?.[docType.exportLink]),
                tooltip: 'Export Doc',
              },
              {
                type: iconType.LINK_EXTERNAL,
                onClick: () => window.open(planGoogleDoc.webViewLink),
                tooltip: 'View Doc',
              },
            ]}
          />
          <Dropdown className="file">
            <Dropdown.Toggle variant="outline-secondary">Change</Dropdown.Toggle>
            <Dropdown.Menu>
              {googleDocs.map((doc, i) => (
                <React.Fragment key={i}>
                  <Dropdown.Item
                    className={classNames({ active: doc.id === planGoogleDoc.id })}
                    onClick={() => {
                      onSelectDoc(doc);
                    }}
                  >
                    {doc.name}
                  </Dropdown.Item>
                </React.Fragment>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <iframe
            src={`${planGoogleDoc.webViewLink}&rm=minimal`}
            allowFullScreen
          />
        </>}
    </div>
  );
};

const Agreement = ({ data, schema, apiPath, campaignFormState }) => {
  const [ agreementFormState, setAgreementFormState ] = useState(null);
  const [ invoiceFormState, setInvoiceFormState ] = useState(null);
  const [ agreementSchema, setAgreementSchema ] = useState(null);
  const [ invoiceSchema, setInvoiceSchema ] = useState(null);

  useEffect(() => {
    setAgreementSchema(alterSchema(schema, { formFields: [ 'invoiceTotal', 'printingCost', 'commissionRate', 'commissionTotal', 'agreementText' ] }));
    setInvoiceSchema(alterSchema(schema, { formFields: [ 'date', 'invoiceNumber', 'invoicePaid', 'invoiceBalance', 'commissionPaid', 'invoiceText' ] }));
  }, []);
  if (!agreementSchema || !invoiceSchema) {
    return null;
  }

  agreementSchema.invoiceTotal.form.onSubmit = ({ updatedItem }) =>
    invoiceFormState.setFieldValue('invoiceBalance', updatedItem.invoiceBalance);

  const exportDoc = async (type) => {
    const campaignFormData = campaignFormState.values;
    const agreementFormData = agreementFormState.values;
    const invoiceFormData = invoiceFormState.values;

    let pdf;
    let fields;
    let formData;
    let fileLabel;
    if (type === 'agreement') {
      pdf = agreementPdf;
      fields = [ 'date', 'contact', 'agreementText' ];
      formData = agreementFormData;
      fileLabel = 'agmt';
    } else {
      pdf = invoicePdf;
      fields = [ 'date', 'invoiceNumber', 'contact', 'invoiceText', 'invoiceTotal', 'invoicePaid', 'invoiceBalance' ];
      formData = invoiceFormData;
      fileLabel = 'inv';
    }

    const [
      { data: billingContact },
      { data: primaryContact },
      { data: client },
    ] = await Promise.all([
      data.fk_billingContactId && axios.get(`${Contact.apiPath}/${data.fk_billingContactId}`),
      data.fk_primaryContactId && axios.get(`${Contact.apiPath}/${data.fk_primaryContactId}`),
      axios.get(`${Client.apiPath}/${data.fk_clientId}`),
    ]);
    const pdfDoc = await PDFDocument.load(await fetch(pdf).then((res) => res.arrayBuffer()));
    const pdfForm = pdfDoc.getForm();

    fields.forEach((key) => {
      const textField = pdfForm.getTextField(key);
      let value = formData[key];
      if (key === 'contact') {
        const contact = billingContact ? {
          ...billingContact,
          address: data.billingAddress,
          city: data.billingCity,
          state: data.billingState,
          zip: data.billingZip,
        } : {
          ...primaryContact,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zip,
        };
        value = contact && `${contact.nameFirst} ${contact.nameLast}\n${client.name}\n${contact.address ? `${contact.address}\n` : ''}${contact.city ? `${contact.city}, ${contact.state} ${contact.zip}` : ''}`;
      } else if (key === 'date') {
        value = moment(invoiceFormData.date).format('MMMM D, YYYY');
      } else if (key === 'invoiceNumber') {
        value = `Invoice # ${value}`;
      }
      if (value) {
        textField.setText(schema[key]?.type === 'price' ? `$${formatNumber(value)}` : value);
        textField.acroField.setFlag(AcroFieldFlags.ReadOnly, true);
      }
    });
    const pdfBlob = new Blob([await pdfDoc.save()], { type: 'application/pdf;charset=utf-8' });
    saveAs(pdfBlob, `${campaignFormData.title} ${fileLabel} ${moment().format('M-D-YYYY')}.pdf`);
  };

  agreementSchema.agreementText.form.icons = [{
    type: iconType.PEN,
    tooltip: 'Populate Document',
    onClick: async ({ values: agreementFormData, setFieldValue }) => {
      const campaignFormData = campaignFormState.values;
      const [
        { data: { items: campaignItems } },
        { data: { items: campaignMarkets } },
        // { data: client },
      ] = await Promise.all([
        axios.get(`${CampaignItem.apiPath}?campaignId=${data.id}`),
        axios.get(`${CampaignMarket.apiPath}?campaignId=${data.id}`),
        // axios.get(`${Client.apiPath}/${campaignFormData.fk_clientId}`),
      ]);
      const campaignItem = last(campaignItems);
      const weeksOnDisplay = Math.round(campaignFormData.daysOnDisplay / 7);

      const placement =
        campaignFormData.type?.toLowerCase().includes('wndwposter') ? '• street-visible placement: WNDWposters™ hang in front of establishments, on doors, windows & in vestibules' :
        campaignFormData.type?.toLowerCase().includes('brochure') ? '• high-visibility placement: Brochures displayed in front doorways & on front counters' :
        campaignFormData.type?.toLowerCase().includes('postcard') ? '• high-visibility placement: Postcards displayed in front doorways & on front counters' :
        campaignFormData.type?.toLowerCase().includes('magazine') ? '• high-visibility placement: Magazines displayed in front doorways & on front counters' : '';
      const timeframe =
        campaignFormData.flatRate ? '• Flat Rate, installed only [1-2 weeks on display]' :
        campaignFormData.type?.toLowerCase().includes('wndwposter') ? `• ${weeksOnDisplay} weeks on display from full install date` :
        campaignFormData.drops ? `• ${campaignFormData.drops} drop(s): on display until all taken` : '';

      const size =
        campaignFormData.type?.toLowerCase().includes('wndwposter') ? 'WNDWposter™ Maximum Size:\n14x22 inches (308 sq inches), held vertically/portrait orientation.\nOversized, non-vertical/portrait oriented posters may incur surcharge.' :
        campaignFormData.type?.toLowerCase().includes('brochure') ? 'Brochure Maximum Size/Weight:\n6x9 inches (54 sq inches), 80# cover stock, two folds maximum.\nOversized, overweight brochures may incur surcharge.' :
        campaignFormData.type?.toLowerCase().includes('postcard') ? 'Postcard Maximum Size/Weight:\n6x9 inches (54 sq inches), 80# cover stock, no folds.\nOversized, overweight postcards may incur surcharge.' : '';

      const terms = '';
      /* const terms =
        campaignFormData.terms === 'Net 15' ? 'Advertiser agrees to remit total cost net 15 days from installation start date.' :
        campaignFormData.terms === 'Net 30' ? 'Advertiser agrees to remit total cost net 30 days from installation start date.' :
        campaignFormData.terms === 'First Time Client' ? 'As a first time client, Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === 'Due Upon Receipt' ? 'Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === '50% Deposit Net 30' ? 'Advertiser agrees to remit 50% of total cost prior to installation start date, and balance net 30 days from installation start date.' : ''; */

      const text = `
${campaignFormData.title?.toUpperCase()}
${campaignFormData.type ?? ''}


• ${campaignItem?.distributionGoal?.toLocaleString()} ${campaignFormData.type}s

• targeting the neighborhoods of ${campaignMarkets.map(({ market: { name } }) => name).join(', ')}:
${campaignMarkets.map(({ neighborhoods }) => neighborhoods?.map(({ name }) => name)).flat().join(', ')} & adjacent areas if needed

${placement ? `${placement}\n` : ''}
• PMD Venue Network: Independently Owned restaurants, cafes, salons, boutiques & storefronts

• installation scheduled to begin the week of ${moment(campaignFormData.dateStart).format('dddd, MMMM D, YYYY')}

• full installation anticipated within ${campaignFormData.installDays} business days

${timeframe ? `${timeframe}\n\n\n` : ''}
E-REPORTS:
• name, type & address of every display venue
• pictures of ${campaignFormData.type}s on site
• interactive venue mapping by market [beta]



Print Production:

Display: ${campaignItem?.distributionGoal?.toLocaleString()} total ${campaignFormData.type}s, ${campaignMarkets.length} market${campaignMarkets.length !== 1 ? 's' : ''}, ${weeksOnDisplay} weeks on display @

Total Cost ... $${formatNumber(agreementFormData.invoiceTotal, 2)}

${size ? `${size}\n\n\n` : ''}


${terms}`;

      setFieldValue('agreementText', text.trim());

      /*
              Upper(D1_Campaign::CampaignTitle) & "¶" &
              Proper(D1_CampaignItemJoin::c_Type) & "¶¶¶" &


              "• " & Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal)  & " " & D1_CampaignItemJoin::c_Type & "s" & "¶¶" &
              "• targeting the neighborhoods of " & D1_Campaign::c_Market & ":" & "¶" &
              Substitute (List(D1_CampaignMarketJoin::FK_Neighborhood); "¶"; ", ") & " & adjacent areas if needed" & "¶¶" &


              Case (PatternCount(D1_CampaignItemJoin::c_Type; "windowposter"); "• street-visible placement:  Windowposters™ hang in front of establishments, on doors, windows & in vestibules";
              PatternCount(D1_CampaignItemJoin::c_Type; "brochure"); "• high-visibility placement:  Brochures displayed in front doorways & on front counters";
              PatternCount(D1_CampaignItemJoin::c_Type; "postcard"); "• high-visibility placement: Postcards displayed in front doorways & on front counters";
              PatternCount(D1_CampaignItemJoin::c_Type; "aerplay"); "• in-store play:  Aerplay CDs are played for customers to hear";
              PatternCount(D1_CampaignItemJoin::c_Type; "magazine"); "• high-visibility placement:  Magazines displayed in front doorways & on front counters") & "¶¶" &

              "• PMD Venue Network: Independently Owned restaurants, cafes, salons, boutiques & storefronts" & "¶¶" &

              "• installation scheduled to begin the week of " & DayName ( D1_Campaign::DateStart ) & ", " & MonthName ( D1_Campaign::DateStart ) & " " & Day ( D1_Campaign::DateStart ) & ", " & Year ( D1_Campaign::DateStart ) & "¶¶" &

              "• full installation anticipated within " & D1_Campaign::InstallDays & " business days" & "¶¶" &

              Case (
                D1_Campaign::f_FlatRate = 1; "• Flat Rate, installed only [1-2 weeks on display]";
              PatternCount(D1_CampaignItemJoin::c_Type; "windowposter"); "• " & GetAsText (D1_Campaign::c_WeeksonDisplay) & " weeks on display from full install date";
              PatternCount(D1_CampaignItemJoin::c_Type; "aerplay"); "• " & D1_Campaign::AerplayWeeks & "play week(s)";
              "• " & D1_Campaign::Drops & " drop(s): on display until all taken") & "¶¶¶¶" &

              "E-REPORTS: " & "¶" &
              "• name, type & address of every display venue" & "¶" &
              "• pictures of " & Proper(D1_CampaignItemJoin::c_Type) & "s on site" & "¶" &
              "• interactive venue mapping by market [beta]" & "¶¶¶¶" &

              "Print Production: " & "¶¶" &

              "Display: " & Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal)  & " total " & Proper(D1_CampaignItemJoin::c_Type) & "s, " & D1_Campaign::c_CountMarket & " market(s)," & "¶" &
              D1_Campaign::c_WeeksonDisplay & " weeks on display @ $" & "¶¶" &

              "Total Cost ... $" & D1_Campaign::InvoiceTotal & "¶¶" &

              TextSize(
                Case (
                  PatternCount(D1_CampaignItemJoin::c_Type; "windowposter"); "Windowposter™ Maximum Size:" & "¶" & "14x22 inches (308 sq inches), held vertically/portrait orientation." & "¶" & "Oversized, non-vertical/portrait oriented posters may incur surcharge." & "¶¶";
              PatternCount(D1_CampaignItemJoin::c_Type; "brochure"); "Brochure Maximum Size/Weight:" & "¶" & "6x9 inches (54 sq inches), 80# cover stock, two folds maximum." & "¶" & "Oversized, overweight brochures may incur surcharge." & "¶¶";
              PatternCount(D1_CampaignItemJoin::c_Type; "postcard"); "Postcard Maximum Size/Weight:" & "¶" & "6x9 inches (54 sq inches), 80# cover stock, no folds." & "¶" & "Oversized, overweight postcards may incur surcharge." & "¶¶"
            ) &
              D1_Campaign::c_Terms; 7)
      */
    },
  }, {
    type: iconType.EXPORT,
    onClick: async () => exportDoc('agreement'),
  }];

  invoiceSchema.invoiceText.form.icons = [{
    type: iconType.PEN,
    tooltip: 'Populate Document',
    onClick: async ({ values: invoiceFormData, setFieldValue }) => {
      const campaignFormData = campaignFormState.values;
      const [
        { data: { items: campaignItems } },
        { data: { items: campaignMarkets } },
        // { data: client },
      ] = await Promise.all([
        axios.get(`${CampaignItem.apiPath}?campaignId=${data.id}`),
        axios.get(`${CampaignMarket.apiPath}?campaignId=${data.id}`),
      ]);
      const campaignItem = last(campaignItems);
      const weeksOnDisplay = Math.round(campaignFormData.daysOnDisplay / 7);

      const agreementFormData = agreementFormState.values;
      const text = `
please make check payable to:
PMD Promotion
19 West 21st Street, Suite 901
New York, NY  10010






• ${campaignItem?.distributionGoal?.toLocaleString()} ${campaignFormData.type}s
• targeting the neighborhoods of ${campaignMarkets.map(({ market: { name } }) => name).join(', ')}
• installation scheduled to begin the week of ${moment(campaignFormData.dateStart).format('dddd, MMMM D, YYYY')}

${campaignItem?.distributionGoal?.toLocaleString()} total ${campaignFormData.type}s, ${campaignMarkets.length} market${campaignMarkets.length !== 1 ? 's' : ''}, ${weeksOnDisplay} weeks on display @

Total Cost ... $${formatNumber(agreementFormData.invoiceTotal, 2)}
`;

      setFieldValue('invoiceText', text.trim());

      const terms =
        campaignFormData.terms === 'Net 15' ? 'Advertiser agrees to remit total cost net 15 days from installation start date.' :
        campaignFormData.terms === 'Net 30' ? 'Advertiser agrees to remit total cost net 30 days from installation start date.' :
        campaignFormData.terms === 'First Time Client' ? 'As a first time client, Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === 'Due Upon Receipt' ? 'Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === '50% Deposit Net 30' ? 'Advertiser agrees to remit 50% of total cost prior to installation start date, and balance net 30 days from installation start date.' : '';
      setFieldValue('invoiceTerms', terms);

      /* "please make check payable to:¶PMD Promotion¶19 West 21st Street, Suite 901¶New York, NY  10010¶¶¶¶¶¶¶" &

      "• " & Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal) & " " & Upper(D1_Campaign::CampaignTitle) & " " & Proper(D1_CampaignItemJoin::c_Type) & "s" & "¶" &
      "• targeting the neighborhoods of " & D1_Campaign::c_Market & "¶" &
      "• installation scheduled to begin the week of " & DayName (D1_Campaign::DateStart ) & ", " & MonthName ( D1_Campaign::DateStart ) & " " & Day ( D1_Campaign::DateStart ) & ", " & Year ( D1_Campaign::DateStart ) & "¶¶" &

      Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal)  & " total " & Proper(D1_CampaignItemJoin::c_Type) & "s, " & D1_Campaign::c_CountMarket & " market(s)," & "¶" &
      D1_Campaign::c_WeeksonDisplay & " weeks on display @ $" & "¶¶" &

      "Total Cost ... $" & D1_Campaign::InvoiceTotal */
    },
  }, {
    type: iconType.EXPORT,
    onClick: async () => exportDoc('invoice'),
  }];

  return (
    <div>
      <Row>
        <Col>
          <Form
            apiPath={apiPath}
            schema={agreementSchema}
            data={data}
            getState={(state) => setAgreementFormState(state)}
          />
        </Col>
        <Col>
          <Form
            apiPath={apiPath}
            schema={invoiceSchema}
            data={data}
            getState={(state) => setInvoiceFormState(state)}
          />
        </Col>
      </Row>
    </div>
  );
};

const CampaignDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit, nested }) => {
  if (!nested) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  const isClient = _isClient();

  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});
  const [ campaignFormState, setCampaignFormState ] = useState(null);

  const schemaCampaignContact = (type) => {
    const typeKey = (key) => type === 'billing' ? `${type}${capitalize(key)}` : key;
    const fields = cloneDeep(pick(schema, [
      ...[ `fk_${type}ContactId`, 'id' ],
      ...[ 'email', 'phone', 'mobile', 'fax', 'address', 'city', 'state', 'zip' ].map((key) => typeKey(key)),
    ]));

    return Object.keys(fields).reduce((acc, key, i) => ({
      ...acc,
      [key]: {
        ...fields[key],
        form: {
          ...fields[key].form,
          hide: !!fields[key].primaryKey,
          ...i === 0 ? {
            onChange: async ({ item, setFieldValue }) => {
              if (item) {
                const { data: contact } = await axios.get(`${Contact.apiPath}/${item.value}`);
                [ 'email', 'phone', 'mobile', 'fax' ].forEach((key) => setFieldValue(typeKey(key), contact[key]));
              }
            },
          } : {
            readOnly: true,
          },
        },
      },
    }), {});
  };

  return (
    <div className="detail campaign">
      <Form
        apiPath={apiPath}
        schema={modelParent === Client.model ? alterSchema(schema, { formFieldsHide: ['fk_clientId'] }) :
                isClient ? alterSchema(schema, { formFieldsInfo: [ 'title', 'type', 'status', 'stage', 'dateStart', 'dateEnd', 'dateFullInstall', 'dateFinalEnd', 'weeksOnDisplay', 'periodsOnDisplay', 'installDaysAct' ], formFieldsInfoOnly: true }) : schema}
        data={data}
        readOnly={isClient}
        dataDefault={!data && (
          modelParent === Client.model ? {
            fk_clientId: dataParent.id,
          } :
          modelParent === Contact.model ? {
            fk_primaryContactId: dataParent.id,
            email: dataParent.email,
            phone: dataParent.phone,
            mobile: dataParent.mobile,
            fax: dataParent.fax,
            /* address: dataParent.address,
            city: dataParent.city,
            state: dataParent.state,
            zip: dataParent.zip, */
            fk_clientId: dataParent.fk_clientId,
            } :
          null
        )}
        onSubmit={onSubmit}
        getState={(state) => setCampaignFormState(state)}
      />
      {data && !isClient && <Tabs
        tabs={[
          {
            key: 'contact',
            title: 'Contacts',
            content:
              <div>
                <Row>
                  <Col>
                    <Form
                      apiPath={apiPath}
                      schema={schemaCampaignContact('primary')}
                      data={data}
                    />
                  </Col>
                  <Col>
                    <Form
                      apiPath={apiPath}
                      schema={schemaCampaignContact('billing')}
                      data={data}
                    />
                  </Col>
                </Row>
              </div>,
          },
          {
            key: 'item',
            title: 'Items',
            content:
              <Crud
                apiPath={CampaignItem.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(CampaignItem.schema, { gridCols: [ 'item.name', 'distributionGoal', 'venuesAlltime', 'upCurrent', 'distributionAudit', 'dateStart', 'dateInitialPosting', 'dateEnd' ] })}
                schemaParent={schema}
                routePath={CampaignItem.routePath}
                label={CampaignItem.label}
                nested
                dataParent={data}
                model={CampaignItem.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'market',
            title: 'Markets',
            content:
              <Crud
                apiPath={CampaignMarket.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(CampaignMarket.schema, { gridCols: [ 'market.name', 'neighborhoods' ] })}
                schemaParent={schema}
                routePath={CampaignMarket.routePath}
                label={CampaignMarket.label}
                nested
                showDelete
                dataParent={data}
                model={CampaignMarket.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'job',
            title: 'Jobs',
            content:
              <Crud
                apiPath={Job.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(Job.schema, { gridCols: [ 'id', 'item.name', 'campaignMarket.market.name', 'distributionGoal', 'venuesAlltime', 'upCurrent', 'distributionAudit', 'dateStart' ] })}
                schemaParent={schema}
                routePath={Job.routePath}
                label={Job.label}
                nested
                dataParent={data}
                model={Job.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'sheet',
            title: 'Sheets',
            content:
              <Crud
                apiPath={RouteSheet.apiPath}
                apiParams={{ distributionRoute: true, campaignId: id }}
                schema={alterSchema(RouteSheet.schema, { gridCols: [ 'id', 'date', 'fk_routeId', 'route.neighborhood.name', 'rep.fullName', 'photosProcessedBy', 'status', 'type', 'distributionAudit' ] })}
                schemaParent={schema}
                routePath={RouteSheet.routePath}
                label={RouteSheet.label}
                nested
                dataParent={data}
                model={RouteSheet.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'activity',
            title: 'Activities',
            content:
              <Crud
                apiPath={Activity.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(Activity.schema, { gridColsOrder: Activity.nestedGridCols })}
                schemaParent={schema}
                routePath={Activity.routePath}
                label={Activity.label}
                nested
                dataParent={data}
                model={Activity.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'plan',
            title: 'Plan',
            content:
              <Tabs
                tabs={[
                  {
                    key: 'venues',
                    title: 'Venues',
                    content:
                      <Venue
                        dataParent={data}
                        modelParent={model}
                      />,
                  },
                  {
                    key: 'rates',
                    title: 'Rates',
                    content:
                      <PlanGoogleDocs
                        data={data}
                        docType={GoogleDoc.type.RATE}
                      />,
                  },
                  {
                    key: 'deck',
                    title: 'Deck',
                    content:
                      <PlanGoogleDocs
                        data={data}
                        docType={GoogleDoc.type.DECK}
                      />,
                  },
                ]}
              />,
          },
          {
            key: 'agreement',
            title: 'Agreement',
            content:
              <Agreement
                data={data}
                schema={schema}
                apiPath={apiPath}
                campaignFormState={campaignFormState}
              />,
          },
          {
            key: 'photo',
            title: 'Photos',
            content:
              <Photo
                dataParent={data}
                modelParent={model}
              />,
          },
          {
            key: 'venue',
            title: 'Venues',
            content:
              <Venue
                dataParent={data}
                modelParent={model}
                apiParams={{ distributionVenue: true }}
                showAdd={false}
                showMap={false}
              />,
          },
          {
            key: 'attachments',
            title: 'Attachments',
            content:
              <Attachment
                schemaParent={schema}
                modelParent={model}
                dataParent={data}
              />,
          },
          {
            key: 'flow',
            title: 'Flow',
            content:
              <Crud
                apiPath={CampaignStage.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(CampaignStage.schema, {
                    gridColsShow: [ 'flow.notes', 'flow.completedBy.fullName', 'flow.completedDate' ],
                    gridColsHide: [ 'status', 'number', 'position' ],
                    formFieldsShow: ['flow.notes'],
                    formFieldsHide: [ 'status', 'number', 'position' ],
                    formFieldsInfo: [ 'stage', 'team' ],
                  })}
                schemaParent={schema}
                routePath={CampaignStage.routePath}
                label={CampaignStage.label}
                nested
                dataParent={data}
                model={CampaignStage.model}
                modelParent={model}
                checkSelect
                showAdd={false}
                checkSelectedField="flow.completedDate"
                onCheckSelected={(node) => CampaignStageFlow.onSelected({ node, dataParent: data })}
                // exportData: (rowData) => rowData?.filter((item) => item.campaignPlanVenue),
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
        ]}
      />}
    </div>
  );
};

export default CampaignDetail;
