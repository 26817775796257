import React from 'react';
import * as Route from 'services/route';
import * as ActivityType from 'views/activityType/config';
import * as Client from 'views/client/config';
import * as Contact from 'views/contact/config';
import * as Campaign from 'views/campaign/config';
import * as Vendor from 'views/vendor/config';
import * as Venue from 'views/venue/config';
import * as User from 'views/user/config';

export const model = 'activity';
export const label = 'Activity';
export const apiPath = '/activities';
export const routePath = () => Route.paths.activities.activities;
export const nestedGridCols = [ 'date', 'subject', 'description', 'nature' ];
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  status: {
    label: 'Status',
    grid: {
      minWidth: 75,
    },
    form: {
      type: 'autocomplete',
      items: [ 'Open', 'Closed' ],
      defaultValue: 'Open',
    },
  },
  nature: {
    label: 'Type',
    required: true,
    grid: {
      minWidth: 100,
    },
    form: {
      type: 'autocomplete',
      labelKey: 'type',
      itemsUrl: () => ActivityType.apiPath,
      freeForm: true,
    },
  },
  fk_clientId: {
    label: 'Company',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Client.apiPath,
      onChange: async ({ item, fields, setFieldValue }) => {
        if (item) {
          await fields.find(({ key }) => key === 'fk_contactId').form.initItems({ fk_clientId: item.value });
          setFieldValue('fk_contactId', '');
        }
      },
      detail: () => ({
        key: 'client.id',
        ...Client,
      }),
      colBreak: true,
    },
  },
  'client.name': {
    label: 'Company',
    grid: {
      minWidth: 150,
      detail: () => ({
        key: 'client.id',
        ...Client,
      }),
    },
    form: {
      hide: true,
    },
  },
  fk_clientId2: {
    label: 'Company #2',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Client.apiPath,
      onChange: async ({ item, fields, setFieldValue }) => {
        if (item) {
          await fields.find(({ key }) => key === 'fk_contactId2').form.initItems({ fk_clientId2: item.value });
          setFieldValue('fk_contactId2', '');
        }
      },
      detail: () => ({
        key: 'client.id',
        ...Client,
      }),
      labelEmpty: 'Company',
    },
  },
  fk_clientId3: {
    label: 'Company #3',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Client.apiPath,
      onChange: async ({ item, fields, setFieldValue }) => {
        if (item) {
          await fields.find(({ key }) => key === 'fk_contactId3').form.initItems({ fk_clientId3: item.value });
          setFieldValue('fk_contactId3', '');
        }
      },
      detail: () => ({
        key: 'client.id',
        ...Client,
      }),
      labelEmpty: 'Company',
    },
  },
  fk_clientId4: {
    label: 'Company #4',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Client.apiPath,
      onChange: async ({ item, fields, setFieldValue }) => {
        if (item) {
          await fields.find(({ key }) => key === 'fk_contactId4').form.initItems({ fk_clientId4: item.value });
          setFieldValue('fk_contactId4', '');
        }
      },
      detail: () => ({
        key: 'client.id',
        ...Client,
      }),
      labelEmpty: 'Company',
    },
  },
  fk_contactId: {
    label: 'Contact',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: (data) => data?.fk_clientId ? `${Contact.apiPath}?clientId=${data.fk_clientId}` : null,
      colBreak: true,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
    },
  },
  fk_contactId2: {
    label: 'Contact #2',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: (data) => data?.fk_clientId2 ? `${Contact.apiPath}?clientId=${data.fk_clientId2}` : null,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
      labelEmpty: 'Contact',
    },
  },
  fk_contactId3: {
    label: 'Contact #3',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: (data) => data?.fk_clientId3 ? `${Contact.apiPath}?clientId=${data.fk_clientId3}` : null,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
      labelEmpty: 'Contact',
    },
  },
  fk_contactId4: {
    label: 'Contact #4',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: (data) => data?.fk_clientId4 ? `${Contact.apiPath}?clientId=${data.fk_clientId4}` : null,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
      labelEmpty: 'Contact',
    },
  },
  fk_campaignId: {
    label: 'Campaign',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'title',
      itemsUrl: () => Campaign.apiPath,
      colBreak: true,
      detail: () => ({
        key: 'campaign.id',
        ...Campaign,
      }),
    },
  },
  fk_vendorId: {
    label: 'Vendor',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Vendor.apiPath,
      onChange: async ({ item, fields, setFieldValue }) => {
        if (item) {
          await fields.find(({ key }) => key === 'fk_vendorContactId').form.initItems({ fk_vendorId: item.value });
          setFieldValue('fk_vendorContactId', '');
        }
      },
      detail: () => ({
        key: 'vendor.id',
        ...Vendor,
      }),
      col: 1,
    },
  },
  fk_vendorContactId: {
    label: 'Contact',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: (data) => data?.fk_vendorId ? `${Contact.apiPath}?vendorId=${data.fk_vendorId}` : null,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
      col: 2,
    },
  },
  fk_venueId: {
    label: 'Venue',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => null,
      // itemsUrl: () => `${Venue.apiPath}?active=true`,
      onChange: async ({ item, fields, setFieldValue }) => {
        if (item) {
          await fields.find(({ key }) => key === 'fk_venueContactId').form.initItems({ fk_venueId: item.value });
          setFieldValue('fk_venueContactId', '');
        }
      },
      detail: () => ({
        key: 'venue.id',
        ...Venue,
      }),
      col: 1,
    },
  },
  fk_venueContactId: {
    label: 'Contact',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: (data) => data?.fk_venueId ? `${Contact.apiPath}?venueId=${data.fk_venueId}` : null,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
      col: 2,
    },
  },
  fk_userId: {
    label: 'User',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'fullName',
      itemsUrl: User.apiPath,
    },
  },
  date: {
    label: 'Date',
    type: 'date',
    time: true,
    required: true,
    grid: {
      minWidth: 125,
    },
    form: {
      info: true,
    },
  },
  subject: {
    label: 'Subject',
    grid: {
      minWidth: 200,
    },
    form: {
      rowBreak: true,
    },
  },
  description: {
    label: 'Notes',
    required: true,
    grid: {
      sortable: false,
      minWidth: 350,
    },
    form: {
      type: 'textarea',
      height: 400,
      rowBreak: true,
    },
  },
  'createdBy.fullName': {
    label: 'Created By',
    grid: {
      minWidth: 125,
    },
    form: {
      info: true,
    },
  },
};
