import React from 'react';
import Crud from 'components/crud';
import { apiPath, schema, model, label } from './config';

const ClientType = () => {
  return (
    <Crud
      apiPath={apiPath}
      schema={schema}
      model={model}
      label={label}
      readOnly
    />
  );
};

export default ClientType;
