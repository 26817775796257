import { paths } from 'services/route';
import * as Market from 'views/market/config';

export const model = 'neighborhood';
export const label = 'Neighborhood';
export const apiPath = '/neighborhoods';
export const routePath = () => paths.admin.neighborhoods;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  name: {
    label: 'Neighborhood',
    required: true,
    grid: {},
  },
  fk_marketId: {
    label: 'Market',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Market.apiPath,
    },
  },
  'market.name': {
    label: 'Market',
    grid: {},
    form: {
      hide: true,
    },
  },
};
