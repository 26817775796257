import React from 'react';
import Crud from 'components/crud';
import { apiPath, schema, model, label, routePath } from './config';

const Client = () => {
  return (
    <Crud
      apiPath={apiPath}
      schema={schema}
      model={model}
      label={label}
      showDelete
      routePath={routePath}
    />
  );
};

export default Client;
