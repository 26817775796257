export const model = 'attachment';
export const label = 'Attachments';
export const apiPath = '/attachments';
export const schema = {
  id: {
    type: 'string',
    label: 'ID',
    required: true,
    primaryKey: true,
  },
  name: {
    label: 'Name',
    grid: {
      minWidth: '400',
    },
  },
  // 'lastModifyingUser.displayName': {
  //   label: 'Modified By',
  //   grid: {},
  // },
  modifiedTime: {
    label: 'Modified On',
    type: 'date',
    grid: {},
  },
};
