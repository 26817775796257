import React, { useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { Row, Col, Alert, Dropdown } from 'react-bootstrap';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import axios from 'axios';
import PQueue from 'p-queue';
import { PDFDocument, PDFString, PDFName, ImageAlignment, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { chunk, orderBy, debounce, range, truncate } from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faSquare, faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons';
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import { WindowScroller, AutoSizer, List } from 'react-virtualized';
import { isBA as _isBA, isClient as _isClient } from 'services/auth';
import Crud from 'components/crud';
import Spinner from 'components/spinner';
import Modal from 'components/modal';
import Header from 'components/header';
import Pipeline from 'components/pipeline';
import iconType from 'utils/iconType';
import { alterSchema } from 'utils/schema';
import { loadImage } from 'utils/image';
import * as Venue from 'views/venue/config';
import * as Photo from 'views/photo/config';
import * as Job from 'views/job/config';
import * as CampaignReportPhoto from 'views/campaignReportPhoto/config';
import { apiPath, schema, model, label, routePath, status, stage, stageInProgress } from './config'; // eslint-disable-line
import montserratBoldFont from 'fonts/Montserrat-Bold.ttf';
import montserratSemiBoldFont from 'fonts/Montserrat-SemiBold.ttf';
import montserratRegularFont from 'fonts/Montserrat-Regular.ttf';
import reportPdf from './report.pdf';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);
ChartJS.defaults.font.family = 'Montserrat';

const ReportSelect = ({ detailData, isReportSelectShowing, setIsReportSelectShowing } = {}) => {
  const isClient = _isClient();
  const [ reportSelectJobs, setReportSelectJobs ] = useState([]);
  const [ reportSelectJobActive, setReportSelectJobActive ] = useState(null);
  const reportSelectPhotosRef = useRef(null);
  const [ reportSelectPhotos, setReportSelectPhotos ] = useState(null);
  const [ reportSelectPhotoData, setReportSelectPhotoData ] = useState({});
  const [ reportSelectPhotoModal, setReportSelectPhotoModal ] = useState(null);
  const reportSelectPhotoRef = useRef(null);
  const [ reportSelectPhotosActive, setReportSelectPhotosActive ] = useState([]);
  const reportSelectPhotosActiveRef = useRef(null);
  const [ reportSelectPhotoExportCounter, setReportSelectPhotoExportCounter ] = useState(null);
  const [ reportSelectChartData, setReportSelectChartData ] = useState(null);
  const [ reportSelectChartPhotoCountTotal, setReportSelectChartPhotoCountTotal ] = useState(null);
  const reportSelectChartRef = useRef(null);
  const reportSelectChartLegendRef = useRef(null);

  useEffect(async () => {
    const { data: { items: jobs } } = await axios.get(`${Job.apiPath}/?campaignId=${detailData.id}`);
    setReportSelectJobs(jobs);
    setReportSelectJobActive(jobs[0]);
  }, []);

  const queue = new PQueue({ concurrency: 10 });
  const fetchPhoto = async ({ photo, mounted = true }) => {
    const id = photo.id;
    if (photo.googleCloudUrl) {
      await queue.add(() => mounted && fetch(photo.googleCloudUrl, { cache: 'no-cache' })
        .then((response) => response.blob())
        .then((blob) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            photo.photo = reader.result.split(',')[1];
            resolve();
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })));
    } else {
      ({ data: photo } = await queue.add(() => mounted ? axios.get(`${Photo.apiPath}/${id}?fields=id,photo,date,time,flagApproved`) : {}));
    }

    if (mounted) {
      reportSelectPhotoData[id] = photo;
      setReportSelectPhotoData({
        ...reportSelectPhotoData,
      });
    }
    return photo;
  };

  useEffect(() => {
    let mounted = true;
    if (reportSelectJobActive) {
      (async () => {
        const venues = [];
        const { data: { items: photos } } = await axios.get(`${Photo.apiPath}/?campaignId=${detailData.id}&jobId=${reportSelectJobActive.id}`);
        const { data: { items: photosSaved } } = await axios.get(`${CampaignReportPhoto.apiPath}/?campaignId=${detailData.id}&jobId=${reportSelectJobActive.id}`);
        let photosActive = orderBy(photos.map((photo) => ({
          ...photo,
          position: photosSaved.find((photoSaved) => photoSaved.fk_photoId === photo.id)?.position,
        })).filter(({ position }) => typeof position !== 'undefined'), [ 'position', 'asc' ]);
        setReportSelectPhotosActive(photosActive);

        await Promise.all(photosActive.map(async (photo) => {
          const id = photo.id;
          photo = await fetchPhoto({ photo, mounted });
          const photoSaved = photosSaved.find((photoSaved) => photoSaved.fk_photoId === id);
          if (photoSaved) {
            photosActive = photosActive.filter((photoActive) => photoActive.id !== id);
            photosActive.push({
              ...photo,
              position: photoSaved.position,
              ...photoSaved.width && {
                crop: {
                  width: photoSaved.width,
                  height: photoSaved.height,
                  x: photoSaved.x,
                  y: photoSaved.y,
                },
                scale: photoSaved.scale,
              },
            });
            setReportSelectPhotosActive(orderBy(photosActive, [ 'position', 'asc' ]));
          }
        })); // fetch active photos first
        photos.map(async (photo) => {
          const venue = photo.venue;
          if (!venues.some(({ id }) => venue.id === id)) {
            venues.push(venue);
            venue.photos = photos.filter((photo) => photo.venue.id === venue.id);
          }
        });
        setReportSelectPhotos(venues.reduce((acc, val) =>
          [ ...acc, ...val.photos.map((photo, i) => ({ ...photo, venuePhotoIndex: i })) ], []));

        const neighborhoods = venues.reduce((acc, venue) => {
          if (venue.neighborhood) {
            const neighborhood = venue.neighborhood.name;
            acc[neighborhood] = {
              zipCodes: [
                ...new Set([
                  ...acc[neighborhood]?.zipCodes || [],
                  venue.zip && venue.zip.trim(),
                ].filter(Boolean)),
              ],
              photoCount: (acc[neighborhood]?.photoCount || 0) + venue.photos.length,
            };
          }
          return acc;
        }, {});
        const chartData = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
              borderWidth: 0,
            },
          ],
        };
        let chartPhotoCountTotal = 0;
        const chartColors = [
          'rgba(12, 36, 97,1.0)',
          'rgba(229, 142, 38,1.0)',
          'rgba(7, 153, 146,1.0)',
          'rgba(30, 55, 153,1.0)',
          'rgba(229, 80, 57,1.0)',
          'rgba(60, 99, 130,1.0)',
          'rgba(120, 224, 143,1.0)',
          'rgba(183, 21, 64,1.0)',
          'rgba(74, 105, 189,1.0)',
          'rgba(250, 152, 58,1.0)',
          'rgba(106, 137, 204,1.0)',
          'rgba(250, 211, 144,1.0)',
          'rgba(10, 61, 98,1.0)',
          'rgba(235, 47, 6,1.0)',
          'rgba(246, 185, 59,1.0)',
          'rgba(96, 163, 188,1.0)',
          'rgba(248, 194, 145,1.0)',
          'rgba(56, 173, 169,1.0)',
          'rgba(184, 233, 148,1.0)',
          'rgba(130, 204, 221,1.0)',
        ];
        Object.keys(neighborhoods).forEach((name, i) => {
          const { photoCount, zipCodes } = neighborhoods[name];
          chartData.labels.push(`${name}: ${zipCodes.join(', ')}`);
          chartData.datasets[0].data.push(photoCount);
          chartPhotoCountTotal += photoCount;
          chartData.datasets[0].backgroundColor.push(chartColors[i]);
        });
        setReportSelectChartPhotoCountTotal(chartPhotoCountTotal);
        setReportSelectChartData(chartData);
      })();
    }

    return () => {
      mounted = false;
    };
  }, [reportSelectJobActive]);

  const generateReportPdf = async () => {
    setReportSelectPhotoExportCounter(null);
    const pdfDoc = await PDFDocument.load(await fetch(reportPdf).then((res) => res.arrayBuffer()));
    const pdfForm = pdfDoc.getForm();
    const pdfLastPage = pdfDoc.getPageCount() - 1;
    pdfDoc.registerFontkit(fontkit);
    const montserratBoldFontBytes = await fetch(montserratBoldFont).then((res) => res.arrayBuffer());
    const montserratSemiBoldFontBytes = await fetch(montserratSemiBoldFont).then((res) => res.arrayBuffer());
    const montserratRegularFontBytes = await fetch(montserratRegularFont).then((res) => res.arrayBuffer());

    // [
    //   { key: 'campaignTitle', value: detailData.title },
    // ].forEach(({ key, value }) => {
    //   const textField = pdfForm.getTextField(key);
    //     textField.setText(value);
    // });
    const textField = pdfForm.getTextField('chartTitle');
    textField.setText(`${detailData.title} - ${reportSelectJobActive?.campaignMarket.market.name} - Neighborhood, Zips`);
    textField.defaultUpdateAppearances(await pdfDoc.embedFont(montserratBoldFontBytes, { subset: true }));
    pdfForm.getButton('chartImage').setImage(await pdfDoc.embedPng(reportSelectChartRef.current.toBase64Image('image/png')));
    const chartLegend = await html2canvas(reportSelectChartLegendRef.current, { backgroundColor: null });
    pdfForm.getButton('chartLegendImage').setImage(await pdfDoc.embedPng(chartLegend.toDataURL('image/png')));

    const reportPages = [];
    const reportPhotos = new JSZip();
    await Promise.all(chunk(reportSelectPhotosActive, 1).map(async (photos) => {
      const saved = await pdfDoc.saveAsBase64();
      const pdfDocPhotoPage = await PDFDocument.load(saved);
      const pdfFormPhotoPage = pdfDocPhotoPage.getForm();
      pdfDocPhotoPage.registerFontkit(fontkit);
      // pdfFormPhotoPage.getTextField('jobName').setText(`${reportSelectJobActive?.item.name} / ${reportSelectJobActive?.campaignMarket.market.name}`);

      await Promise.all(photos.map(async ({ photo, crop, scale, venue, position }, i) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = await loadImage(photo);
        crop = convertToPixelCrop({ ...crop, unit: '%' }, image.naturalWidth, image.naturalHeight);
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const pixelRatio = window.devicePixelRatio;
        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = 'high';
        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;
        ctx.save();
        ctx.translate(-cropX, -cropY);
        ctx.translate(centerX, centerY);
        ctx.scale(scale, scale);
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
        );
        ctx.restore();

        const photoData = canvas.toDataURL('image/jpeg', 0.25).split(';base64,')[1];
        await reportPhotos.file(`${detailData.title} - ${reportSelectJobActive?.campaignMarket.market.name} - ${position + 1}.jpg`, photoData, { base64: true });
        const photoField = pdfFormPhotoPage.getButton('photo');
        photoField.setImage(await pdfDocPhotoPage.embedJpg(photoData), ImageAlignment.Left);

        const campaignTitleField = pdfFormPhotoPage.getTextField('campaignTitle');
        campaignTitleField.setText(detailData.title);
        campaignTitleField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratBoldFontBytes, { subset: true }));

        const venueNameField = pdfFormPhotoPage.getTextField('venueName');
        venueNameField.setText(venue.name);
        venueNameField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));
        const venueTypeField = pdfFormPhotoPage.getTextField('venueType');
        venueTypeField.setText(venue.type?.type || '-');
        venueTypeField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));

        const venueNeighborhoodField = pdfFormPhotoPage.getTextField('venueNeighborhood');
        venueNeighborhoodField.setText(venue.neighborhood?.name ? `${venue.neighborhood.name}` : '-');
        venueNeighborhoodField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));

        const venueLocationField = pdfFormPhotoPage.getTextField('venueLocation');
        venueLocationField.setText(`${venue.city}, ${venue.state} ${venue.zip}`);
        venueLocationField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));

        setReportSelectPhotoExportCounter(position + 1);
      }));
      /* if (photos.length === 1) {
        pdfFormPhotoPage.removeField(pdfFormPhotoPage.getButton('photo2'));
        pdfFormPhotoPage.removeField(pdfFormPhotoPage.getTextField('location2'));
      } */

      const venue = photos[0].venue;
      const venueLocationRect = pdfFormPhotoPage.getTextField('venueLocation').acroField.getWidgets()[0].getRectangle();

      pdfFormPhotoPage.flatten();
      const [photoPage] = await pdfDoc.copyPages(pdfDocPhotoPage, [pdfLastPage]);

      const link = photoPage.doc.context.register(
        photoPage.doc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [ venueLocationRect.x, venueLocationRect.y - 20, venueLocationRect.x + venueLocationRect.width, venueLocationRect.y + venueLocationRect.height ],
          Border: [ 0, 0, 0 ],
          C: [ 0, 0, 0 ],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(venue.googlePlaceId ? `https://www.google.com/maps/place/?q=place_id:${venue.googlePlaceId}` : `https://maps.google.com/?q=${encodeURIComponent(`${venue.name},${venue.streetNumber} ${venue.streetName},${venue.city},${venue.state}`)}`),
          },
        }),
      );
      photoPage.node.set(PDFName.of('Annots'), pdfDoc.context.obj([link]));

      reportPages.push(photoPage);
      // pdfDoc.addPage(copied);
    }));
    reportPages.forEach((page) => pdfDoc.addPage(page));

    pdfDoc.removePage(pdfLastPage);
    pdfForm.getFields().forEach((f) => f.enableReadOnly());
    pdfDoc.setTitle(`${detailData.title} Report`);
    const pdfBlob = new Blob([await pdfDoc.save()], { type: 'application/pdf;charset=utf-8' });
    saveAs(pdfBlob, `${detailData.title} Report ${moment().format('M-D-YYYY')}.pdf`);

    const photosBlob = await reportPhotos.generateAsync({ type: 'blob' });
    saveAs(photosBlob, `${detailData.title} Report Photos ${moment().format('M-D-YYYY')}.zip`);
  };

  const calcReportSelectPhotoCrop = (e) => {
    const { width, height } = e.currentTarget;
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        16 / 9,
        width,
        height,
      ),
      width,
      height,
    );
  };

  const changeReportSelectPhoto = (photo) => {
    if (isClient) {
      return;
    }
    setReportSelectPhotosActive((reportSelectPhotosActive) =>
      reportSelectPhotosActive.map((item) => item.id === photo.id ? photo : item));

    if (reportSelectPhotoModal) {
      axios.put(`${CampaignReportPhoto.apiPath}/${reportSelectPhotoModal.id}`, { ...reportSelectPhotoModal.crop, scale: reportSelectPhotoModal.scale });
    }
  };

  const onReportSelectRowsRendered = ({ startIndex, stopIndex }) => {
    Promise.all(
      range(startIndex, stopIndex + 1)
      .map((index) => reportSelectPhotos[index])
      .filter((photo) => !reportSelectPhotosActive.some((photoActive) => photoActive.id === photo.id) && photo.fk_jobId === reportSelectJobActive.id)
      .map((photo) => !reportSelectPhotoData[photo.id] && fetchPhoto({ photo })),
    );
  };
  const debounceReportSelectRowsRendered = useCallback(debounce(onReportSelectRowsRendered, 500), [ reportSelectPhotos, reportSelectPhotosActive, reportSelectPhotoData, reportSelectJobActive ]);
  const getReportSelectRowHeight = ({ index }) => !index ? 180 : !reportSelectPhotos[index].venuePhotoIndex ? 220 : 140;

  return (
    <>
      <Modal
        showing={isReportSelectShowing}
        onHide={() => setIsReportSelectShowing(false)}
        fullScreen
        className={classNames('campaign report-select', { client: isClient })}
        body={
          <>
            {reportSelectJobs.length > 1 &&
            <Dropdown className="job">
              <Dropdown.Toggle variant="outline-secondary">{`${reportSelectJobActive?.item.name}${reportSelectJobActive?.campaignMarket ? ` / ${reportSelectJobActive.campaignMarket.market.name}` : ''}`}</Dropdown.Toggle>
              <Dropdown.Menu>
                {reportSelectJobs?.map((job, i) => (
                  <React.Fragment key={i}>
                    <Dropdown.Item
                      className={classNames({ active: job.id === reportSelectJobActive?.id })}
                      onClick={() => {
                        setReportSelectJobActive(job);
                        setReportSelectPhotos(null);
                      }}
                    >
                      {`${job.item.name}${job.campaignMarket ? ` / ${job.campaignMarket.market.name}` : ''}`}
                    </Dropdown.Item>
                  </React.Fragment>
                ))}
              </Dropdown.Menu>
            </Dropdown>}
            <Row>
              <Col
                className="col"
                md={!reportSelectPhotos?.length ? 12 : 3}
                style={{ maxHeight: window.innerHeight - 100 }}
                ref={reportSelectPhotosRef}
              >
                {!reportSelectPhotos ? <Spinner /> :
                (!reportSelectPhotos.length ? <Alert variant="secondary" className="empty">No photos</Alert> :
                  <WindowScroller scrollElement={reportSelectPhotosRef.current}>
                    {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                      <AutoSizer disableHeight>
                        {({ width }) => (
                          <div
                            ref={registerChild}
                            style={{
                              width: '100%',
                              height: `${
                                reportSelectPhotos
                                .map((_, index) => getReportSelectRowHeight({ index }))
                                .reduce((acc, h) => acc + h, 0)
                              }px`,
                            }}
                          >
                            <List
                              style={{
                                height: '100%',
                              }}
                              width={width - 5}
                              height={height}
                              autoHeight
                              overscanRowCount={10}
                              scrollTop={scrollTop}
                              isScrolling={isScrolling}
                              onScroll={onChildScroll}
                              rowCount={reportSelectPhotos.length}
                              rowHeight={getReportSelectRowHeight}
                              rowRenderer={({ index, key, style }) => {
                                const { id, venuePhotoIndex, venue, date, time } = reportSelectPhotos[index];
                                const photoData = reportSelectPhotoData[id];
                                const isSelected = reportSelectPhotosActive.some(({ id }) => photoData?.id === id);
                                return (
                                  <div
                                    className={classNames('photo', { loading: !photoData })}
                                    key={key}
                                    style={style}
                                  >
                                    {!venuePhotoIndex &&
                                      <div className="venue">
                                        {venue.name}
                                        <div>{`${venue.neighborhood?.name ? `${venue.neighborhood.name}, ` : ''}${venue.market?.name}`}</div>
                                      </div>}
                                    <div>
                                    {!photoData ?
                                      <>
                                        <FontAwesomeIcon icon={faImage} />
                                        <Spinner />
                                      </> :
                                      <div className={classNames({ selected: isSelected })}>
                                        <i
                                          className="select"
                                          onClick={() => {
                                            if (isClient) {
                                              return;
                                            }
                                            setReportSelectPhotosActive(!isSelected ? [
                                              ...reportSelectPhotosActive,
                                              photoData,
                                            ] : [
                                              ...reportSelectPhotosActive.filter(({ id }) => photoData.id !== id),
                                            ]);
                                            if (!isSelected) {
                                              axios.post(CampaignReportPhoto.apiPath, { campaignId: detailData.id, jobId: reportSelectJobActive.id, photoId: photoData.id });
                                            } else {
                                              axios.delete(CampaignReportPhoto.apiPath, { data: { campaignId: detailData.id, jobId: reportSelectJobActive.id, photoId: photoData.id } });
                                            }
                                          }}
                                        />
                                        <img // eslint-disable-line
                                          src={`data:image/jpg;base64,${photoData.photo}`}
                                          onClick={() => setReportSelectPhotoModal({
                                            ...photoData,
                                            ...reportSelectPhotosActive.find(({ id }) => photoData.id === id),
                                            selected: isSelected,
                                          })}
                                        />
                                        {date ? <div className="date">{`${moment(date).format('M/D/YYYY')} ${time ? moment(time, 'HH:mm:ss').format('h:mm a') : ''}`}</div> : null}
                                      </div>}
                                    </div>
                                  </div>
                                );
                              }}
                              onRowsRendered={({ startIndex, stopIndex }) => {
                                debounceReportSelectRowsRendered({ startIndex, stopIndex });
                              }}
                            />
                          </div>
                        )}
                      </AutoSizer>
                    )}
                  </WindowScroller>
                )}
              </Col>
              {reportSelectPhotos?.length ?
              <Col className="col" md={9}>
                <div ref={reportSelectPhotosActiveRef}>
                  <Header
                    label={`${detailData?.title} Report`}
                    icons={[
                      reportSelectPhotosActive.length && !reportSelectPhotosActive.some(({ photo }) => !photo) && {
                        type: iconType.EXPORT,
                        onClick: async () => generateReportPdf(),
                        tooltip: 'Export Campaign Report',
                        exportingMsg: `Exporting${reportSelectPhotoExportCounter ? ` ${reportSelectPhotoExportCounter} of ${reportSelectPhotosActive.length} ` : ''}...`,
                      },
                    ].filter(Boolean)}
                  />
                  {reportSelectChartData &&
                    <>
                      <div className="chart">
                        <Doughnut
                          ref={reportSelectChartRef}
                          width={950}
                          height={500}
                          options={{
                            animation: {
                              duration: 0,
                            },
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                display: false,
                                // position: 'left',
                                // labels: {
                                //   boxWidth: 12,
                                //   font: {
                                //     size: 12,
                                //   },
                                // },
                              },
                              datalabels: {
                                color: '#ffffff',
                                formatter: (value) =>
                                  `${((value / reportSelectChartPhotoCountTotal) * 100).toFixed(1)}%`,
                              },
                            },
                          }}
                          data={reportSelectChartData}
                        />
                      </div>
                      <div className="chart-legend">
                        <ul ref={reportSelectChartLegendRef}>
                          {reportSelectChartData.labels.map((label, i) => (
                            <li>
                              <div className="bullet" style={{ backgroundColor: reportSelectChartData.datasets[0]?.backgroundColor[i] }} />
                              <div>{label}</div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>}
                  {!reportSelectPhotosActive.length ? <Alert variant="secondary" className="empty">None selected</Alert> :
                    <div className="photos-container" style={{ maxHeight: window.innerHeight - 160 }}>
                      <SortableList
                        onSortEnd={(oldIndex, newIndex) => {
                          const sorted = arrayMove(reportSelectPhotosActive, oldIndex, newIndex);
                          setReportSelectPhotosActive(sorted);
                          axios.post(CampaignReportPhoto.apiPath, { campaignId: detailData.id, jobId: reportSelectJobActive.id, reposition: sorted.map(({ id }, i) => ({ fk_photoId: id, position: i })) });
                        }}
                        className="photos"
                        draggedItemClassName="campaign report-select photo drag"
                      >
                        {reportSelectPhotosActive.map((photoData, i) =>
                          <SortableItem key={photoData.id}>
                            <div className={classNames('photo', { loading: !photoData.photo })}>
                              <div className="selected">
                                {!photoData.photo ?
                                  <>
                                    <FontAwesomeIcon icon={faImage} />
                                    <FontAwesomeIcon icon={faSquare} />
                                    <Spinner />
                                  </> :
                                  <>
                                    <i
                                      className="select"
                                      onClick={() => {
                                        if (isClient) {
                                          return;
                                        }
                                        setReportSelectPhotosActive([
                                          ...reportSelectPhotosActive.filter(({ id }) => photoData.id !== id),
                                        ]);
                                        axios.delete(CampaignReportPhoto.apiPath, { data: { campaignId: detailData.id, jobId: reportSelectJobActive.id, photoId: photoData.id } });
                                      }}
                                    />
                                    <div
                                      onClick={() =>
                                        setReportSelectPhotoModal({
                                          ...photoData,
                                          selected: true,
                                        })}
                                    >
                                      {photoData.crop && <div className="crop-selection" style={{ top: `${photoData.crop.y}%`, left: `${photoData.crop.x}%`, width: `${photoData.crop.width}%`, height: `${photoData.crop.height}%` }} />}
                                      <img // eslint-disable-line
                                        src={`data:image/jpg;base64,${photoData.photo}`}
                                        draggable={false}
                                        style={{ transform: `scale(${photoData.scale})` }}
                                        onLoad={(e) => {
                                          if (!photoData.crop) {
                                            photoData.crop = calcReportSelectPhotoCrop(e);
                                            photoData.scale = 1;
                                            changeReportSelectPhoto(photoData);
                                          }
                                        }}
                                        onClick={() =>
                                          setReportSelectPhotoModal({
                                            ...photoData,
                                            selected: true,
                                          })}
                                      />
                                    </div>
                                  </>}
                              </div>
                              <div className="caption">
                                {`${photoData.venue.neighborhood?.name ? `${photoData.venue.neighborhood.name}, ` : ''}${photoData.venue.market?.name}`}
                              </div>
                            </div>
                          </SortableItem>)}
                      </SortableList>

                    {/* {chunk(reportSelectPhotosActive, 2).map((selected, i) =>
                      <Row className="slide photo" key={i}>
                        {selected.map((photoData, j) =>
                        <Col className="col" md={6} key={j}>
                          <div className={classNames('selected', { loading: !photoData.photo })}>
                            {!photoData.photo ?
                            <>
                              <FontAwesomeIcon icon={faImage} />
                              <FontAwesomeIcon icon={faSquare} />
                              <Spinner />
                            </> :
                            <>
                              <i
                                className="select"
                                onClick={() => {
                                  setReportSelectPhotosActive([
                                    ...reportSelectPhotosActive.filter(({ id }) => photoData.id !== id),
                                  ]);
                                  axios.delete(CampaignReportPhoto.apiPath, { data: { campaignId: detailData.id, photoId: photoData.id } });
                                }}
                              />
                              <img // eslint-disable-line
                                src={`data:image/jpg;base64,${photoData.photo}`}
                                onClick={() => setReportSelectPhotoModal({
                                  data: photoData.photo,
                                })}
                              />
                            </>}
                          </div>
                          <div className="caption">
                            {`${photoData.venue.neighborhood?.name ? `${photoData.venue.neighborhood.name}, ` : ''}${photoData.venue.market?.name}`}
                          </div>
                        </Col>)}
                      </Row>)} */}
                    </div>}
                </div>
              </Col> : null}
            </Row>
          </>
        }
      />
      <Modal
        showing={!!reportSelectPhotoModal}
        onHide={() => {
          changeReportSelectPhoto(reportSelectPhotoModal);
          setReportSelectPhotoModal(null);
        }}
        fullScreen
        className="campaign report-select image"
        body={
          reportSelectPhotoModal?.selected ?
            <>
              <ReactCrop
                crop={{ ...reportSelectPhotoModal.crop, unit: '%' }}
                onChange={(_, percentCrop) => setReportSelectPhotoModal({ ...reportSelectPhotoModal, crop: percentCrop })}
                keepSelection
                aspect={16 / 9}
                disabled={isClient}
              >
                <img
                  ref={reportSelectPhotoRef}
                  src={`data:image/jpg;base64,${reportSelectPhotoModal.photo}`}
                  style={{ transform: `scale(${reportSelectPhotoModal.scale})` }}
                />
              </ReactCrop>
              <div
                className="controls"
                style={reportSelectPhotoModal.crop && {
                  top: `${reportSelectPhotoModal.crop.y + reportSelectPhotoModal.crop.height}%`,
                  left: `${reportSelectPhotoModal.crop.x + reportSelectPhotoModal.crop.width}%`,
                }}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlassPlus}
                  onClick={() => setReportSelectPhotoModal({ ...reportSelectPhotoModal, scale: reportSelectPhotoModal.scale + 0.1 })}
                />
                <FontAwesomeIcon
                  icon={faMagnifyingGlassMinus}
                  onClick={() => setReportSelectPhotoModal({ ...reportSelectPhotoModal, scale: Math.max(reportSelectPhotoModal.scale - 0.1, 1) })}
                  className={classNames({ disabled: reportSelectPhotoModal.scale === 1 })}
                />
              </div>
            </> :
            <img src={`data:image/jpg;base64,${reportSelectPhotoModal?.photo}`} />
        }
      />
    </>
  );
};

const Campaign = () => {
  const isClient = _isClient();
  const [ isProposedShowing, setIsProposedShowing ] = useState(false);
  const [ isPipelineShowing, setIsPipelineShowing ] = useState(false);
  const [ isAllShowing, setIsAllShowing ] = useState(false);
  const [ reportSelectDetailData, setReportSelectDetailData ] = useState(null);
  const [ isReportSelectShowing, setIsReportSelectShowing ] = useState(false);
  const [ isTagCopied, setIsTagCopied ] = useState(false);
  const [ apiUrl, setApiUrl ] = useState(null);

  return (
    <div className={classNames('index campaign', { 'pipeline-showing': isPipelineShowing })}>
      <Crud
        apiPath={apiPath}
        apiParams={{ index: true, proposed: isProposedShowing, all: isAllShowing }}
        schema={
          isProposedShowing ? alterSchema(schema, { gridColsHide: [ 'distributionGoal', 'venuesAlltime', 'upCurrent', 'venuesAudit', 'status' ], gridColsShow: ['type'] }) :
          alterSchema(schema, { gridColsHide: isAllShowing ? [ 'hot', 'avgBudget', 'dateLastActivity' ] : [ 'hot', 'avgBudget', 'dateLastActivity', 'dateCreation' ] })
        }
        model={model}
        label={label}
        routePath={routePath}
        showAdd={!isClient}
        icons={[
          ...!isClient ? [
            {
              type: iconType.PROPOSED,
              onClick: () => {
                setIsProposedShowing(!isProposedShowing);
                setIsAllShowing(false);
              },
              active: isProposedShowing,
              tooltip: 'Toggle Proposed',
            },
            {
              type: iconType.ALL,
              onClick: () => {
                setIsAllShowing(!isAllShowing);
                setIsProposedShowing(false);
              },
              active: isAllShowing,
              tooltip: 'Toggle All',
            },
            {
              type: iconType.PIPELINE,
              onClick: () => setIsPipelineShowing(!isPipelineShowing),
              active: isPipelineShowing,
              tooltip: 'Toggle Pipeline',
            },
            {
              type: iconType.TAG,
              detail: true,
              tooltip:
                isTagCopied ?
                  <div>
                    Copied Campaign Tag
                    <i>paste into email body</i>
                  </div> :
                  <div>
                    Copy Campaign Tag
                    <i>paste into email body</i>
                  </div>,
              onClick: async ({ detailData }) => {
                setIsTagCopied(true);
                setTimeout(() => {
                  setIsTagCopied(false);
                }, 2000);
                window.navigator.clipboard.writeText(`#campaign${detailData.id}`);
              },
            },
          ] : [],
/*           {
            type: iconType.EXPORT,
            detail: true,
            tooltip: 'Export Campaign Venues',
            onClick: async ({ detailData }) => {
              const workbook = XLSX.utils.book_new();
              const { data: { items: jobs } } = await axios.get(`${Job.apiPath}/?campaignId=${detailData.id}`);
              (await Promise.all(jobs.map(async (job) => {
                const { data: { items: venues } } = await axios.get(`${Venue.apiPath}/?campaignId=${detailData.id}&jobId=${job.id}&distributionVenue=true`);
                return {
                  job,
                  venues,
                };
              }))).forEach(({ job, venues }) => {
                try {
                  const worksheet = XLSX.utils.json_to_sheet(venues.map((venue) => ({
                    ID: venue.id,
                    Name: venue.name,
                    Address: venue.address,
                    Zip: venue.zip,
                    Type: venue.type?.type,
                    Neighborhood: venue.neighborhood?.name,
                    Market: venue.market?.name,
                    Latitude: venue.latitude,
                    Longitude: venue.longitude,
                  })));
                  XLSX.utils.book_append_sheet(workbook, worksheet, truncate(`${job.campaignMarket.market.name} | ${job.item.name}`, { length: 30 }));
                } catch (e) {
                  console.log(e);
                }
              });
              XLSX.writeFile(workbook, `Venues-${detailData.title}-${moment().format('YYYYMMDD')}.xlsx`, { compression: true });
            },
          }, */
          {
            type: iconType.REPORT,
            detail: true,
            tooltip: 'Generate Campaign Report',
            onClick: async ({ detailData }) => {
              setReportSelectDetailData(detailData);
              setIsReportSelectShowing(true);
            },
          },
        ].filter(Boolean)}
        getApiUrl={setApiUrl}
      />
      {isPipelineShowing && <Pipeline
        stage={isProposedShowing ? stage : stageInProgress}
        stageField="stage"
        apiPath={apiPath}
        apiUrl={apiUrl}
        filterMatch={({ item, query }) =>
          item.title?.toLowerCase().includes(query) ||
          item.client?.name?.toLowerCase().includes(query) ||
          item.markets?.some(({ name, code }) => name.toLowerCase().includes(query) || code.toLowerCase().includes(query))}
        renderCard={(item) => ({
          title:
          <>
            <div className="title">{item.title}</div>
            {item.hot && <div className="hot">{item.hot}</div>}
          </>,
          description:
            <div className="description">
              <div>{item.client.name}</div>
              <div>{moment(item.dateStart).format('M/D/YYYY')}{item.dateEnd && <> &ndash; {moment(item.dateEnd).format('M/D/YYYY')}</>}</div>
              {item.type ? <div>{item.type}</div> : ''}
              {item.markets?.length ? <div>{item.markets.map(({ code }) => code).join(', ')}</div> : ''}
              {isProposedShowing && item.avgBudget ? <div>{`$${item.avgBudget.toLocaleString()}`}</div> : item.stage === stageInProgress.PAYMENT && item.invoiceTotal ? <div>{`$${(item.invoicePaid || 0).toLocaleString()} of $${item.invoiceTotal.toLocaleString()} paid`}</div> : ''}
              {isProposedShowing && item.dateLastActivity ? <div><span>Last Contact:</span>{moment(item.dateLastActivity).format('M/D/YYYY')}</div> : ''}
              {isProposedShowing && item.stage === stage.RFP_REQUEST && item.due ? <div><strong><span>DUE:</span>{item.due}</strong></div> : ''}
              {isProposedShowing && item.stage === stage.ON_HOLD && item.dateHoldExpires ? <div><span>Hold Expires:</span>{moment(item.dateHoldExpires).format('M/D/YYYY')}</div> : ''}
            </div>,
          // draggable: isProposedShowing,
        })}
      />}
      {isReportSelectShowing && <ReportSelect
        detailData={reportSelectDetailData}
        isReportSelectShowing={isReportSelectShowing}
        setIsReportSelectShowing={setIsReportSelectShowing}
      />}
    </div>
  );
};

export default Campaign;
