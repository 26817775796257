import * as Market from 'views/market/config';

export const model = 'setting';
export const label = 'Settings';
export const apiPath = '/settings';
export const schema = {
  fk_defaultMarketId: {
    label: 'Default Market',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Market.apiPath,
    },
  },
  defaultOverhead: {
    label: 'Default Overhead',
    type: 'number',
    required: true,
    form: {},
  },
};
