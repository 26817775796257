import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Board from 'react-trello';
import axios from 'axios';
import { groupBy, invert, mapKeys, debounce } from 'lodash';
import { Badge, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import * as Route from 'services/route';
import Spinner from './spinner';
import 'styles/pipeline.scss';

export default ({ stage, stageField, apiPath, apiUrl, renderCard, filterMatch } = {}) => {
  const navigate = useNavigate();
  const [ laneData, setLaneData ] = useState(null);
  const [ laneDataUpdated, setLaneDataUpdated ] = useState(null);
  const [ laneCardMoved, setLaneCardMoved ] = useState(false);
  const [ eventBus, setEventBus ] = useState(null);
  const laneFilterIconRefs = useRef([]);
  const laneFilterBoxRefs = useRef([]);
  const [ laneFilter, setLaneFilter ] = useState(Object.keys(stage).reduce((acc, key) => ({
    ...acc,
    [key]: '',
  }), {}));

  const { pathname } = useLocation();
  const params = useParams();
  const isDetailPath = () =>
    (Route.isDetailPath({ params }) || Route.isAddPath({ pathname }));

  const initLane = (cards) => {
    cards = cards?.filter(({ className }) => className !== 'hide');
    return {
      className: !cards?.length && 'empty',
      label: <Badge>{cards?.length || 0}</Badge>,
    };
  };

  const updateLanes = (lanes) => {
    lanes = lanes.map((lane) => ({
      ...lane,
      ...initLane(lane.cards),
    }));
    eventBus.publish({ type: 'UPDATE_LANES', lanes });
    setLaneDataUpdated(lanes);
  };

  const changeFilter = (target, key) => {
    laneFilter[key] = target.value;
    target.classList[target.value ? 'add' : 'remove']('active');
    setLaneFilter({
      ...laneFilter,
      _changed: key,
    });
  };
  const debounceChangeFilter = useCallback(debounce(changeFilter, 300), []);

  useEffect(async () => {
    if (laneFilter._changed) {
      const lanes = (laneDataUpdated || laneData);
      const lane = lanes.find(({ id }) => id === laneFilter._changed);
      const query = laneFilter[laneFilter._changed].toLowerCase();
      laneFilterIconRefs.current[lanes.indexOf(lane)].classList[query ? 'add' : 'remove']('active');
      lane.cards.forEach((card) => {
        const { laneId, metadata } = card;
        card.className = !filterMatch({ item: metadata, query }) && 'hide';
        eventBus.publish({
          type: 'UPDATE_CARD',
          laneId,
          card,
        });
      });
      updateLanes(lanes);
    }
  }, [laneFilter]);

  useEffect(async () => {
    setLaneData(null);
    setLaneDataUpdated(null);
  }, [stage]);

  useEffect(async () => {
    if (isDetailPath()) {
      setLaneData(null);
      setLaneDataUpdated(null);
    } else if (!laneData) {
      const { data: { items } } = await axios.get(`${apiUrl}&pipeline=true`);
      const groupedItems = mapKeys(groupBy(items, stageField), (value, key) => invert(stage)[key]);
      setLaneData(
        //Object.keys(stage).filter((key) => key !== 'DEAD').map((key) => ({
        Object.keys(stage).map((key, i) => ({
          id: key,
          title:
            <span
              className="title"
            >
              {stage[key]}
              <OverlayTrigger
                placement="top"
                trigger="click"
                rootClose
                onEnter={(container) => {
                  const formControl = container.querySelector('.form-control');
                  formControl.focus();
                  formControl.value = laneFilter[key];
                  if (formControl.value) {
                    formControl.classList.add('active');
                  }
                  laneFilterIconRefs.current[i].classList.add('open');
                }}
                onExit={() => {
                  laneFilterIconRefs.current[i].classList.remove('open');
                }}
                overlay={
                <Popover
                  className="pipeline lane-filter"
                >
                  <Form.Control
                    placeholder="Filter..."
                    onChange={(e) => debounceChangeFilter(e.target, key)}
                  />
                  <i
                    className="fa fa-xmark clear"
                    onClick={(e) => {
                      const formControl = e.target.previousSibling;
                      formControl.value = '';
                      changeFilter(formControl, key);
                    }}
                  />
                </Popover>
              }
              >
                <div
                  className="filter"
                  ref={(ref) => laneFilterIconRefs.current.push(ref)}
                  title=""
                />
              </OverlayTrigger>
            </span>,
          style: { width: `${100 / Object.keys(stage).length}%` },
          cards: groupedItems?.[key] ? groupedItems?.[key]?.map((item) => ({
            id: item.id,
            metadata: item,
            ...renderCard(item),
            // draggable: false,
/*             title: <div className="title">{item.title}</div>,
            description:
              <>
                <div className="company">{item.client.name}</div>
                <div className="date">{moment(item.dateStart).format('M/D/YYYY')}</div>
              </>, */
          })) : [],
          ...initLane(groupedItems?.[key]),
        })),
      );
    }
  }, [ pathname, laneData ]);

  return !laneData ? <Spinner /> :
    <div
      ref={(node) => node?.querySelectorAll('section').forEach((section) => {
        section.setAttribute('title', '');
      })}
    >
      <Board
        className="pipeline"
        style={{ paddingRight: ((Object.keys(stage).length - 1) * 10) + 5 }}
        collapsibleLanes={false}
        laneDraggable={false}
        handleDragEnd={(cardId, fromLaneId, toLaneId, position, cardDetails) => {
          setLaneCardMoved(true);
          if (fromLaneId !== toLaneId) {
            axios.patch(`${apiPath}/${cardId}`, { [stageField]: stage[toLaneId] });
          }
        }}
        onCardClick={(cardId, metadata, laneId) => {
          navigate(`./${cardId}`);
          //setIsPipelineShowing(false);
        }}
        onDataChange={({ lanes }) => {
          if (laneCardMoved) {
            updateLanes(lanes);
          }
        }}
        eventBusHandle={(handle) => setEventBus(handle)}
        data={{ lanes: laneData }}
      />
    </div>;
};
