import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import Spinner from 'components/spinner';
import 'styles/modal.scss';

export default ({ showing, onHide, body, onSubmit, className, confirm, containerWidth, fullScreen }) => {

  const [ submitting, setSubmitting ] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    await onSubmit();
    setSubmitting(false);
  };

  return (
    <Modal
      show={showing}
      onHide={onHide}
      className={classNames(className, { confirm, 'container-width': containerWidth, 'full-screen': fullScreen })}
    >
      <Modal.Header closeButton />
      <Modal.Body>{body}</Modal.Body>
      {onSubmit &&
      <Modal.Footer>
        <Button variant="danger" onClick={handleSubmit} disabled={submitting} className="submit">
          Ok
        </Button>
        <Button variant="light" onClick={onHide} disabled={submitting} className="cancel">
          Cancel
        </Button>
        {submitting && <Spinner size="sm" />}
      </Modal.Footer>}
    </Modal>
  );
};
