import React from 'react';
import classNames from 'classnames';
import { isBA as _isBA } from 'services/auth';
import Crud from 'components/crud';
import { apiPath, schema, model, label, routePath, icons } from './config';

const RouteSheet = () => {
  const isBA = _isBA();
  return (
    <div className={classNames('index route-sheet', { ba: isBA })}>
      <Crud
        apiPath={apiPath}
        schema={schema}
        model={model}
        label={label}
        showAdd={false}
        showDelete={!isBA}
        routePath={routePath}
        icons={!isBA ? icons : []}
      />
    </div>
  );
};

export default RouteSheet;
