import React, { useRef } from 'react';
import axios from 'axios';
import Crud from 'components/crud';
import * as RouteSheet from 'views/routeSheet/config';
import { apiPath, schema, model, label } from './config';

const Payroll = () => {
  const showLogDetailRef = useRef(null);
  return (
    <Crud
      apiPath={apiPath}
      schema={schema}
      model={model}
      label={label}
      labelPluralize={false}
      readOnly
      showDetailRef={showLogDetailRef}
      onRowClick={async ({ data, event }) => {
        if (!event.target.classList.contains('fa')) {
          showLogDetailRef.current({
            nestedModalForce: true,
            data: (await axios.get(`${RouteSheet.apiPath}/${data.id}`)).data,
            detail: {
              ...RouteSheet,
              tabActive: 'log',
            },
          });
        }
      }}
    />
  );
};

export default Payroll;
