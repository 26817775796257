import React from 'react';
import Crud from 'components/crud';
import { apiPath, schema, model, label, routePath } from './config';

const Vendor = () => {
  return (
    <Crud
      apiPath={apiPath}
      schema={schema}
      model={model}
      label={label}
      routePath={routePath}
    />
  );
};

export default Vendor;
