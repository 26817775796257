import * as Venue from 'views/venue/config';

export const model = 'routeSheetLog';
export const label = 'Activity';
export const apiPath = '/routeSheetLog';
export const schema = {
  date: {
    label: 'Date',
    type: 'date',
    time: true,
    grid: {
      suppressMenu: true,
    },
  },
  break: {
    label: 'Break',
    type: 'number',
    grid: {
      suppressMenu: true,
    },
  },
  venueName: {
    label: 'Venue',
    grid: {
      detail: () => ({
        key: 'fk_venueId',
        ...Venue,
      }),
      suppressMenu: true,
    },
  },
  fk_photoId: {
    label: 'Photo',
    grid: {
      image: true,
      suppressMenu: true,
    },
  },
  contactName: {
    label: 'Contact Name',
    grid: {
      suppressMenu: true,
    },
  },
  contactEmail: {
    type: 'email',
    label: 'Contact Email',
    grid: {
      suppressMenu: true,
    },
  },
  refused: {
    label: 'Refusal',
    grid: {
      suppressMenu: true,
    },
  },
  auditStatus: {
    label: 'Audit Status',
    grid: {
      suppressMenu: true,
    },
  },
  comments: {
    label: 'Notes',
    grid: {
      suppressMenu: true,
    },
  },
};
