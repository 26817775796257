import React from 'react';
import Form from 'components/form';
import 'styles/detail.scss';

const VendorTypeDetail = ({ id, apiPath, schema, data, onSubmit }) => {
  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={schema}
        data={data}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default VendorTypeDetail;
