import React, { useState, useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
// import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import pluralize from 'pluralize';
import classNames from 'classnames';
import { Alert } from 'react-bootstrap';
import { isEmpty as _isEmpty, cloneDeep, get } from 'lodash';
import axios from 'axios';
import { saveAs } from 'file-saver';
import isEmpty from 'utils/isEmpty';
import iconType from 'utils/iconType';
import { getPrimaryKey } from 'utils/schema';
import { base64ToArrayBuffer } from 'utils/encoding';
import { isBA as _isBA } from 'services/auth';
import * as Photo from 'views/photo/config';
import Spinner from './spinner';
import Modal from './modal';
import 'styles/alert.scss';

// TODO: set final license
// LicenseManager.setLicenseKey('');

const LoadingOverlay = () => (
  <Spinner />
);

const Grid = (props) => {
  const {
    cols,
    rowData,
    getRowData,
    setRowCount,
    apiLimit,
    apiParams,
    onRowClick,
    nested,
    dataParent,
    model,
    modelParent,
    schemaParent,
    height,
    label,
    labelPluralize = true,
    readOnly,
    checkSelect,
    checkSelectedField,
    onCheckSelected,
    onCellMouseOver,
    onCellMouseOut,
    getApi,
    filterModel,
    emptyMsg,
    rowClass,
    clientSide = false,
  } = props;
  const [ api, setApi ] = useState(null);
  const [ nestedHeight, setNestedHeight ] = useState(null);
  const [ viewport, setViewport ] = useState(null);
  const [ hasHorizontalScroll, setHasHorizontalScroll ] = useState(null);
  const [ filterClearing, setFilterClearing ] = useState(null);
  const [ imageModal, setImageModal ] = useState(null);
  const isBA = _isBA();

  const defaultColDef = {
    minWidth: 100,
    filter: 'agTextColumnFilter',
    filterParams: { buttons: ['clear'] },
    sortable: true,
    resizable: true,
    suppressMovable: isBA,
    menuTabs: ['filterMenuTab'],
    cellClassRules: {
      empty: ({ value }) => isEmpty(value),
    },
  };

  const columnDefs = cols.map((col, i) => {
    col = cloneDeep(col);
    let cellRenderer = ({ value }) => !isEmpty(value) ? value : '-';
    if (col.type === 'number') {
      col.filter = 'agNumberColumnFilter';
      cellRenderer = ({ value }) => !isEmpty(value) ? value.toLocaleString() : '-';
    } else if (col.type === 'date') {
      col.filter = 'agDateColumnFilter';
      cellRenderer = ({ value }) => value ? moment(value).format(`M/D/YYYY${col.time ? ' h:mm a' : ''}`) : '-';
    } else if (col.type === 'boolean') {
      col.boolean = true;
      col.filter = 'agSetColumnFilter';
      cellRenderer = ({ value }) => value ? 'Yes' : 'No';
      col.filterParams = {
        values: [ 'Yes', 'No' ],
        filterOptions: [
          'equals',
          'notEqual',
        ],
      };

    } else if (col.type === 'price') {
      col.filter = 'agNumberColumnFilter';
      cellRenderer = ({ value }) => value ? parseFloat(value).toFixed(2) : '-';
    } else if (col.type === 'array') {
      col.sortable = false;
    }
    if (col.detail || col.email || col.linkedIn) {
      cellRenderer = (params) =>
        isEmpty(params.value) ? '-' : (
          col.detail && !get(params.data, col.detail.key) ? params.value :
          <>
            <i className={`fa fa-${iconType.LINK.className}`} />
            <span>{params.value}</span>
          </>
        );
    } else if (col.image) {
      cellRenderer = (params) =>
        isEmpty(params.value) ? '-' : (
          <i className={`fa fa-${iconType.IMAGE.className}`} />
        );
      col.cellClass = 'image';
      col.onCellClicked = async ({ data, node, event, api, column }) => {
        if (event.target.classList.contains('fa')) {
          setImageModal({
            loading: true,
          });
          const { data: { photo, googleCloudUrl } } = await axios.get(`${Photo.apiPath}/${data.fk_photoId}?fields=id,photo,googleCloudUrl`);
          if (photo) {
            setImageModal({
              id: data.fk_photoId,
              data: photo,
            });
          } else if (googleCloudUrl) {
            const url = googleCloudUrl;
            await fetch(googleCloudUrl, { cache: 'no-cache' })
              .then((response) => response.blob())
              .then((blob) => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setImageModal({
                    id: data.fk_photoId,
                    data: reader.result.split(',')[1],
                  });
                  resolve();
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              }));
          }
        }
      };
    }

    col.cellRenderer = col.cellRenderer || cellRenderer;

    delete col.type;
    return col;
  });

  if (checkSelect) {
    const firstCol = columnDefs.find((col) => !col.hide);
    if (firstCol) {
      firstCol.checkboxSelection = true;
    }
  }

  const setCheckSelected = (params) => {
    if (checkSelectedField) {
      params.api.forEachNode((node) => {
        if (get(node.data, checkSelectedField)) {
          node.setSelected(true);
        }
      });
    }
  };

  useEffect(() => {
    // if (!api && rowData) {
    //   //setRenderedRowCount(rowData.length);
    // }
    if (api && !rowData) {
      if (!clientSide) {
        setDatasource({ api }); // eslint-disable-line
      }
    } else if (api && rowData) {
      if (cols) {
        cols.forEach((col) => {
          if (!col.hide) {
            api.columnModel?.columnApi.setColumnVisible(col.field, col.visible !== false);
          }
        });
      }
      setTimeout(() => {
        api.sizeColumnsToFit();
        setCheckSelected({ api });
      });
    }
  }, [ api, rowData, cols ]);

  const getCacheKey = () =>
    modelParent ? `${modelParent}::${dataParent[schemaParent ? getPrimaryKey(schemaParent) : 'id']}::${model}` : model;

  const getCache = () => {
    const cacheKey = getCacheKey();
    return JSON.parse(window.sessionStorage.getItem(cacheKey))?.grid;
  };

  const setCache = (params) => {
    const cacheKey = getCacheKey();
    window.sessionStorage.setItem(cacheKey, JSON.stringify({
      ...JSON.parse(window.sessionStorage.getItem(cacheKey)),
      ...{
        grid: {
          filterModel: params.api.getFilterModel(),
          columnState: params.columnApi.getColumnState(),
        },
      },
    }));
  };

  const setDatasource = (params) => {
    params.api.setDatasource({
      rowCount: undefined,
      getRows: async ({ startRow, endRow, sortModel, filterModel, successCallback }) => {
        let filter = filterModel;
        if (!_isEmpty(filterModel)) {
          filter = cloneDeep(filterModel);
          Object.keys(filter).forEach((key) => {
            if (filter[key].filterType === 'set' && cols.find(({ field }) => field === key).boolean) {
              filter[key].values = filter[key].values.map((value) => value === 'Yes' ? 1 : null);
            }
          });
        }
        const sort = sortModel.filter(({ colId }) => !cols.some((col) => col.field === colId && col.sortable === false));
        const { items, count } = await getRowData({ start: startRow, end: endRow, sort, filter });
        // rows = rows.filter((row) => !row._pinned);
        if (!isEmpty(count)) {
          setRowCount(count);
        }
        setFilterClearing(false);
        successCallback(items, items.length < apiLimit ? startRow + items.length : -1);
        setCheckSelected(params);
      },
    });
  };

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };

    const cache = getCache();
    const _filterModel = filterModel || cache?.filterModel;
    if (_filterModel) {
      params.api.setFilterModel(_filterModel);
    }
    if (cache?.columnState) {
      params.columnApi.applyColumnState({ state: cache.columnState });
    }

    // document.querySelector('.ag-header-cell[col-id="quarter1"]').onclick = () => {
    //   params.api.columnModel.columnApi.setColumnsVisible(['month1','month2','month3'], false);
    // };

    // if (!clientSide) {
    //   setDatasource(params);
    // }

    setApi(params.api);
    getApi?.(params.api);
  }, [rowData]);

  useEffect(async () => {
    if (api && apiParams) {
      setDatasource({ api });
    }
  }, [JSON.stringify(apiParams)]);

  const setFilterClearButton = ({ colId, filterMenu }) => {
    if (filterMenu) {
      if (api.getFilterModel()[colId]) {
        filterMenu.classList.add('is-filtered');
      } else {
        filterMenu.classList.remove('is-filtered');
      }
    }
  };

  const onFilterChanged = (params) => {
    setFilterClearButton({
      colId: params.columns[0].colId,
      filterMenu: params.api.getFilterInstance(params.columns[0].colId)?.eGui.parentNode,
    });
    props.onFilterChanged?.(params.api.getFilterModel());

    // cleared, set temp flag in state to keep grid headers in place until getRows finished
    if (!api.getFilterModel()[params.columns[0].colId]) {
      setFilterClearing(true);
    }
    setCache(params);
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    });
  };

  const onFilterOpened = (params) => {
    setFilterClearButton({
      colId: params.column.colId,
      filterMenu: params.eGui,
    });
  };

  const onSortChanged = (params) => {
    setCache(params);
  };

  const onGridSizeChanged = () => {
    if (viewport) {
      if (viewport.clientWidth) {
        const displayedCols = cols.filter((col) => !col.hide);
        const colWidths = displayedCols.filter((col) => col.minWidth).map((col) => col.minWidth);
        if (colWidths.length === displayedCols.length) {
          const colWidthsTotal = colWidths.reduce((sum, a) => sum + a, 0);
          const columnState = api.columnModel.columnApi.getColumnState();
          columnState.forEach((col) => {
            const displayedCol = displayedCols.find(({ field }) => field === col.colId);
            if (displayedCol) {
              col.width = viewport.clientWidth * (displayedCol.minWidth / colWidthsTotal);
            }
          });
          api.columnModel.columnApi.applyColumnState({ state: columnState });
        }
      }
      setHasHorizontalScroll(viewport.scrollWidth > viewport.clientWidth);
    }
  };

  // if (!rowData) {
  //   return <Spinner />;
  // }

  // if (!rowData.length && !Object.keys(getCache()?.filterModel || {}).length) {
  //   return <Alert variant="secondary" className="grid empty">{emptyMsg || `No ${(labelPluralize ? pluralize(label) : label).toLowerCase()}`}</Alert>;
  // }

  const rowHeight = isMobile ? 33 : 28;

  return (
    <>
      <div
        className={classNames('grid ag-theme-balham', { loading: !rowData, readonly: readOnly, rowclick: !!onRowClick, empty: !rowData?.items.length, filtered: filterClearing || !_isEmpty(getCache()?.filterModel) })}
        ref={(el) => {
          if (el) {
            setViewport(el.querySelector('.ag-center-cols-viewport'));
            if (nested && !height) {
              setNestedHeight(window.innerHeight - el.getBoundingClientRect().top - 25);
            }
          }
        }}
        style={{ height: height || nestedHeight || null, maxHeight: rowData ? rowData.items.filter((item) => !item._groupChildCollapsed).length * rowHeight + 35 + (hasHorizontalScroll && rowData?.items.length ? 15 : 0) : 0 }}
      >
        <AgGridReact
          rowModelType={clientSide ? 'clientSide' : 'infinite'}
          cacheBlockSize={apiLimit}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          animateRows
          accentedSort
          suppressColumnMoveAnimation
          loadingOverlayComponent={LoadingOverlay}
          onGridReady={onGridReady}
          rowSelection={!readOnly ? (checkSelect ? 'multiple' : 'single') : ''}
          rowMultiSelectWithClick={!!checkSelect}
          suppressRowClickSelection={!!checkSelect}
          onRowClicked={onRowClick}
          onRowSelected={({ node }) => {
            if (checkSelect && onCheckSelected) {
              onCheckSelected(node);
            }
          }}
          //onSelectionChanged={(params) => console.log(params[checkSelectedField])}
          /* onSelectionChanged={!readOnly ? onSelect : null} */
          onFilterChanged={onFilterChanged}
          onFilterOpened={onFilterOpened}
          onSortChanged={onSortChanged}
          onCellMouseOver={onCellMouseOver}
          onCellMouseOut={onCellMouseOut}
          onGridSizeChanged={onGridSizeChanged}
          suppressContextMenu
          rowData={clientSide && rowData?.items}
          rowHeight={rowHeight}
          rowClassRules={rowClass}
          singleClickEdit
          /* cacheOverflowSize={2} */
          /* maxConcurrentDatasourceRequests={2} */
          /* infiniteInitialRowCount={1000} */
          /* maxBlocksInCache={10} */
          //onModelUpdated={onModelUpdated}
          // domLayout="autoHeight"
        />
        {!rowData ? <Spinner /> : !rowData.items.length && <Alert variant="secondary" className="grid empty">{emptyMsg || `No ${(labelPluralize ? pluralize(label) : label).toLowerCase()}`}</Alert>}
      </div>
      <Modal
        showing={!!imageModal}
        onHide={() => setImageModal(null)}
        containerWidth
        className="image"
        body={
          imageModal?.loading ? <Spinner /> :
          <img // eslint-disable-line
            src={`data:image/jpg;base64,${imageModal?.data}`}
            onClick={() => {
              saveAs(
                new Blob([base64ToArrayBuffer(imageModal.data)], { type: 'image/jpeg' }),
                `${imageModal.id}.jpg`,
              );
            }}
          />
        }
      />
    </>
  );
};

export default Grid;
