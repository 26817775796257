import * as Campaign from 'views/campaign/config';
import * as Route from 'views/route/config';

export const model = 'routeSheetNote';
export const label = 'Route Sheet Notes';
export const apiPath = '/routeSheetNotes';
export const schema = {
  fk_campaignId: {
    label: 'Campaign',
  },
  'campaign.title': {
    label: 'Campaign',
    grid: {
      minWidth: 150,
      detail: () => ({
        key: 'campaign.id',
        ...Campaign,
      }),
    },
  },
  'route.id': {
    label: 'Route',
    grid: {
      minWidth: 100,
      detail: () => ({
        key: 'route._id',
        ...Route,
      }),
    },
  },
  notes: {
    label: 'Notes',
    grid: {},
  },
};
