import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import Crud from 'components/crud';
import Form from 'components/form';
import * as Route from 'views/route/config';
import * as RouteSheet from 'views/routeSheet/config';
import * as Job from 'views/job/config';
import * as Market from 'views/market/config';
import { alterSchema } from 'utils/schema';
import iconType from 'utils/iconType';
import 'styles/views/routeDistribution.scss';

export const schema = {
  fk_marketId: {
    label: 'Market',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      col: 1,
      colWidth: 4,
      itemsUrl: () => Market.apiPath,
      onChange: async ({ item, values, fields, setFieldValue }) => {
        if (item) {
          await fields.find(({ key }) => key === 'fk_routeId').form.initItems({ fk_marketId: item.value });
          setFieldValue('fk_routeId', null);
          await fields.find(({ key }) => key === 'fk_jobId').form.initItems({ fk_marketId: item.value, ...values.date && { date: values.date } });
          setFieldValue('fk_jobId', null);
        }
      },
    },
  },
  date: {
    label: 'Date',
    type: 'date',
    required: true,
    form: {
      col: 2,
      onChange: async ({ value, values, fields, setFieldValue }) => {
        if (value) {
          await fields.find(({ key }) => key === 'fk_jobId').form.initItems({ fk_marketId: values.fk_marketId, date: value });
          setFieldValue('fk_jobId', null);
        }
      },
      defaultValue: moment().format('yyyy-MM-DD'),
    },
  },
  fk_routeId: {
    label: 'Route',
    required: true,
    form: {
      type: 'autocomplete',
      primaryKey: '_id',
      valueKey: 'id',
      labelKey: 'id',
      col: 3,
      itemsUrl: (data) => data && data.fk_marketId ? `${Route.apiPath}?marketId=${data.fk_marketId}${data.fk_jobId ? `&jobId=${data.fk_jobId}` : ''}` : null,
      // onChange: async ({ item, fields, setFieldValue }) => {},
    },
  },
  fk_jobId: {
    label: 'Job',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      col: 4,
      colWidth: 4,
      raw: false,
      itemsUrl: (data) => data && data.fk_marketId ? `${Job.apiPath}?marketId=${data.fk_marketId}${data.date && `&dateStart=${data.date}`}&active=true` : null,
      onChange: async ({ item, values, setFieldValue }) => {
        if (item) {
          await changeJob({ jobId: item.value, filterFormState: { setFieldValue, values } }); // eslint-disable-line
        }
      },
    },
  },
};

const changeJob = async ({ jobId, filterFormState }) => {
  const { fk_marketId: marketId, fk_routeId: routeId } = filterFormState.values;
  if (jobId && marketId) {
    const routes = await schema.fk_routeId.form.initItems({ fk_marketId: marketId, fk_jobId: jobId });
    if (routeId && !routes.some(({ value }) => value === routeId)) {
      filterFormState.setFieldValue('fk_routeId', null);
    }
  }
};

const RouteDistribution = () => {
  const [ filterFormState, setFilterFormState ] = useState(null);
  const [ refreshRouteSheet, setRefreshRouteSheet ] = useState(null);
  const [ refreshRouteDistribution, setRefreshRouteDistribution ] = useState(null);

  const onSubmit = async ({ warnConfirm } = {}) => {
    await axios.post(RouteSheet.apiPath, {
      ...filterFormState.values,
      distributionRoute: true,
      warnConfirm,
    });
    refreshRouteDistribution();
    refreshRouteSheet();
  };

  return (
    <div className="index route-distribution">
      <div className="filter">
        <Form
          apiPath={RouteSheet.apiPath}
          schema={schema}
          getState={(state) => setFilterFormState(state)}
          // dataDefault={!data ? { fk_campaignId: dataParent.id } : null}
          onSubmit={onSubmit}
        />
      </div>
      {filterFormState?.values.fk_marketId &&
      <>
        <Row>
          <Col>
            <Crud
              apiPath={Route.apiPath}
              apiParams={{ marketId: filterFormState?.values.fk_marketId, ...filterFormState?.values.fk_jobId && { jobId: filterFormState?.values.fk_jobId } }}
              schema={alterSchema(Route.schema, {
                gridCols: [ 'id', 'neighborhood.name', 'dateLast', 'health', 'jobsLeave' ],
              })}
              model={Route.model}
              label={Route.label}
              routePath={Route.routePath}
              showAdd={false}
              showExport={false}
              nested
              gridIcons={[{
                type: iconType.UP,
                onClick: async (data) => {
                  filterFormState.setFieldValue('fk_routeId', data.id);
                },
              }]}
              emptyMsg="No routes for market selected"
            />
          </Col>
          <Col>
            <Crud
              apiPath={RouteSheet.apiPath}
              apiParams={{ distributionRoute: true, marketId: filterFormState?.values.fk_marketId, ...filterFormState?.values.date && { date: filterFormState.values.date } }}
              schema={alterSchema(RouteSheet.schema, {
                gridCols: [ 'id', 'fk_routeId', 'distributionRoute.fk_jobId', 'distributionRoute.item.name' ],
                gridDetail: {
                  id: {
                    key: 'id',
                    ...RouteSheet,
                  },
                  fk_routeId: {
                    key: 'route._id',
                    ...Route,
                  },
                },
                labels: {
                  id: 'Sheet',
                },
              })}
              model={RouteSheet.model}
              label="Route Distribution"
              labelPluralize={false}
              routePath={RouteSheet.routePath}
              showAdd={false}
              showExport={false}
              nested
              emptyMsg="No route distribution for market and date selected"
              refreshData={setRefreshRouteDistribution}
              readOnly
              gridIcons={[{
                type: iconType.DELETE,
                onDelete: async (data) => {
                  await axios.delete(`${RouteSheet.apiPath}/${data.distributionRoute.id}`, { data: { distributionRoute: true } });
                  refreshRouteSheet();
                },
              }]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Crud
              apiPath={Job.apiPath}
              apiParams={{ marketId: filterFormState?.values.fk_marketId, ...filterFormState?.values.date && { dateStart: filterFormState.values.date }, active: true }}
              schema={alterSchema(Job.schema, {
                gridCols: [ 'id', 'dateStart', 'item.name', 'distributionGoal', 'venuesAlltime' ],
              })}
              model={Job.model}
              label={Job.label}
              routePath={Job.routePath}
              showAdd={false}
              showExport={false}
              nested
              gridIcons={[{
                type: iconType.UP,
                onClick: async (data) => {
                  filterFormState.setFieldValue('fk_jobId', data.id);
                  await changeJob({ jobId: data.id, filterFormState });
                },
              }]}
              emptyMsg="No jobs for market and date selected"
            />
          </Col>
          <Col>
            <Crud
              apiPath={RouteSheet.apiPath}
              apiParams={{ marketId: filterFormState?.values.fk_marketId, status: 'Pending,Active' }}
              schema={alterSchema(RouteSheet.schema, {
                gridCols: [ 'id', 'date', 'fk_routeId', 'route.neighborhood.name', 'rep.fullName', 'status' ],
              })}
              model={RouteSheet.model}
              label={RouteSheet.label}
              routePath={RouteSheet.routePath}
              showAdd={false}
              showExport={false}
              showDelete
              nested
              refreshData={setRefreshRouteSheet}
              icons={RouteSheet.icons}
            />
          </Col>
        </Row>
      </>}
    </div>
  );
};

export default RouteDistribution;
