import React from 'react';
import { paths } from 'services/route';
import * as RouteSheet from 'views/routeSheet/config';
import * as Route from 'views/route/config';
import * as Campaign from 'views/campaign/config';
import * as User from '../user/config';

export const model = 'payroll';
export const label = 'Payroll';
export const apiPath = '/payroll';
export const routePath = () => paths.admin.payroll;

export const schema = {
  id: {
    label: 'Sheet',
    grid: {},
  },
  date: {
    label: 'Date',
    type: 'date',
    grid: {},
  },
  campaignTitle: {
    label: 'Campaign',
    grid: {
      minWidth: 150,
      detail: () => ({
        key: 'fk_campaignId',
        ...Campaign,
      }),
    },
  },
  'route.id': {
    label: 'Route',
    grid: {
      minWidth: 100,
      detail: () => ({
        key: 'route._id',
        ...Route,
      }),
    },
  },
  fk_displayRepId: {
    label: 'BA',
  },
  'rep.fullName': {
    label: 'BA',
    grid: {},
  },
  startTime: {
    label: 'Start Time',
    type: 'date',
    time: true,
    grid: {},
  },
  endTime: {
    label: 'End Time',
    type: 'date',
    time: true,
    grid: {},
  },
  hours: {
    label: 'Hours',
    type: 'number',
    grid: {},
  },
  maxBreak: {
    label: 'Max Break',
    type: 'number',
    grid: {},
  },
  distributionActual: {
    label: 'Displays',
    type: 'number',
    grid: {},
  },
  newVenues: {
    label: 'New Venues',
    type: 'number',
    grid: {},
  },
};
