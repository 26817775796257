import React from 'react';
import * as Route from 'services/route';
import * as Campaign from 'views/campaign/config';
import * as VenueType from 'views/venueType/config';

export const model = 'item';
export const label = 'Item';
export const apiPath = '/items';
export const routePath = () => Route.paths.campaigns.items;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  name: {
    label: 'Name',
    required: true,
    grid: {},
  },
  type: {
    label: 'Type',
    grid: {},
    form: {
      type: 'autocomplete',
      items: [
        'WindowPoster',
        'Postcard',
        'Brochure',
        'Sticker',
        'Aerplay',
        'Magazine',
        'Camera',
        'Ticket Invite',
        'Calendar',
        'Branded Bag',
        'Booklet',
        'Voucher',
        'Coaster',
        'Pocket Guide',
      ],
    },
  },
  fk_campaignId: {
    label: 'Orig Campaign',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'title',
      itemsUrl: () => Campaign.apiPath,
      detail: () => ({
        key: 'campaign.id',
        ...Campaign,
      }),
    },
  },
  'campaign.title': {
    label: 'Orig Campaign',
    grid: {
      minWidth: 150,
      detail: () => ({
        key: 'campaign.id',
        ...Campaign,
      }),
    },
    form: {
      hide: true,
    },
  },
  size: {
    label: 'Size',
    grid: {},
    form: {
      type: 'autocomplete',
      items: [
        '14x22',
        '20x30',
      ],
    },
  },
  maintain: {
    label: 'Maintain',
    type: 'boolean',
    form: {
      col: 1,
      colWidth: 'auto',
    },
  },
  smallBusinessDirect: {
    label: 'Small Business Direct',
    type: 'boolean',
    form: {
      col: 2,
    },
  },
  venueExclusions: {
    type: 'array',
    label: 'Excluded Venues',
    form: {
      type: 'checkbox',
      valueKey: 'type',
      labelKey: 'type',
      itemsUrl: () => VenueType.apiPath,
      itemsWrap: 10,
      colBreak: true,
      colWidth: 'auto',
    },
  },
  photo: {
    label: 'Photo',
    type: 'image',
    form: {
      colBreak: true,
      hideAdd: true,
      download: {
        filename: ({ data }) => `${data.name}.jpg`,
      },
    },
  },
  inventoryQty: {
    label: 'Inventory Qty',
    grid: {},
    form: {
      info: true,
    },
  },
  manualCount: {
    label: 'Manual Count',
    form: {
      info: true,
    },
  },
  dateCreation: {
    label: 'Created On',
    type: 'date',
    form: {
      info: true,
    },
  },
  dateMod: {
    label: 'Modified On',
    type: 'date',
    time: true,
    form: {
      info: true,
    },
  },
};
