import axios from 'axios';
import { paths } from 'services/route';
import * as Campaign from 'views/campaign/config';
import * as CampaignStageFlow from 'views/campaignStageFlow/config';

export const model = 'campaignStage';
export const label = 'Campaign Stage';
export const apiPath = '/campaignStages';
export const routePath = () => paths.admin.campaignStages;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  status: Campaign.schema.status,
  stage: {
    label: 'Stage',
    grid: {
      minWidth: 300,
    },
    form: {},
  },
  number: {
    label: 'Number',
    type: 'number',
    grid: {},
    form: {
      col: 1,
    },
  },
  position: {
    label: 'Position',
    type: 'number',
    grid: {},
    form: {
      col: 2,
    },
  },
  team: {
    label: 'Assigned To',
    grid: {
      minWidth: 150,
    },
    form: {},
  },
  'flow.completedBy.fullName': {
    label: 'Completed By',
    grid: {
      hide: true,
      minWidth: 125,
    },
    form: {
      hide: true,
    },
  },
  'flow.completedDate': {
    label: 'Completed On',
    type: 'date',
    query: true,
    grid: {
      hide: true,
      minWidth: 125,
    },
    form: {
      hide: true,
    },
  },
  'flow.notes': {
    label: 'Notes',
    grid: {
      hide: true,
      minWidth: 150,
    },
    form: {
      type: 'textarea',
      hide: true,
      nested: true,
      onChange: CampaignStageFlow.onChange,
    },
  },
};
