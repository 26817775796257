import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from 'components/header';
import Form from 'components/form';
import Spinner from 'components/spinner';
import { apiPath, schema } from './config';

const Setting = () => {
  const [ settings, setSettings ] = useState(null);

  useEffect(async () => {
    const { data } = await axios.get(apiPath);
    setSettings(data);
  }, []);

  return !settings ? <Spinner /> :
    <>
      <Header
        label="Settings"
      />
      <div className="detail">
        <Form
          apiPath={apiPath}
          schema={schema}
          data={settings}
        />
      </div>
    </>;
};

export default Setting;
