import React, { useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import Crud from 'components/crud';
import Form from 'components/form';
import Tabs from 'components/tabs';
import { alterSchema } from 'utils/schema';
import * as Activity from 'views/activity/config';
import { model } from './config';
import 'styles/detail.scss';

const UserDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit }) => {
  if (!dataParent) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});

  const accessFormFields = [ 'email', 'password', 'role', 'fk_clientId' ];

  if (data?.hasPassword) {
    data.password = '•••••••••••';
  }

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={alterSchema(schema, { formFieldsHide: accessFormFields })}
        data={data}
        onSubmit={onSubmit}
      />
      {data && <Tabs
        tabs={[
          {
            key: 'access',
            title: 'Access',
            content:
              <Form
                apiPath={apiPath}
                schema={alterSchema(schema, { formFieldsHide: Object.keys(schema).filter((key) => ![ 'id', ...accessFormFields ].includes(key)) })}
                data={data}
                onSubmit={onSubmit}
              />,
          },
          {
            key: 'activity',
            title: 'Activities',
            content:
              <Crud
                apiPath={Activity.apiPath}
                apiParams={{ userId: id }}
                schema={alterSchema(Activity.schema, { gridColsOrder: Activity.nestedGridCols })}
                schemaParent={schema}
                routePath={Activity.routePath}
                label={Activity.label}
                nested
                dataParent={data}
                model={Activity.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
        ]}
      />}
    </div>
  );
};

export default UserDetail;
