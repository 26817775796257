import { paths } from 'services/route';

export const model = 'market';
export const label = 'Market';
export const apiPath = '/markets';
export const routePath = () => paths.admin.markets;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  name: {
    label: 'Market',
    required: true,
    grid: {},
  },
  code: {
    label: 'Code',
    required: true,
    grid: {},
  },
  state: {
    label: 'State',
    required: true,
    grid: {},
  },
  /* bonus1: {
    label: 'Bonus 1 Job',
    type: 'price',
    required: true,
    grid: {},
  },
  bonus2: {
    label: 'Bonus 2 Job',
    type: 'price',
    required: true,
    grid: {},
  },
  bonus3: {
    label: 'Bonus 3 Job',
    type: 'price',
    required: true,
    grid: {},
  },
  bonus4: {
    label: 'Bonus 4 Job',
    type: 'price',
    required: true,
    grid: {},
  },
  bonusNewVenue: {
    label: 'Bonus New Venue',
    type: 'price',
    required: true,
    grid: {},
  }, */
};
