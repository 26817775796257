import React from 'react';
import * as Route from 'services/route';
import * as Market from 'views/market/config';

export const model = 'manualCount';
export const label = 'Manual Count';
export const apiPath = '/manualCounts';
export const routePath = () => Route.paths.campaigns.manualCounts;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  date: {
    label: 'Date',
    type: 'date',
    required: true,
    grid: {},
  },
  fk_itemId: {
    label: 'Item',
    required: true,
    form: {
      hide: true,
    },
  },
  createdBy: {
    label: 'Recorded By',
    form: {
      info: true,
    },
  },
  fk_marketId: {
    label: 'To/From Market',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Market.apiPath,
    },
  },
  'market.name': {
    label: 'To/From Market',
    grid: {},
    form: {
      hide: true,
    },
  },
  fk_routeSheetId: {
    label: 'Route Sheet',
    /* required: true, */
    form: {
      // type: 'autocomplete',
      // valueKey: 'id',
      // labelKey: 'id',
      // itemsUrl: () => Market.apiPath,
    },
  },
  // TODO: add route sheet name here if applicable
  // ...
  qty: {
    label: 'Qty',
    type: 'number',
    required: true,
    grid: {},
  },
  notes: {
    label: 'Notes',
    grid: {},
    form: {
      type: 'textarea',
    },
  },
};
