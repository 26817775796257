import React from 'react';
import { getUserId } from 'services/auth';
import Crud from 'components/crud';
import { apiPath, schema, model, label, routePath } from './config';

const Activity = () => {
  return (
    <Crud
      apiPath={apiPath}
      schema={schema}
      model={model}
      label={label}
      showDelete={(data) => data?.fk_createdById === getUserId()}
      routePath={routePath}
    />
  );
};

export default Activity;
