import React, { useEffect, useReducer, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import pluralize from 'pluralize';
import Form from 'components/form';
import Tabs from 'components/tabs';
import Crud from 'components/crud';
import { alterSchema } from 'utils/schema';
import * as Client from 'views/client/config';
import * as ContactClientLog from 'views/contactClientLog/config';
import * as Vendor from 'views/vendor/config';
import * as Venue from 'views/venue/config';
import * as Campaign from 'views/campaign/config';
import * as Activity from 'views/activity/config';
import Attachment from 'views/attachment/index';
import { model } from './config';
import 'styles/detail.scss';

const ContactDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit, nested }) => {
  if (!nested) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});
  const [ formState, setFormState ] = useState(null);
  useEffect(async () => {
    if (formState && !schema.fk_venueId.form.items?.length) {
      schema.fk_venueId.form.itemsUrl = () => `${Venue.apiPath}?active=true`;
      await schema.fk_venueId.form.initItems?.();
      schema.fk_venueId.form.itemsUrl = () => null;
      if (data?.fk_venueId) {
        formState.setFieldValue('fk_venueId', data.fk_venueId);
      }
    }
  }, [formState]);

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={modelParent ? alterSchema(schema, { formFieldsHide:
          modelParent === Client.model ? [ 'fk_clientId', 'fk_vendorId', 'fk_venueId' ] :
          modelParent === Vendor.model ? [ 'fk_clientId', 'fk_vendorId', 'fk_venueId' ] :
          modelParent === Venue.model ? [ 'fk_clientId', 'fk_vendorId', 'fk_venueId', 'mobile', 'fax', 'linkedIn' ] :
          null,
        }) : schema}
        data={data}
        dataDefault={!data && (
          modelParent === Client.model ? { fk_clientId: dataParent.id, type: 'Client' } :
          modelParent === Vendor.model ? { fk_vendorId: dataParent.id, type: 'Vendor' } :
          modelParent === Venue.model ? { fk_venueId: dataParent.id, type: 'Venue' } :
          null
        )}
        onSubmit={onSubmit}
        getState={setFormState}
      />
      {data && <Tabs
        tabs={[
          {
            key: ContactClientLog.model,
            title: pluralize(ContactClientLog.label),
            content:
              <Crud
                apiPath={ContactClientLog.apiPath}
                apiParams={{ contactId: id }}
                schema={ContactClientLog.schema}
                schemaParent={schema}
                label={ContactClientLog.label}
                dataParent={data}
                model={ContactClientLog.model}
                modelParent={model}
                gridHeight={200}
                nested
                readOnly // TODO: make company selectable
              />,
          },
          {
            key: 'campaign',
            title: 'Campaigns',
            content:
              <Crud
                apiPath={Campaign.apiPath}
                apiParams={{ contactId: id }}
                schema={alterSchema(Campaign.schema, { gridCols: [ 'client.name', 'title', 'type', 'markets', 'dateStart', 'dateEnd', 'status' ] })}
                schemaParent={schema}
                routePath={Campaign.routePath}
                label={Campaign.label}
                nested
                dataParent={data}
                model={Campaign.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'activity',
            title: 'Activities',
            content:
              <Crud
                apiPath={Activity.apiPath}
                apiParams={{ contactId: id }}
                schema={alterSchema(Activity.schema, { gridColsOrder: Activity.nestedGridCols })}
                schemaParent={schema}
                routePath={Activity.routePath}
                label={Activity.label}
                nested
                dataParent={data}
                model={Activity.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'attachment',
            title: 'Attachments',
            content:
              <Attachment
                schemaParent={schema}
                modelParent={model}
                dataParent={data}
              />,
          },
        ]}
      />}
    </div>
  );
};

export default ContactDetail;
