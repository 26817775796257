import * as Route from 'services/route';
import * as Market from 'views/market/config';
import * as Neighborhood from 'views/neighborhood/config';

export const model = 'campaignMarket';
export const label = 'Market';
export const apiPath = '/campaignMarkets';
export const routePath = () => Route.paths.campaigns.campaignMarkets;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  fk_campaignId: {
    label: 'Campaign',
    required: true,
    form: {
      hide: true,
    },
  },
  fk_marketId: {
    label: 'Market',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Market.apiPath,
      onChange: async ({ item, fields, setFieldValue }) => {
        if (item) {
          const neighborhoodsField = fields.find(({ key }) => key === 'neighborhoods');
          await neighborhoodsField.form.initItems({ fk_marketId: item.value });
          setFieldValue('neighborhoods', []);
        }
      },
    },
  },
  'market.name': {
    label: 'Name',
    grid: {},
    form: {
      hide: true,
    },
  },
  neighborhoods: {
    type: 'array',
    label: 'Preferred Neighborhoods',
    grid: {
      valueGetter: ({ data }) => data?.neighborhoods.map(({ name }) => name).join(', '),
    },
    form: {
      type: 'checkbox',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: (data) => data && data.fk_marketId ? `${Neighborhood.apiPath}?marketId=${data.fk_marketId}` : null,
      itemsWrap: 10,
    },
  },
};
