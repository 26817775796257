import axios from 'axios';
import moment from 'moment';
import * as Venue from 'views/venue/config';
import * as Market from 'views/market/config';
import * as Campaign from 'views/campaign/config';
import * as Item from '../item/config';

export const model = 'photo';
export const apiPath = '/photos';
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  fk_campaignId: {
    label: 'Campaign',
  },
  fk_venueId: {
    label: 'Venue',
    form: {
      // info: true,
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: (data, { dataParent, modelParent }) => `${Venue.apiPath}${modelParent === Campaign.model ? `?campaignId=${dataParent.id}` : ''}`,
      onChange: async ({ item, setFieldValue }) => {
        const { data } = await axios.get(`${Venue.apiPath}/${item.value}`);
        setFieldValue('venueType', data.type?.type, { submit: false });
        setFieldValue('venueMarket', data.market?.name, { submit: false });
        setFieldValue('venueNeighborhood', data.neighborhood?.name, { submit: false });
      },
    },
  },
  'venue.name': {
    label: 'Venue',
    form: {
      info: true,
      detail: () => ({
        key: 'venue.id',
        ...Venue,
      }),
    },
  },
  venueType: {
    label: 'Type',
    form: {
      info: true,
    },
  },
  venueMarket: {
    label: 'Market',
    form: {
      info: true,
    },
  },
  venueNeighborhood: {
    label: 'Neighborhood',
    form: {
      info: true,
    },
  },
  'routeSheet.rep.fullName': {
    label: 'BA',
    form: {
      info: true,
    },
  },
  date: {
    label: 'Date',
    form: {
      info: true,
    },
  },
  time: {
    label: 'Time',
    form: {
      info: true,
    },
  },
  itemType: {
    label: 'Type',
    form: {
      info: true,
      type: 'autocomplete',
      items: [
        'Windowposter',
        'Postcard',
      ],
    },
  },
  flagApproved: {
    label: 'Approved',
    type: 'boolean',
    form: {
      info: true,
    },
  },
  flagReportInclude: {
    label: 'Include',
    type: 'boolean',
    form: {
      info: true,
    },
  },
  photo: {
    type: 'image',
    form: {
      readOnly: true,
      colBreak: true,
      colWidth: 9,
      download: {
        filename: ({ data, dataParent }) => `${dataParent.title} ${data.id} ${moment(data.date).format('M-D-YYYY')}.jpg`,
      },
    },
  },
};
