import React, { useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import Crud from 'components/crud';
import Form from 'components/form';
import Tabs from 'components/tabs';
import { alterSchema } from 'utils/schema';
import * as CampaignItem from 'views/campaignItem/config';
import * as ManualCount from 'views/manualCount/config';
import * as Movement from 'views/movement/config';
import 'styles/detail.scss';
import { model } from './config';

const ItemDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit, nested }) => {
  if (!nested) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={modelParent === CampaignItem.model ? alterSchema(schema, { formFieldsHide: ['fk_campaignId'] }) : schema}
        data={data}
        dataDefault={!data && (modelParent === CampaignItem.model ? { fk_campaignId: dataParent.fk_campaignId } : null)}
        onSubmit={onSubmit}
      />
      {data && <Tabs
        tabs={[
          {
            key: 'manualCount',
            title: 'Manual Counts',
            content:
              <Crud
                apiPath={ManualCount.apiPath}
                apiParams={{ itemId: id }}
                schema={alterSchema(ManualCount.schema, { gridCols: [ 'date', 'createdBy', 'market.name', 'fk_routeSheetId', 'qty', 'notes' ] })}
                schemaParent={schema}
                routePath={ManualCount.routePath}
                label={ManualCount.label}
                nested
                dataParent={data}
                model={ManualCount.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'inventoryMovement',
            title: 'Inventory Movement',
            content:
              <Crud
                apiPath={Movement.apiPath}
                apiParams={{ itemId: id }}
                schema={alterSchema(Movement.schema, { gridCols: [ 'date', 'createdBy', 'market.name', 'qtyIn', 'qtyOut', 'deliveryInfo' ] })}
                schemaParent={schema}
                routePath={Movement.routePath}
                label={Movement.label}
                nested
                dataParent={data}
                model={Movement.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
        ]}
      />}
    </div>
  );
};

export default ItemDetail;
