import React, { useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import Crud from 'components/crud';
import Form from 'components/form';
import Tabs from 'components/tabs';
import { alterSchema } from 'utils/schema';
import * as Campaign from 'views/campaign/config';
import * as RouteSheet from 'views/routeSheet/config';
import { model, status } from './config';
import 'styles/detail.scss';

const JobDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit, nested, showNestedDetailModal, hideNestedDetailModal }) => {
  if (!nested) {
    [ id, apiPath, schema, data, onSubmit, showNestedDetailModal ] = useOutletContext();
  }

  /* const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {}); */

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={!data && modelParent === Campaign.model ? alterSchema(schema, { formFieldsShow: [ 'fk_itemId', 'fk_campaignMarketId' ] }) : schema}
        data={data}
        dataDefault={!data && (modelParent === Campaign.model ? { fk_campaignId: dataParent.id, dateStart: dataParent.dateStart } : null)}
        onSubmit={onSubmit}
        showNestedDetailModal={showNestedDetailModal}
      />
      {data && <Tabs
        tabs={[
          {
            key: 'routeSheet',
            title: 'Route Sheets',
            content:
              <Crud
                apiPath={RouteSheet.apiPath}
                apiParams={{ distributionRoute: true, jobId: id }}
                schema={alterSchema(RouteSheet.schema, { gridCols: [ 'id', 'date', 'fk_routeId', 'route.neighborhood.name', 'rep.fullName', 'photosProcessedBy', 'status' ] })}
                schemaParent={schema}
                label={RouteSheet.label}
                nested
                dataParent={data}
                model={RouteSheet.model}
                modelParent={model}
                routePath={RouteSheet.routePath}
                showAdd={data?.status !== status.COMPLETED}
                showDelete
                icons={RouteSheet.icons}
              />,
          },
          // {
          //   key: 'activity',
          //   title: 'Activities',
          //   content:
          //     <Crud
          //       apiPath={Task.apiPath}
          //       apiParams={{ jobId: id }}
          //       schema={alterSchema(Task.schema, { gridCols: Task.nestedGridCols })}
          //       routePath={Task.routePath}
          //       label={Task.label}
          //       nested
          //       dataParent={data}
          //       model={Task.model}
          //       modelParent={model}
          //       onInit={() => {
          //         forceUpdate();
          //       }}
          //     />,
          // },
        ]}
      />}
    </div>
  );
};

export default JobDetail;
