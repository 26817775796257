import { paths } from 'services/route';

export const model = 'venueType';
export const label = 'Venue Type';
export const apiPath = '/venueTypes';
export const routePath = () => paths.admin.venueTypes;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  type: {
    label: 'Type',
    required: true,
    grid: {
      minWidth: 150,
    },
  },
};
