import React, { useState } from 'react';
import { pick, cloneDeep } from 'lodash';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import Form from 'components/form';
import { getAuth } from 'services/auth';
import { apiPath, schema } from 'views/user/config';
import 'styles/alert.scss';
import 'styles/views/profile.scss';

const Profile = () => {
  const [ submitted, setSubmitted ] = useState(null);

  const _schema = pick(cloneDeep(schema), [ 'id', 'email', 'password' ]);
  _schema.email.form.readOnly = true;
  _schema.password.required = true;
  delete _schema.email.form.colWidth;
  delete _schema.password.form.colWidth;
  delete _schema.password.form.inlineButton;
  delete _schema.password.form.readOnly;
  const { user } = getAuth();
  return (
    <div className="view profile">
      <div>
        <Form
          apiPath={apiPath}
          schema={_schema}
          dataDefault={user}
          submitLabel="Save"
          onSubmit={({ password }) => {
            axios.patch(`${apiPath}/${user.id}`, { password });
            setSubmitted(true);
          }}
          size="md"
        />
        {submitted && <Alert variant="success">Profile saved</Alert>}
      </div>
    </div>
  );
};

export default Profile;
