import * as Route from 'services/route';

export const model = 'street';
export const label = 'Street';
export const apiPath = '/streets';
export const routePath = () => Route.paths.routes.streets;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  fk_routeId: {
    label: 'Route',
    required: true,
    form: {
      hide: true,
    },
  },
  streetName: {
    label: 'Street Name',
    grid: {},
    form: {},
  },
};
