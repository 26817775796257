import * as Route from 'services/route';
import * as AuditRatio from 'utils/auditRatio';
import * as Item from 'views/item/config';
import * as Client from '../client/config';

export const model = 'campaignItem';
export const label = 'Item';
export const apiPath = '/campaignItems';
export const routePath = () => Route.paths.campaigns.campaignItems;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  fk_campaignId: {
    label: 'Campaign',
    required: true,
    form: {
      hide: true,
    },
  },
  fk_itemId: {
    label: 'Item',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Item.apiPath,
      inlineAdd: Item,
      detail: () => ({
        key: 'item.id',
        ...Item,
      }),
    },
  },
  'item.name': {
    label: 'Name',
    grid: {},
    form: {
      hide: true,
    },
  },
  distributionGoal: {
    label: 'Dist Goal',
    type: 'number',
    required: true,
    grid: {},
    form: {
      col: 1,
    },
  },
  venuesAlltime: {
    label: 'Venues All-Time',
    type: 'number',
    grid: {},
    form: {
      info: true,
    },
  },
  upCurrent: {
    label: 'Up Current',
    type: 'number',
    grid: {},
    form: {
      info: true,
    },
  },
  venuesAudit: {
    label: 'Audit %',
    type: 'number',
    grid: {
      cellRenderer: ({ params, dataParent }) => AuditRatio.renderer(params.data),
    },
    form: {
      info: true,
      infoRenderer: AuditRatio.renderer,
    },
  },
  dateStart: {
    label: 'Start Date',
    type: 'date',
    grid: {},
  },
  dateInitialPosting: {
    label: 'Initial Posting',
    type: 'date',
    grid: {},
  },
  dateEnd: {
    label: 'End Date',
    type: 'date',
    grid: {},
  },
};
