import * as Client from 'views/client/config';

export const model = 'contactClientLog';
export const label = 'Companies';
export const apiPath = '/contactClientLog';
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
  },
  fk_contactId: {
    label: 'Contact',
  },
  fk_clientId: {
    label: 'Company',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Client.apiPath,
    },
  },
  'client.name': {
    label: 'Company',
    grid: {
      detail: () => ({
        key: 'fk_clientId',
        ...Client,
      }),
    },
  },
  dateStart: {
    type: 'date',
    label: 'Date',
    grid: {},
  },
};
