import React from 'react';
import { useLocation, matchPath, Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { paths, routes, matchRoute, isDetailPath, isAddPath } from 'services/route';
import 'styles/breadcrumb.scss';

export default () => {
  const { pathname } = useLocation();
  if (matchPath(paths.home, pathname) || matchPath(paths.login, pathname)) {
    return null;
  }
  return (
    <Breadcrumb className="breadcrumb">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: paths.home }}>Home</Breadcrumb.Item>
      {routes.filter(({ path }) => ![ paths.home, paths.login ].includes(path)).map((route, r) => {
        const { label, children, path, index, icon } = route;
        const childRoute = matchRoute({ pathname, children });
        return (
          <React.Fragment key={r}>
            {
              childRoute ? (path !== childRoute.path && !icon && <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }}>{label}</Breadcrumb.Item>) :
              index && matchRoute({ pathname }) === route && <Breadcrumb.Item>{label}</Breadcrumb.Item>
            }
            {
              childRoute &&
              <>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: childRoute.path }} {...{ active: false }}>{childRoute.label}</Breadcrumb.Item>
                {isAddPath({ pathname }) && <Breadcrumb.Item active>New</Breadcrumb.Item>}
                {isDetailPath({ pathname }) && <Breadcrumb.Item active>Details</Breadcrumb.Item>}
              </>
            }
          </React.Fragment>
        );
      })}
    </Breadcrumb>
  );
};
