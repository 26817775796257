import { login, logout, refresh } from 'services/auth';
import initialState from './initialState';

export const REQUEST_LOGIN = '@@auth/REQUEST_LOGIN';
export const REQUEST_LOGIN_FAILURE = '@@auth/REQUEST_LOGIN_FAILURE';
export const REQUEST_LOGIN_SUCCESS = '@@auth/REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGOUT = '@@auth/REQUEST_LOGOUT';

export const REQUEST_REFRESH = '@@auth/REQUEST_REFRESH';
export const REQUEST_REFRESH_SUCCESS = '@@auth/REQUEST_REFRESH_SUCCESS';
export const REQUEST_REFRESH_FAILURE = '@@auth/REQUEST_REFRESH_FAILURE';

export default function authReducer(state = initialState.auth, action = {}) {
  switch (action.type) {
    case REQUEST_LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case REQUEST_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        user: {},
      };
    case REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userLoaded: true,
        isLoading: false,
        user: action.data,
        error: null,
      };
    case REQUEST_REFRESH:
      return {
        ...state,
        isLoading: true,
      };
    case REQUEST_REFRESH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userLoaded: true,
        isLoading: false,
        user: action.data,
      };
    case REQUEST_REFRESH_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        userLoaded: true,
        isLoading: false,
        error: action.error,
        user: {},
      };
    case REQUEST_LOGOUT:
      return {
        ...initialState.auth,
      };
    default:
      return state;
  }
}

export const Actions = {};

Actions.login = ({ email, password }) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_LOGIN });
    try {
      const { user } = await login({ email, password });
      return dispatch({ type: REQUEST_LOGIN_SUCCESS, data: user });
    } catch (error) {
      return dispatch({ type: REQUEST_LOGIN_FAILURE, error: error?.data });
    }
  };
};

Actions.refresh = () => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_REFRESH });
    try {
      const { user } = await refresh();
      return dispatch({ type: REQUEST_REFRESH_SUCCESS, data: user });
    } catch (error) {
      return dispatch({ type: REQUEST_REFRESH_FAILURE, error: error?.data });
    }
  };
};

Actions.logout = () => {
  logout();
  return { type: REQUEST_LOGOUT };
};
