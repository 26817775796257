import * as Route from 'services/route';
import * as VendorType from 'views/vendorType/config';
import * as Contact from 'views/contact/config';
import state from 'data/state';

export const model = 'vendor';
export const label = 'Vendor';
export const apiPath = '/vendors';
export const routePath = () => Route.paths.crm.vendors;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  name: {
    label: 'Name',
    required: true,
    grid: {
      minWidth: 150,
    },
  },
  fk_typeId: {
    label: 'Type',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'type',
      itemsUrl: () => VendorType.apiPath,
    },
  },
  'type.type': {
    label: 'Type',
    grid: {},
    form: {
      hide: true,
    },
  },
  status: {
    label: 'Status',
    form: {
      type: 'autocomplete',
      items: [ 'Lead', 'Proposed', 'Active', 'Inactive', 'Dead' ],
    },
  },
  id_primaryContact: {
    label: 'Primary Contact',
    form: {
      hide: true,
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: ({ id }) => `${Contact.apiPath}?vendorId=${id}`,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
    },
  },
  'contact.nameFull': {
    label: 'Primary Contact',
    grid: {
      minWidth: 150,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
    },
    form: {
      hide: true,
    },
  },
  address: {
    label: 'Address',
    form: {
      hide: true,
    },
    /* form: {
      colBreak: true,
    }, */
  },
  city: {
    label: 'City',
    grid: {},
    form: {
      hide: true,
    },
  },
  state: {
    label: 'State',
    form: {
      hide: true,
      col: 1,
      type: 'autocomplete',
      items: state,
    },
  },
  zip: {
    label: 'Zip',
    form: {
      hide: true,
      col: 2,
    },
  },
  email: {
    type: 'email',
    label: 'Email',
    form: {
      hide: true,
    },
  },
  phone: {
    label: 'Phone',
    grid: {},
    form: {
      hide: true,
    },
  },
  mobile: {
    label: 'Mobile',
    form: {
      hide: true,
    },
  },
  fax: {
    label: 'Fax',
    form: {
      hide: true,
    },
  },
  id_arContact: {
    label: 'AR Contact',
    form: {
      hide: true,
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: ({ id }) => `${Contact.apiPath}?vendorId=${id}`,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
    },
  },
  arAddress: {
    label: 'Address',
    form: {
      hide: true,
    },
  },
  arCity: {
    label: 'City',
    form: {
      hide: true,
    },
  },
  arState: {
    label: 'State',
    form: {
      hide: true,
      col: 1,
      type: 'autocomplete',
      items: state,
    },
  },
  arZip: {
    label: 'Zip',
    form: {
      hide: true,
      col: 2,
    },
  },
  arEmail: {
    type: 'email',
    label: 'Email',
    form: {
      hide: true,
    },
  },
  arPhone: {
    label: 'Phone',
    form: {
      hide: true,
    },
  },
  arMobile: {
    label: 'Mobile',
    form: {
      hide: true,
    },
  },
  arFax: {
    label: 'Fax',
    form: {
      hide: true,
    },
  },
  terms: {
    label: 'Terms',
    form: {
      hide: true,
    },
  },
  accountNumber: {
    label: 'Account No',
    form: {
      hide: true,
    },
  },
  discount: {
    label: 'Discount',
    form: {
      hide: true,
    },
  },
  notes: {
    label: 'Notes',
    form: {
      type: 'textarea',
      colBreak: true,
    },
  },
  tags: {
    label: 'Tags',
    grid: {},
    form: {
      type: 'tag',
      colBreak: true,
      colWidth: 2,
    },
  },
  createdBy: {
    label: 'Created By',
    form: {
      info: true,
    },
  },
  dateCreation: {
    label: 'Created On',
    type: 'date',
    form: {
      info: true,
    },
  },
  modifiedBy: {
    label: 'Modified By',
    form: {
      info: true,
    },
  },
  dateMod: {
    label: 'Modified On',
    type: 'date',
    time: true,
    form: {
      info: true,
    },
  },
};
