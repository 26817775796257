import React, { useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import Form from 'components/form';
import { alterSchema } from 'utils/schema';
import * as Item from 'views/item/config';
import 'styles/detail.scss';

const ManualCountDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit }) => {
  if (!dataParent) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  return (
    <div className="detail">
      <Form
        apiPath={apiPath}
        schema={modelParent === Item.model ? alterSchema(schema, { formFieldsHide: ['fk_itemId'] }) : schema}
        data={data}
        dataDefault={!data && (modelParent === Item.model ? { fk_itemId: dataParent.id } : null)}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default ManualCountDetail;
