import React from 'react';
import Tabs from 'components/tabs';
import Crud from 'components/crud';
import * as Market from 'views/market/config';
import * as Neighborhood from 'views/neighborhood/config';
import * as ClientType from 'views/clientType/config';
import * as VendorType from 'views/vendorType/config';
import * as VenueType from 'views/venueType/config';
import * as ActivityType from 'views/activityType/config';
import * as CampaignStage from 'views/campaignStage/config';
import 'styles/detail.scss';

const Value = () => {
  return (
    <div className="detail">
      <Tabs
        tabs={[
          {
            key: 'market',
            title: 'Markets',
            content:
              <Crud
                apiPath={Market.apiPath}
                schema={Market.schema}
                routePath={Market.routePath}
                label={Market.label}
                nested
                showDelete
              />,
          },
          {
            key: 'neighborhood',
            title: 'Neighborhoods',
            content:
              <Crud
                apiPath={Neighborhood.apiPath}
                schema={Neighborhood.schema}
                routePath={Neighborhood.routePath}
                label={Neighborhood.label}
                nested
                showDelete
              />,
          },
          {
            key: 'clientType',
            title: 'Client Types',
            content:
              <Crud
                apiPath={ClientType.apiPath}
                schema={ClientType.schema}
                routePath={ClientType.routePath}
                label={ClientType.label}
                nested
                showDelete
              />,
          },
          {
            key: 'vendorType',
            title: 'Vendor Types',
            content:
              <Crud
                apiPath={VendorType.apiPath}
                schema={VendorType.schema}
                routePath={VendorType.routePath}
                label={VendorType.label}
                nested
                showDelete
              />,
          },
          {
            key: 'venueType',
            title: 'Venue Types',
            content:
              <Crud
                apiPath={VenueType.apiPath}
                schema={VenueType.schema}
                routePath={VenueType.routePath}
                label={VenueType.label}
                nested
                showDelete
              />,
          },
          {
            key: 'activityType',
            title: 'Activity Types',
            content:
              <Crud
                apiPath={ActivityType.apiPath}
                schema={ActivityType.schema}
                routePath={ActivityType.routePath}
                label={ActivityType.label}
                nested
                showDelete
              />,
          },
          {
            key: 'campaignStage',
            title: 'Campaign Flow',
            content:
              <Crud
                apiPath={CampaignStage.apiPath}
                schema={CampaignStage.schema}
                routePath={CampaignStage.routePath}
                label={CampaignStage.label}
                nested
                showDelete
              />,
          },
        ]}
      />
    </div>
  );
};

export default Value;
